import React, {useState} from 'react';
import fp from 'lodash/fp';

import {IconButton, Menu, MenuItem, ListItemIcon} from '@material-ui/core';
import {
    Assignment,
    MoreVert,
    Publish,
    Print,
    PictureAsPdf,
    Refresh,
} from '@material-ui/icons';

import {useDialog} from '@arborian/narrf';
import {useFeatures} from 'lib/util';

export default function TicketMenu({ticket, actions}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const faxTicketDialog = useDialog('faxTicket');
    const pccProgressNoteDialog = useDialog('pccProgressNote');
    const features = useFeatures();

    const handleUpdatePCCData = async ev => {
        setAnchorEl(null);
        await actions.updatePCC(ticket);
    };

    const handleUploadPCC = async ev => {
        setAnchorEl(null);
        await actions.uploadPCC(ticket);
        await actions.refresh(ticket);
    };

    const handleCreateProgressNote = async ev => {
        setAnchorEl(null);
        const values = await pccProgressNoteDialog(ticket.id);
        if (values) {
            await actions.createPCCProgressNote(ticket, values);
            await actions.refresh(ticket);
        }
    };

    const handleUploadPF = async ev => {
        setAnchorEl(null);
        await actions.uploadPF(ticket);
        await actions.refresh(ticket);
    };

    const handleDownloadPdf = ev => {
        setAnchorEl(null);
        window.open(ticket.links.pdf);
    };

    const handleFaxTicketMenu = async ev => {
        setAnchorEl(null);
        const fax = await faxTicketDialog(
            fp.get('attributes.fax_number', ticket),
        );
        if (fax) {
            await actions.sendFax(ticket, fax);
            await actions.refresh(ticket);
        }
    };

    const showPCC = fp.pipe([
        fp.get('attributes.patient_identifier'),
        fp.find(ident => ident.system === 'http://www.pointclickcare.com'),
    ])(ticket);

    return (
        <>
            <IconButton onClick={ev => setAnchorEl(ev.currentTarget)}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={ev => setAnchorEl(null)}
            >
                {showPCC && (
                    <MenuItem key='pcc_refresh' onClick={handleUpdatePCCData}>
                        <ListItemIcon>
                            <Refresh />
                        </ListItemIcon>
                        Update data from PointClickCare
                    </MenuItem>
                )}
                {showPCC && features.pcc_pdf_upload && (
                    <MenuItem key='pcc_doc' onClick={handleUploadPCC}>
                        <ListItemIcon>
                            <Publish />
                        </ListItemIcon>
                        Upload to PointClickCare (as document)
                    </MenuItem>
                )}
                {showPCC && features.pcc_progress_note_upload && (
                    <MenuItem key='pcc_pn' onClick={handleCreateProgressNote}>
                        <ListItemIcon>
                            <Assignment />
                        </ListItemIcon>
                        Create PointClickCare Progress Note
                    </MenuItem>
                )}
                <MenuItem onClick={handleUploadPF}>
                    <ListItemIcon>
                        <Publish />
                    </ListItemIcon>
                    Upload to PracticeFusion
                </MenuItem>
                <MenuItem onClick={handleDownloadPdf}>
                    <ListItemIcon>
                        <PictureAsPdf />
                    </ListItemIcon>
                    Download PDF
                </MenuItem>
                <MenuItem onClick={handleFaxTicketMenu}>
                    <ListItemIcon>
                        <Print />
                    </ListItemIcon>
                    Fax Ticket
                </MenuItem>
            </Menu>
        </>
    );
}
