import React, {useEffect, useState, useCallback} from 'react';

import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';


export default function RegistrationRedirectPage() {
    const api = useApi();
    const [providers, setProviders] = useState();

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            console.log('Got error, trying direct redirect');
            window.location = authorizeLink;
        }
    }, [api, setProviders]);

    useEffect(() => {
        if (!providers) {
            fetchProviders();
        }
        if (providers) {
            const redirect_uri = new URL('/callback', window.location.href);
            const cognito = fp.find(
                p => p.attributes.plugin_id === 'aws-cognito',
                providers,
            );
            const authorizeLink = api.authorizeLink({
                redirect_uri,
                provider_id: fp.get('id', cognito),
                intent: "register",
            });
            window.location = authorizeLink;
        }
    }, [providers, fetchProviders, api]);
    return <p>Loading...</p>
}