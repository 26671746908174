import React from 'react';
import fp from 'lodash/fp';

import {Chip} from '@material-ui/core';

import PCCPatientDataTable from './PCCPatientDataTable';
import LabDetail from './LabDetail';
import {FacilityDateTime} from './FacilityDates';


function ChipList({value}) {
    return (
        <div>
            {fp.map(c => <Chip key={c} label={c}/>, value)}
        </div>
    );
}

export default function LabsTable({patient, facility}) {
    if(!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='labs'
            columns={[
                {
                    title: 'Issued', field: 'data.attributes.issuedDateTime', type: 'datetime',
                    render: rd => <FacilityDateTime value={rd.data.attributes.effectiveDateTime} facility={facility}/>
                },
                {
                    title: 'Effective', field: 'data.attributes.effectiveDateTime', type: 'datetime',
                    render: rd => <FacilityDateTime value={rd.data.attributes.effectiveDateTime} facility={facility}/>
                },
                { title: 'Name', field: 'data.attributes.reportName' },
                { title: 'Status', field: 'data.attributes.reportStatus' },
                { title: 'Laboratory', field: 'data.attributes.reportingLaboratory'},
                { title: 'Category', render: rd => <ChipList value={rd.data.attributes.category}/> },
            ]}
            detailPanel={[
                {
                    tooltip: 'Details',
                    render: rowData => <LabDetail value={rowData.data} />
                }
            ]}
        />
    )
}
