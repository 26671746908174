import React from 'react';

import BasePage from 'components/BasePage';
import PracticeFusion from 'components/PracticeFusion';

export default function PracticeFusionPage() {
    return (
        <BasePage wide title='PracticeFusion'>
            <PracticeFusion />
        </BasePage>
    );
}
