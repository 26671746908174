import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {makeStyles, Button} from '@material-ui/core';

import BasePage from 'components/BasePage';
import {history} from '../routes';
import TicketNarrative from '../components/TicketNarrative';
import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    pageParent: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        textAlign: 'center',
        width: '50%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function SubmitPage() {
    const api = useApi();
    const classes = useStyles();
    let query = useQuery();
    const sessionid = query.get('sess');
    const [narrative, setNarrative] = useState('');
    const [submitText, setSubmitText] = useState('Submit');
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        const asyncFunc = async () => {
            const url = api.url_for('session.resource', {sessionid});
            const resp = await api.fetchJson(`${url}/preview`);
            setNarrative(resp.data);
        };
        asyncFunc();
    }, [api, sessionid]);

    const handleSubmit = async () => {
        setBusy(true);
        setSubmitText('Submitting...');
        const url = api.url_for('ticket.collection');
        await api.fetchJson(url, {
            method: 'POST',
            body: JSON.stringify({
                data: {
                    id: sessionid,
                    type: 'SessionId',
                },
            }),
            headers: {'Content-Type': 'application/json'},
        });
        await history.push(`/form/success?sess=${sessionid}`);
    };

    return (
        <BasePage>
            <div className={classes.pageParent}>
                <h2>Review</h2>
                <h5>The following ticket will be submitted</h5>
                <TicketNarrative value={narrative} hideCopy={true} />
                <div className={classes.buttons}>
                    <Button onClick={() => history.go(-1)}>Back</Button>
                    <Button
                        style={{margin: 20}}
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={busy}
                    >
                        {submitText}
                    </Button>
                </div>
            </div>
        </BasePage>
    );
}
