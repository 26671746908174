import {useMemo} from 'react';
import fp from 'lodash/fp';
import {useSnackbar} from 'notistack';

import {useApi} from '@arborian/narrf';

class TicketActions {
    constructor({api, include, enqueueSnackbar}) {
        this.api = api;
        this.include = include;
        this.enqueueSnackbar = enqueueSnackbar;
    }

    async success(message) {
        this.enqueueSnackbar(message, {persist: false, variant: 'success'});
    }

    async error(message, err) {
        message = await this.formatErrorMessage(message, err);
        console.error(message, err);
        this.enqueueSnackbar(message, {persist: false, variant: 'error'});
    }

    batch = ({success_message, error_message}) => {
        return new TicketActionBatch({success_message, error_message, ...this});
    };

    async formatErrorMessage(message, err) {
        const err_code = fp.get('response.status', err);
        let detail = null;
        if (err_code) {
            detail = `${err_code}`;
            detail += ' ' + (await err.text());
            message = `${message}: API error: ${detail}`;
        }
        return message;
    }

    refresh = async ticket => {
        await this.api.fetchJsonApi(ticket.links.self, {
            include: this.include,
        });
    };

    patch = async (ticket, attributes, options = {}) => {
        const {type, id} = ticket;
        const {
            success_message = 'Ticket updated',
            error_message = 'Error updating ticket',
        } = options;
        attributes = fp.merge(ticket.attributes, attributes);
        try {
            await this.api.fetchJsonApi(ticket.links.self, {
                method: 'PATCH',
                include: this.include,
                json: {data: {type, id, attributes}},
            });
            await this.success(success_message);
        } catch (err) {
            await this.error(error_message, err);
        }
    };

    sendFax = async (ticket, fax_number) => {
        if (!fax_number || !fax_number.length) {
            this.error('No fax number(s) specified.');
            return;
        }
        try {
            await this.api.fetchJson(ticket.links.fax, {
                method: 'POST',
                json: {fax_number},
            });
            const numbers = fp.join(', ', fax_number);
            await this.success(`Fax(es) scheduled to be sent to ${numbers}.`);
        } catch (err) {
            await this.error('Unable to fax ticket', err);
        }
    };

    updatePCC = async ticket => {
        try {
            const resp = await this.api.fetchJsonApi(
                ticket.links['snapshot-pcc'],
                {
                    method: 'POST',
                    include: this.include,
                },
            );
            if (!fp.get('data.attributes.hasPCCSnapshots', resp)) {
                const err = new Error('No PCC data');
                await this.error(
                    'Attempted refresh, but unable to load PCC data',
                    err,
                );
            } else {
                await this.success('PointClickCare data updated.');
            }
        } catch (err) {
            await this.error('Error updating data from PointClickCare', err);
        }
    };

    uploadPCC = async ticket => {
        try {
            await this.api.fetchJson(ticket.links.upload_pcc, {
                method: 'POST',
            });
            await this.success('Ticket uploaded to PointClickCare.');
        } catch (err) {
            await this.error('Error uploading ticket to PointClickCare', err);
        }
    };

    uploadPF = async ticket => {
        try {
            await this.api.fetchJson(ticket.links.upload_pf, {
                method: 'POST',
            });
            await this.success('Ticket uploaded to Practice Fusion.');
        } catch (err) {
            await this.error('Error uploading ticket to Practice Fusion', err);
        }
    };

    archive = async ticket => {
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {data: fp.set('attributes.archived', true, ticket)},
            });
            await this.success('Ticket archived.');
        } catch (err) {
            await this.error('Error archiving ticket', err);
        }
    };

    unarchive = async ticket => {
        try {
            await this.api.fetchJson(ticket.links.self, {
                method: 'PATCH',
                json: {data: fp.set('attributes.archived', false, ticket)},
            });
            await this.success('Ticket archived.');
        } catch (err) {
            await this.error('Error archiving ticket', err);
        }
    };

    createPCCProgressNote = async (
        ticket,
        {progressNoteType, ...sections_obj},
    ) => {
        const sections = fp.pipe([
            fp.toPairs,
            fp.filter(([name]) =>
                fp.includes(name, progressNoteType.attributes.sections),
            ),
            fp.map(([name, value]) => ({name, value})),
        ])(sections_obj);
        const noteType = fp.get('attributes.noteType', progressNoteType);
        const noteTypeId = fp.get(
            'attributes.progressNoteTypeId',
            progressNoteType,
        );
        const externalId = fp.truncate(40, `TCPA-${noteTypeId}-${ticket.id}`);
        console.log({progressNoteType, sections_obj, sections});
        const attributes = {externalId, noteType, sections};
        try {
            let resp = await this.api.fetchJson(
                ticket.relationships.progressNotes.links.related,
                {
                    method: 'POST',
                    json: {
                        data: {
                            type: 'PCCProgressNote',
                            attributes,
                        },
                    },
                },
            );
            console.log('Created progress note', resp);
            await this.success('Progress note created in PointClickCare');
        } catch (error) {
            console.error('Error creating progress note', error);
            await this.error('Progress note creation failed', error);
        }
    };
}

class TicketActionBatch extends TicketActions {
    constructor({success_message, error_message, ...args}) {
        super(args);
        this.success_message = success_message;
        this.error_message = error_message;
        this._num_success = this._num_error = 0;
    }

    async success(message) {
        console.log(message);
        this._num_success++;
    }

    async error(message, error) {
        console.error(await this.formatErrorMessage(message, error), error);
        this._num_error++;
    }

    async flush() {
        if (this._num_success && !this._num_error) {
            await super.success(this.success_message);
        } else if (this._num_error) {
            const total = this._num_error + this._num_success;
            await super.error(
                `${this.error_message} (${this._num_error}/${total} errors)`,
            );
        }
        this._num_success = this._num_error = 0;
    }
}

export function useTicketActions(include = []) {
    const api = useApi();
    const {enqueueSnackbar} = useSnackbar();

    const ticketActions = useMemo(
        () => new TicketActions({api, include, enqueueSnackbar}),
        [api, include, enqueueSnackbar],
    );

    return ticketActions;
}
