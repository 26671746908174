import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Menu,
    MenuItem,
    ListItemIcon,
    FormGroup,
    Button,
    ButtonGroup,
} from '@material-ui/core';
import {Print, PrintDisabled, ArrowDropDown} from '@material-ui/icons';
import {Formik, Form, Field} from 'formik';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import {selectFacilityFaxes} from 'lib/selectors';
import {useFeatures} from 'lib/util';
import {FaxOptionsField} from 'components/fields/FaxOptions';

const selectFaxOptions = createSelector(
    selectFacilityFaxes,
    fp.map(f => `${f.attributes.name}:${f.attributes.number}`),
);

export default function TicketResolveDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const faxOptions = useSelector(selectFaxOptions);
    const [initialValues, setInitialValues] = useState({});
    const [showPCC, setShowPCC] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const features = useFeatures();

    const handleSubmit = async values => {
        resolve(values);
        setResolve(null);
        setMenuAnchor(null);
    };

    const handleCancel = () => {
        resolve(null);
        setResolve(null);
        setMenuAnchor(null);
    };

    dialogRef.current = ticket =>
        new Promise((resolve, reject) => {
            const hasPCC = fp.pipe([
                fp.get('attributes.patient_identifier'),
                fp.find(
                    ident => ident.system === 'http://www.pointclickcare.com',
                ),
            ])(ticket);

            const newInitialValues = {
                orders: fp.get('attributes.orders', ticket),
                fax: fp.get('attributes.fax_number', ticket),
                sendFax: false,
                uploadPF: true,
                uploadPCC: features.pcc_pdf_upload && hasPCC,
                createPCCProgressNote:
                    features.pcc_progress_note_upload &&
                    hasPCC &&
                    !fp.get('attributes.pcc_progressNoteId', ticket),
            };

            setShowPCC(hasPCC);
            setInitialValues(newInitialValues);
            setResolve(() => resolve);
        });

    const handleSubmitWithFax = formikProps => ev => {
        formikProps.setFieldValue('sendFax', true);
        formikProps.handleSubmit();
    };

    const handleSubmitWithoutFax = formikProps => ev => {
        formikProps.setFieldValue('sendFax', false);
        formikProps.handleSubmit();
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <Dialog
                        open={!!resolve}
                        onClose={handleCancel}
                        scroll='paper'
                        maxWidth='xl'
                        fullWidth
                    >
                        <DialogTitle>Resolve Ticket?</DialogTitle>
                        <DialogContent dividers>
                            <p>
                                You are resolving this ticket with the following
                                orders:
                            </p>
                            <Field
                                name='orders'
                                component={TextField}
                                multiline
                                fullWidth
                                label='Orders'
                            />
                            <FaxOptionsField
                                name='fax'
                                label='Fax number(s)'
                                options={faxOptions}
                                multiple
                            />
                            <FormGroup>
                                <Field
                                    name='uploadPF'
                                    component={CheckboxWithLabel}
                                    type='checkbox'
                                    Label={{
                                        label:
                                            'Upload the ticket to Practice Fusion',
                                    }}
                                />
                                {features.pcc_pdf_upload && showPCC && (
                                    <Field
                                        name='uploadPCC'
                                        type='checkbox'
                                        component={CheckboxWithLabel}
                                        Label={{
                                            label:
                                                'Upload the ticket to PointClickCare',
                                        }}
                                    />
                                )}
                                {features.pcc_progress_note_upload && showPCC && (
                                    <Field
                                        name='createPCCProgressNote'
                                        type='checkbox'
                                        component={CheckboxWithLabel}
                                        Label={{
                                            label:
                                                'Create a progress note in PointClickCare',
                                        }}
                                    />
                                )}
                            </FormGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <ButtonGroup variant='contained' color='primary'>
                                <Button
                                    onClick={handleSubmitWithFax(formikProps)}
                                    startIcon={<Print />}
                                    name='submit'
                                    value='button1'
                                >
                                    Resolve Ticket & Fax
                                </Button>
                                <Button
                                    size='small'
                                    onClick={ev =>
                                        setMenuAnchor(ev.currentTarget)
                                    }
                                >
                                    <ArrowDropDown />
                                </Button>
                            </ButtonGroup>
                        </DialogActions>
                    </Dialog>
                    <Menu
                        open={!!menuAnchor}
                        anchorEl={menuAnchor}
                        on
                        onClose={() => setMenuAnchor()}
                    >
                        <MenuItem onClick={handleSubmitWithoutFax(formikProps)}>
                            <ListItemIcon>
                                <PrintDisabled />
                            </ListItemIcon>
                            Resolve ticket without faxing
                        </MenuItem>
                    </Menu>
                </Form>
            )}
        </Formik>
    );
}
