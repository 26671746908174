import React, {useMemo, useCallback, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Link, useHistory} from 'react-router-dom';

import {makeStyles} from '@material-ui/core';

import BasePage from 'components/BasePage';
import {useApi, components} from '@arborian/narrf';
import {toPhoneNumber} from 'lib/util';
import {selectAvailableFacilities} from 'lib/selectors';

import {url_for} from '../routes';
const {
    DataTable,
    Column,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const useStyles = makeStyles(theme => ({
    tableContainer: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 375,
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
}));

function PhoneNumberField({value, onChange}) {
    return (
        <div
            className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl'
            style={{fontSize: 13}}
        >
            <input
                className='MuiInputBase-input MuiInput-input'
                label='Number'
                value={value}
                onChange={e => onChange(toPhoneNumber(e.target.value))}
            />
        </div>
    );
}

export default function FaxDirectoryPage({match}) {
    const api = useApi();
    const classes = useStyles();
    const faxCollection = api.url_for('fax_directory.collection');

    const fetchData = useCallback(
        fetchOptions => {
            return api.fetchDataTable(
                api.url_for('fax_directory.collection'),
                fetchOptions,
            );
        },
        [api],
    );

    const facilities = useSelector(selectAvailableFacilities);
    const lookupFacilities = useMemo(() => {
        return fp.pipe([
            fp.filter(o => o.id !== '__all__'),
            fp.map(o => [o.id, o.attributes.name]),
            fp.fromPairs,
        ])(facilities);
    }, [facilities]);

    useEffect(() => {
        api.fetchAllJsonApi(api.url_for('facility.collection'));
    }, [api]);

    const toFacilityLink = rowData => (
        <Link
            to={url_for('adminFacility', {
                facid: rowData.data.attributes.facility_id,
            })}
        >
            {fp.get(rowData.data.attributes.facility_id, lookupFacilities)}
        </Link>
    );

    const onRowAdd = async row => {
        await api.fetchJson(faxCollection, {
            method: 'POST',
            json: {
                data: {
                    type: 'FacilityFax',
                    attributes: row.data.attributes,
                },
            },
        });
    };

    const onRowDelete = async row => {
        console.log(row);
        await api.fetchJson(row.data.links.self, {
            method: 'DELETE',
        });
    };

    const onRowUpdate = async (row, oldRow) => {
        await api.fetchJson(row.data.links.self, {
            method: 'PATCH',
            json: {
                data: {
                    type: 'FacilityFax',
                    attributes: row.data.attributes,
                },
            },
        });
    };

    const history = useHistory();
    const defaultFetchOptions = {
        sort: {field: 'attributes.name', direction: 'asc'},
        page: {size: 5},
    };
    const fetchOptions = useLocationFetchOptions(defaultFetchOptions, 'table.');
    const onChangeFetchOptions = o => {
        const newParams = updateLocationFetchOptions(o, 'table.');
        history.push('?' + newParams.toString());
    };
    console.log('fetchOptions', fetchOptions);

    return (
        <BasePage>
            <div className={classes.tableContainer}>
                <DataTable
                    title='Global Fax Directory'
                    size='small'
                    data={fetchData}
                    fetchOptions={fetchOptions}
                    onChangeFetchOptions={onChangeFetchOptions}
                    editable={{onRowAdd, onRowDelete, onRowUpdate}}
                    options={{
                        search: false,
                        sorting: true,
                        actionsColumnIndex: -1,
                    }}
                >
                    <Column title='Label' field='attributes.name' />
                    <Column
                        title='Number'
                        field='attributes.number'
                        EditComponent={PhoneNumberField}
                    />
                    <Column
                        title='Facility'
                        field='attributes.facility_id'
                        lookup={lookupFacilities}
                        render={toFacilityLink}
                    />
                </DataTable>
            </div>
        </BasePage>
    );
}
