import React, {useCallback, useRef} from 'react';
import MaterialTable from 'material-table';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

const columns = [
    {
        title: 'Date',
        field: 'data.attributes.created',
        type: 'datetime',
        defaultSort: 'desc',
    },
    {title: 'Changes', field: 'data.attributes.message'},
];

export default function TicketHistoryTable({ticket, actions}) {
    const api = useApi();
    const tableRef = useRef();
    const latestRef = useRef();
    const fetchHistory = useCallback(
        async options => {
            if (!ticket) return {data: [], page: 0, totalCount: 0}; //null;
            let data = await api.fetchMaterialTable(
                ticket.relationships.history.links.related,
                options,
            );
            if (!latestRef.current) {latestRef.current = data.data[0]};  // To disable reverting to latest
            return data;
        },
        [api, ticket],
    );

    const refresh = useCallback(async () => {
        tableRef.current.onQueryChange();
    }, [tableRef]);

    const handleRestore = useCallback(
        async (event, rowData) => {
            try {
                await api.fetchJsonApi(rowData.data.links.restore, {
                    method: 'POST',
                    include: actions.include,
                });
                actions.success('Ticket restored to previous version');
            } catch (err) {
                actions.error('Ticket restore failed', err);
            }
            refresh();
        },
        [api, refresh, actions],
    );

    return (
        <MaterialTable
            title='Ticket Activity'
            columns={columns}
            data={fetchHistory}
            tableRef={tableRef}
            options={{
                search: false,
                sorting: false,
                paging: true,
                filtering: false,
                actionsColumnIndex: -1,
            }}
            actions={[
                {
                    icon: 'refresh',
                    tooltip: 'Refresh',
                    isFreeAction: true,
                    onClick: refresh,
                },
                rowData => ({
                    tooltip: 'Restore this version',
                    icon: 'history',
                    disabled: rowData.data.id === fp.get('data.id', latestRef.current),
                    onClick: handleRestore,
                }),
            ]}
        />
    );
}
