import React, {useState} from 'react';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    makeStyles,
} from '@material-ui/core';
import {KeyboardDateTimePicker} from '@material-ui/pickers';

import BasePage from 'components/BasePage';
import {useApi} from '@arborian/narrf';
import SyncPatientsButton from 'components/SyncPatientsButton';

const useStyles = makeStyles(theme => ({
    adminCard: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

export default function EventReportPage() {
    const classes = useStyles();
    const api = useApi();
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());

    const generateReport = async () => {
        console.log('Generate report', startTime, endTime);

        const resp = await api.fetchJsonApi(api.url_for('event.report'), {
            method: 'POST',
            filter: {
                timestamp: JSON.stringify({
                    $gte: {$date: startTime.getTime()},
                    $lte: {$date: endTime.getTime()},
                }),
            },
        });
        window.location = resp.data.links.attachment;
    };

    return (
        <BasePage title='Admin Tools'>
            <>
                <Card>
                    <CardHeader title='Admin Actions' />
                    <CardContent>
                        <SyncPatientsButton />
                    </CardContent>
                </Card>
                <br />
                <Card>
                    <CardHeader title='Generate Event Report' />
                    <CardContent className={classes.adminCard}>
                        <KeyboardDateTimePicker
                            variant='inline'
                            label='Start Time'
                            value={startTime}
                            onChange={setStartTime}
                            format='MM/dd/yyyy HH:mm'
                        />
                        <br />
                        <KeyboardDateTimePicker
                            variant='inline'
                            label='End Time'
                            value={endTime}
                            onChange={setEndTime}
                            format='MM/dd/yyy HH:mm'
                        />
                        <br />
                        <br />
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={generateReport}
                        >
                            Generate Report
                        </Button>
                    </CardContent>
                </Card>
            </>
        </BasePage>
    );
}
