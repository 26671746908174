import React, {Fragment, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';

import PCCPatientDataTable from './PCCPatientDataTable';
import {FacilityDate} from './FacilityDates';

import {useApi, ducks} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    note: {
        padding: theme.spacing(2),
        '& h6': {
            margin: 0,
        },
        '& p': {
            whiteSpace: 'pre-wrap',
        },
    },
}));

export function ProgressNoteDetail({value, facility}) {
    const api = useApi();
    const classes = useStyles();
    const progressNote = useSelector(
        ducks.jsonapi.selectObject(['PCCProgressNote', value.id]),
    );
    useEffect(() => {
        api.fetchJsonApi(value.links.self);
    }, [api, value.links.self]);

    return (
        <div className={classes.note}>
            {fp.pipe([
                fp.get('attributes.sections'),
                fp.map(s => (
                    <Fragment key={s.name}>
                        <h6>{s.name}</h6>
                        <p>{s.value}</p>
                    </Fragment>
                )),
            ])(progressNote)}
        </div>
    );
}

export default function ProgressNotesTable({patient, facility}) {
    if (!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='progressNotes'
            columns={[
                {
                    title: 'Effective Date',
                    field: 'data.attributes.effectiveDate',
                    type: 'datetime',
                    render: rd => (
                        <FacilityDate
                            value={rd.data.attributes.effectiveDate}
                            facility={facility}
                        />
                    ),
                },
                {
                    title: 'Created Date',
                    field: 'data.attributes.effectiveDate',
                    type: 'datetime',
                    render: rd => (
                        <FacilityDate
                            value={rd.data.attributes.createdDate}
                            facility={facility}
                        />
                    ),
                },
                {title: 'Type', field: 'data.attributes.progressNoteType'},
                {title: 'Created By', field: 'data.attributes.createdBy'},
            ]}
            detailPanel={[
                {
                    render: rowData => (
                        <ProgressNoteDetail
                            value={rowData.data}
                            facility={facility}
                        />
                    ),
                },
            ]}
        />
    );
}
