import React, {useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {Dialog} from '@material-ui/core';
import MaterialTable from 'material-table';

import {
    selectTicketOwnersLookup,
    selectHealthcareProvidersLookup,
} from 'lib/selectors';

export default function ShiftAssignmenDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [rows, setRows] = useState([]);
    const [data, setData] = useState(null);
    const lookupOwners = useSelector(selectTicketOwnersLookup);
    const lookupProviders = useSelector(selectHealthcareProvidersLookup);

    const columns = useMemo(
        () => [
            {title: 'Shift', field: 'data.attributes.name', editable: false},
            {
                title: 'Owner',
                field: 'owner_id',
                lookup: lookupOwners,
            },
            {
                title: 'Provider',
                field: 'provider_id',
                lookup: lookupProviders,
            },
        ],
        [lookupOwners, lookupProviders],
    );

    const handleCancel = () => {
        resolve({action: 'cancel'});
        setResolve(null);
    };

    const onBulkUpdate = async changes => {
        resolve({action: 'save', changes});
        setResolve(null);
    };

    dialogRef.current = ({rows, shifts, assignmentIndex}) => {
        return new Promise((resolve, reject) => {
            setRows(rows);
            const shiftData = fp.map(shift => {
                let data = {...shift};
                let shiftAssignments = fp.map(
                    row => fp.get([row.data.id, shift.id], assignmentIndex),
                    rows,
                );
                let shiftOwners = fp.pipe([
                    fp.map('attributes.owner_id'),
                    fp.uniq,
                ])(shiftAssignments);
                let shiftProviders = fp.pipe([
                    fp.map('attributes.provider_id'),
                    fp.uniq,
                ])(shiftAssignments);
                const owner_id =
                    shiftOwners.length === 1 ? shiftOwners[0] : null;
                const provider_id =
                    shiftProviders.length === 1 ? shiftProviders[0] : null;

                return {data, owner_id, provider_id};
            }, shifts);
            const fallbackOwners = fp.pipe([
                fp.map('data.attributes.default_owner_id'),
                fp.uniq,
            ])(rows);
            const fallbackProviders = fp.pipe([
                fp.map('data.attributes.default_provider_id'),
                fp.uniq,
            ])(rows);
            const owner_id =
                fallbackOwners.length === 1 ? fallbackOwners[0] : null;
            const provider_id =
                fallbackProviders.length === 1 ? fallbackProviders[0] : null;
            setData([
                {owner_id, provider_id, data: {attributes: {name: 'Fallback'}}},
                ...shiftData,
            ]);
            setResolve(() => resolve);
        });
    };
    const facCount = rows.length;

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <MaterialTable
                title={`Shift Assignments (editing ${facCount} facilities)`}
                options={{
                    filtering: false,
                    sorting: false,
                    paging: false,
                    search: false,
                }}
                localization={{
                    header: {
                        actions: '',
                    },
                }}
                editable={{onBulkUpdate}}
                columns={columns}
                data={data}
                actions={[
                    {
                        hidden: true,
                        icon: 'delete',
                        tooltip: 'Neverseen',
                    },
                ]}
            />
        </Dialog>
    );
}
