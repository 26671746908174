import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import {useApi} from '@arborian/narrf';
import {url_for} from 'routes';

export default function AutoLogout({timeout}) {
    const api = useApi();
    const history = useHistory();
    const onIdle = useCallback(() => {
        if(api.isAuthorized()) {
            history.push(url_for('logoutRedirect'))
        }
    }, [api, history])
    return <IdleTimer timeout={timeout} onIdle={onIdle}/>
}
