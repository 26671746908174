import React, {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {makeStyles} from '@material-ui/core';
import MaterialTable from 'material-table';
import fp from 'lodash/fp';

import BasePage from 'components/BasePage';
import {useApi, ducks} from '@arborian/narrf';
import {selectAsList} from 'lib/selectors';

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    wideTable: {
        width: 800,
        margin: 15,
    },
}));

const orderSelector = createSelector(
    selectAsList('Order'),
    fp.map(data => ({data})),
);
const instructionSelector = createSelector(
    selectAsList('Instruction'),
    instructions => fp.map(data => ({data}), instructions),
);

export default function OrdersPage() {
    const api = useApi();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [init, setInit] = useState(false);
    useEffect(() => {
        if (!init) {
            setInit(true);
            api.fetchAllJsonApi(api.url_for('order.order_collection'));
            api.fetchAllJsonApi(api.url_for('order.instruction_collection'));
        }
    }, [api, init]);

    const allOrders = useSelector(orderSelector);

    const columns = [
        {
            title: 'Title',
            field: 'data.attributes.title',
            defaultSort: 'asc',
        },
    ];

    const onRowAdd = async rowData => {
        console.log({rowData});
        await api.fetchJsonApi(api.url_for('order.order_collection'), {
            method: 'POST',
            json: {
                data: {
                    type: 'Order',
                    attributes: {
                        title: rowData.data.attributes.title,
                    },
                },
            },
        });
    };

    const onRowUpdate = async rowData => {
        console.log({rowData});
        await api.fetchJsonApi(rowData.data.links.self, {
            method: 'PATCH',
            json: {data: rowData.data},
        });
    };

    const onRowDelete = async rowData => {
        await api.fetchJsonApi(rowData.data.links.self, {
            method: 'DELETE',
        });
        dispatch(ducks.jsonapi.deleteData(rowData.data));
    };

    const localizationOptions = {
        body: {
            editRow: {
                deleteText:
                    'Delete this order template & instructions. Are you sure?',
            },
            emptyDataSourceMessage: 'No order templates to display',
        },
    };

    return (
        <BasePage>
            <header className={classes.header}>
                <div className={classes.wideTable}>
                    <h3>Orders Templates</h3>
                    <MaterialTable
                        title={null}
                        columns={columns}
                        data={allOrders}
                        localization={localizationOptions}
                        options={{
                            pageSize: 10,
                            search: false,
                            sorting: true,
                            paging: true,
                            actionsColumnIndex: -1,
                        }}
                        editable={{
                            onRowAdd: onRowAdd,
                            onRowUpdate: onRowUpdate,
                            onRowDelete: onRowDelete,
                        }}
                        detailPanel={rowData => (
                            <InstructionsDetailPanel
                                orderId={rowData.data.id}
                            />
                        )}
                    />
                </div>
            </header>
        </BasePage>
    );
}

const InstructionsDetailPanel = ({orderId}) => {
    const api = useApi();
    const dispatch = useDispatch();
    const allInstructions = useSelector(instructionSelector);
    const data = useMemo(
        () =>
            fp.filter(
                i => i.data.attributes.order_id === orderId,
                allInstructions,
            ),
        [allInstructions, orderId],
    );

    const columns = [
        {
            title: 'Position',
            field: 'data.attributes.position',
            defaultSort: 'asc',
            hidden: true,
        },
        {
            title: 'Instruction Text',
            field: 'data.attributes.text',
        },
    ];

    const onRowAdd = async instructionData => {
        console.log({data});
        let nextPos = data.length + 1;
        await api.fetchJsonApi(api.url_for('order.instruction_collection'), {
            method: 'POST',
            json: {
                data: {
                    type: 'Instruction',
                    attributes: {
                        text: instructionData.data.attributes.text,
                        position: nextPos,
                        order_id: orderId,
                    },
                },
            },
        });
    };

    const onRowUpdate = async instructionData => {
        console.log({instructionData});
        await api.fetchJsonApi(instructionData.data.links.self, {
            method: 'PATCH',
            json: {data: instructionData.data},
        });
    };

    const onRowDelete = async instructionData => {
        await api.fetchJsonApi(instructionData.data.links.self, {
            method: 'DELETE',
        });
        dispatch(ducks.jsonapi.deleteData(instructionData.data));
    };

    return (
        <MaterialTable
            title={null}
            columns={columns}
            data={data}
            localization={{
                body: {emptyDataSourceMessage: 'No instructions to display'},
            }}
            options={{
                pageSize: 10,
                search: false,
                sorting: true,
                paging: true,
                actionsColumnIndex: -1,
            }}
            editable={{
                onRowAdd,
                onRowUpdate,
                onRowDelete,
            }}
        />
    );
};
