import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';

import {selectFacilities} from 'lib/selectors';
import {AutocompleteField} from './Autocomplete';

export function FacilitySelectField(props) {
    const api = useApi();
    const facilities = useSelector(selectFacilities);
    useEffect(() => {
        if (!facilities.length) {
            api.fetchAllJsonApi(api.url_for('facility.collection'));
        }
    }, [api, facilities]);

    const getOptionSelected = (o, val) => o.id === val.id;

    return (
        <AutocompleteField
            options={facilities || []}
            getOptionLabel={fp.get('attributes.name')}
            getOptionSelected={getOptionSelected}
            {...props}
        />
    );
}
