import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {ducks} from '@arborian/narrf';

import DynaFormExpansionPanel from 'components/DynaFormExpansionPanel';

export default function TicketFormData({ticket, actions, readOnly}) {
    const [expanded, setExpanded] = useState({});
    const ticketRel = useSelector(ducks.jsonapi.selectRelated(ticket));
    const form = ticketRel.form;

    const handleTicketChange = async session_data => {
        await actions.patch(ticket, {session_data});
        setExpanded({});
    };

    const completed_pages = fp.get('attributes.completed_pages', ticket);
    const session = fp.get('attributes.session_data', ticket);
    const setPageExpanded = page => (ev, isExpanded) => {
        setExpanded({...expanded, [page]: isExpanded});
    };
    const pages = fp.get('attributes.form', form);
    console.log('Got ticketform', ticket, form);
    return (
        <>
            {fp.pipe([
                fp.keys,
                fp.filter(page => completed_pages.includes(page)),
                fp.map(page => (
                    <DynaFormExpansionPanel
                        key={page}
                        expanded={expanded[page]}
                        onChangeExpanded={setPageExpanded(page)}
                        form={pages}
                        session={session}
                        pageid={page}
                        handleTicketChange={handleTicketChange}
                        readOnly={
                            readOnly || ticket.attributes.status === 'resolved'
                        }
                    />
                )),
            ])(pages)}
        </>
    );
}
