import React, {useState, useEffect, useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {
    List,
    Card,
    CardHeader,
    CardContent,
    ListItem,
    ListItemText,
    ListSubheader,
    Tooltip,
    IconButton,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from '@material-ui/core';
import {DoubleArrow, Block} from '@material-ui/icons';

import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';
import {createSelector} from '@reduxjs/toolkit';

import {useApi} from '@arborian/narrf';
import {selectAsList} from 'lib/selectors';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '& > .MuiFormControl-root': {
            margin: theme.spacing(1),
        },
    },
    listsContainer: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        alignItems: 'stretch',
        '& .MuiCard-root': {
            flex: 1,
            margin: theme.spacing(1),
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
        },
        '& .MuiCardHeader-root': {
            padding: theme.spacing(1, 2),
        },
        '& .MuiCardContent-root': {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        '& .MuiList-root': {
            backgroundColor: theme.palette.background.paper,
        },
        '& .MuiList-root .MuiList-root .MuiListItem-root': {
            paddingLeft: theme.spacing(3),
        },
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
    },
}));

const selectAllInstructions = createSelector(
    ducks.jsonapi.selectObject('Order'),
    selectAsList('Instruction'),
    (oIndex, iList) =>
        fp.pipe([
            fp.sortBy(['attributes.order_id', 'attributes.position']),
            fp.map(i => {
                const o = fp.get(i.attributes.order_id, oIndex);
                return {
                    instruction_id: i.id,
                    instruction_text: i.attributes.text,
                    order_id: i.attributes.order_id,
                    order_title: o && o.attributes.title,
                };
            }),
        ])(iList),
);

const selectAllInstructionsIndex = createSelector(
    selectAllInstructions,
    fp.pipe([fp.map(i => [i.instruction_id, i]), fp.fromPairs]),
);

function union(setA, setB) {
    let _union = new Set(setA);
    for (let elem of setB) {
        _union.add(elem);
    }
    return _union;
}
function difference(setA, setB) {
    let _difference = new Set(setA);
    for (let elem of setB) {
        _difference.delete(elem);
    }
    return _difference;
}

function InstructionItem({instruction, onClick}) {
    const handleClick = (ev, ...args) => {
        onClick([instruction.instruction_id]);
    };
    return (
        <ListItem button onClick={handleClick} value={instruction}>
            <ListItemText primary={instruction.instruction_text} />
        </ListItem>
    );
}

function OrderItems({title, instructions, onClick}) {
    return (
        <>
            <ListSubheader>{title}</ListSubheader>
            <List dense disablePadding>
                {fp.map(
                    instruction => (
                        <InstructionItem
                            key={instruction.instruction_id}
                            instruction={instruction}
                            onClick={onClick}
                        />
                    ),
                    instructions,
                )}
            </List>
        </>
    );
}

function TransferPanelLeft({items, onSelect, ordersCategory}) {
    const classes = useStyles();
    let sortedItems = fp.pipe([fp.toPairs, fp.sortBy((key, val) => key)])(
        items,
    );
    let flatItems = fp.flatMap(i => i, items);
    const handleSelectAll = () => {
        onSelect(fp.map('instruction_id', flatItems));
    };

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                title='Available Orders'
                action={
                    <Tooltip title='Select all'>
                        <IconButton onClick={handleSelectAll}>
                            <DoubleArrow />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <div className={classes.listContainer}>
                    <List dense>
                        {ordersCategory
                            ? fp.map(
                                  instruction => (
                                      <InstructionItem
                                          key={instruction.instruction_id}
                                          instruction={instruction}
                                          onClick={onSelect}
                                      />
                                  ),
                                  flatItems,
                              )
                            : fp.map(
                                  ([title, instructions]) => (
                                      <OrderItems
                                          key={title}
                                          title={title}
                                          instructions={instructions}
                                          onClick={onSelect}
                                      />
                                  ),
                                  sortedItems,
                              )}
                    </List>
                </div>
            </CardContent>
        </Card>
    );
}

function TransferPanelRight({items, onSelect}) {
    const classes = useStyles();
    const handleSelectAll = () => {
        const value = fp.map('instruction_id', items);
        onSelect(value);
    };
    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                title='Selected Orders'
                action={
                    <Tooltip title='Remove all'>
                        <IconButton onClick={handleSelectAll}>
                            <Block />
                        </IconButton>
                    </Tooltip>
                }
            />
            <CardContent>
                <div className={classes.listContainer}>
                    <List dense>
                        {fp.map(
                            instruction => (
                                <InstructionItem
                                    key={instruction.instruction_id}
                                    instruction={instruction}
                                    onClick={onSelect}
                                />
                            ),
                            items,
                        )}
                    </List>
                </div>
            </CardContent>
        </Card>
    );
}

export default function OrderTransferList({value, onChange}) {
    const api = useApi();
    const classes = useStyles();
    const [ordersCategory, setOrdersCategories] = useState('');
    const [rhs, setRhs] = useState(new Set());
    const [init, setInit] = useState(false);
    useEffect(() => {
        if (!init) {
            setInit(true);
            api.fetchAllJsonApi(api.url_for('order.order_collection'));
            api.fetchAllJsonApi(api.url_for('order.instruction_collection'));
        }
    }, [api, init]);

    const selectSortedOrders = createSelector(
        selectAsList('Order'),
        fp.sortBy('attributes.title'),
    );
    const allOrders = useSelector(selectSortedOrders);
    const allInstructions = useSelector(selectAllInstructions);
    const instructionsIndex = useSelector(selectAllInstructionsIndex);

    const instructionsLeft = useMemo(
        () =>
            fp.pipe([
                fp.filter(
                    i => !ordersCategory || i.order_id === ordersCategory.id,
                ),
                fp.filter(i => !rhs.has(i.instruction_id)),
                fp.groupBy(i => i.order_title),
            ])(allInstructions),
        [allInstructions, rhs, ordersCategory],
    );

    // Orders should be added the values list in the order they are selected.
    // It turns out that Javascript Sets keep their contents in the order
    // that they were inserted. So all we need to do here is look up the actual
    // instruction from the Set.
    const updateRhs = newRhs => {
        setRhs(newRhs);
        const newValue = fp.pipe([
            fp.toArray,
            fp.map(id => instructionsIndex[id]),
        ])(newRhs);
        onChange(newValue);
    };

    const handleSelect = ids => {
        updateRhs(union(rhs, ids));
    };

    const handleDeselect = ids => {
        updateRhs(difference(rhs, ids));
    };

    const handleOrdersChange = event => {
        let order = event.target.value;
        console.log('Set category', order);
        setOrdersCategories(order);
    };

    return (
        <div className={classes.root}>
            {/* Selector */}
            <FormControl variant='outlined' margin='dense' size='small'>
                <InputLabel>Filter Orders</InputLabel>
                <Select
                    value={ordersCategory}
                    onChange={handleOrdersChange}
                    label='Orders Categories'
                >
                    <MenuItem value=''>All</MenuItem>
                    {allOrders.map(order => (
                        <MenuItem key={order.id} value={order}>
                            {order.attributes.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div className={classes.listsContainer}>
                <TransferPanelLeft
                    items={instructionsLeft}
                    ordersCategory={ordersCategory}
                    onSelect={handleSelect}
                />
                <TransferPanelRight items={value} onSelect={handleDeselect} />
            </div>
        </div>
    );
}
