import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {Button} from '@material-ui/core';
import {AssignmentTurnedIn} from '@material-ui/icons';
import {format} from 'date-fns';

import {ducks, useDialog, useApi} from '@arborian/narrf';

import {selectProgressNoteInitialValues} from 'lib/selectors';
import {strWithNewline} from 'lib/util';

// Written as a Thunk so we can get access to the state for things like
// the userinfo and the (updated) narrative after resolving the ticket
const resolveTicket = (ticket, actions, formValues) => async (
    dispatch,
    getState,
) => {
    console.log('resolveTicket', formValues);

    const userinfo = ducks.auth.selectUserinfo(getState());
    let nowStr = format(new Date(), 'yyyy-MM-dd hh:mm a');
    let signedMsg = `Electronically signed by ${userinfo.name} at ${nowStr}`;
    let notes = strWithNewline(ticket.attributes.notes || '') + signedMsg;

    await actions.patch(
        ticket,
        {
            notes,
            orders: formValues.orders,
            fax_number: formValues.fax,
            status: 'resolved',
        },
        {
            success_message: 'Ticket resolved',
            error_message: 'Error resolving ticket',
        },
    );
    if (formValues.sendFax) {
        await actions.sendFax(ticket, formValues.fax);
    }
    if (formValues.uploadPF) {
        await actions.uploadPF(ticket);
    }
    if (formValues.uploadPCC) {
        await actions.uploadPCC(ticket);
    }
    if (formValues.createPCCProgressNote) {
        const values = selectProgressNoteInitialValues(ticket.id)(getState());
        console.log('creating pcc progress note', values);
        await actions.createPCCProgressNote(ticket, values);
    }
    actions.refresh(ticket);
};

export default function Resolve({ticket, actions, setPendingResolve}) {
    const api = useApi();
    const dispatch = useDispatch();
    const resolveDialog = useDialog('ticketResolve');
    const relTicket = useSelector(ducks.jsonapi.selectRelated(ticket));

    const handleResolve = async () => {
        const values = await resolveDialog(ticket);
        console.log('Resolving', {values, relTicket});
        if (values) {
            // Need to fetch progress note types for PCC facility of the ticket (if there is one)
            if (relTicket.pcc_facility) {
                console.log('Fetch progress note types');
                await api.fetchJsonApi(relTicket.pcc_facility.links.self, {
                    include: ['progressNoteTypes'],
                });
            }
            console.log('Dispatch ticket resolution');

            dispatch(resolveTicket(ticket, actions, values));
        } else {
            console.log('Resolve cancelled');
        }
    };

    return (
        <Button
            variant='contained'
            color='primary'
            disabled={
                !ticket.attributes.orders ||
                fp.trim(ticket.attributes.orders).length === 0
            }
            startIcon={<AssignmentTurnedIn />}
            onClick={handleResolve}
        >
            Resolve Ticket
        </Button>
    );
}
