import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {url_for} from 'routes';
import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';

import BasePage from 'components/BasePage';
import UserTable from 'components/UserTable';

export default function PermissionsPage({match}) {
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const history = useHistory();
    if(!userinfo) return null;
    const isStaff = fp.includes('staff', userinfo.scopes);
    const isAdmin = fp.includes('__admin__', userinfo.scopes);
    if(!isStaff && !isAdmin) {history.push(url_for('home'))}
    return (
        <BasePage wide title="TCPA">
            <UserTable />
        </BasePage>
    )
}
