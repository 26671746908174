import React, {useMemo, useState} from 'react';
import fp from 'lodash/fp';
import * as I from '@material-ui/icons';

import {Chip, Button, Menu, MenuItem} from '@material-ui/core';

function AddItem({value, options, getOptionLabel, onSelect, children}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = ev => {
        setAnchorEl(ev.target);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelect = item => ev => {
        onSelect(item)(ev);
        handleClose();
    };

    return (
        <>
            <Button variant='outlined' onClick={handleClick}>
                {children}
            </Button>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
                {fp.map(
                    o => (
                        <MenuItem key={o.id} onClick={handleSelect(o)}>
                            {getOptionLabel(o)}
                        </MenuItem>
                    ),
                    options,
                )}
            </Menu>
        </>
    );
}

export default function ChipList({
    value,
    options,
    icon,
    addLabel,
    getOptionLabel,
    getOptionSelected,
    onChange,
}) {
    if (!getOptionLabel) {
        getOptionLabel = o => o;
    }
    const availableOptions = useMemo(() => {
        return fp.filter(o => !fp.includes(o, value), options);
    }, [options, value]);

    const handleAdd = item => ev => {
        const newValue = fp.uniq([...value, item]);
        onChange(newValue);
    };

    const handleDelete = item => ev => {
        const newValue = fp.filter(i => i !== item, value);
        onChange(newValue);
    };

    return (
        <>
            {fp.pipe([
                fp.filter(o => o && fp.includes(o, value)),
                fp.map(o => (
                    <Chip
                        key={o.id}
                        label={getOptionLabel(o)}
                        onDelete={onChange && handleDelete(o)}
                    />
                )),
            ])(value)}
            {onChange && (
                <AddItem
                    value={value}
                    options={availableOptions}
                    onSelect={handleAdd}
                    getOptionLabel={getOptionLabel}
                >
                    {icon || <I.Add />} {addLabel}
                </AddItem>
            )}
        </>
    );
}
