import React from 'react';
import {createBrowserHistory} from 'history';
import {MuiThemeProvider, CssBaseline} from '@material-ui/core';
import {Provider as ReduxProvider} from 'react-redux';
import {configureStore} from '@reduxjs/toolkit';
import {SnackbarProvider} from 'notistack';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsAdapter from '@date-io/date-fns';

import {reducer, ApiProvider, ErrorBoundary} from '@arborian/narrf';
import {logError} from './lib/util';
import DialogProvider from './components/DialogProvider';
import Router from './components/Router';
import theme from './theme';

const API_ROOT = process.env.REACT_APP_API_ROOT;
const APP_KEY = process.env.REACT_APP_APP_KEY;
const store = configureStore({reducer});
const history = createBrowserHistory();

function App() {
    console.log({API_ROOT});
    return (
        <ErrorBoundary fallback={errorScreen} onError={logError}>
            <ReduxProvider store={store}>
                <ApiProvider
                    ui_home='/'
                    ui_login='/login'
                    history={history}
                    api_root={API_ROOT}
                    client_id={APP_KEY}
                    onError={logError}
                >
                    <CssBaseline />
                    <MuiThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsAdapter}>
                            <SnackbarProvider>
                                <DialogProvider>
                                    <Router />
                                </DialogProvider>
                            </SnackbarProvider>
                        </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                </ApiProvider>
            </ReduxProvider>
        </ErrorBoundary>
    );
}

export const errorScreen = () => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <h2>Something went wrong.</h2>
        <p>
            Please refresh the page and try again. If this issue continues,
            please contact{' '}
            <a href='mailto:services@tcpamd.com'>services@tcpamd.com</a>.
        </p>
    </div>
);

export default App;
