import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Menu,
    MenuItem,
    Button,
    ButtonGroup,
    useMediaQuery,
    useTheme,
    makeStyles,
} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';

import OrdersTransferList from './OrdersTransferList';

const useStyles = makeStyles(theme => ({
    builder: {
        '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            padding: theme.spacing(1),
        },
    },
}));

function OrderBuilderDialog({ticket, actions, open, onClose}) {
    const classes = useStyles();
    const [value, setValue] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleSave = () => {
        if (!fp.isEmpty(value)) {
            let orders = fp.get('attributes.orders', ticket) || '';
            if (orders.length && !fp.endsWith('\n', orders)) {
                orders += '\n';
            }
            fp.forEach(order => {
                orders += `- ${order.instruction_text}\n`;
            }, value);
            actions.patch(ticket, {orders});
        }
        onClose();
    };
    useEffect(() => {
        if (open) setValue([]);
    }, [open]);
    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='xl'
            className={classes.builder}
        >
            <DialogContent>
                <OrdersTransferList value={value} onChange={setValue} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function OrderEditorDialog({ticket, actions, open, onClose}) {
    const [orders, setOrders] = useState(ticket.attributes.orders || '');
    useEffect(() => setOrders(ticket.attributes.orders || ''), [ticket, open]);

    const handleSave = ev => {
        if (orders !== ticket.attributes.orders) {
            actions.patch(ticket, {orders});
        }
        onClose();
    };

    const readOnly = ticket.attributes.status === 'resolved';
    return (
        <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
            <DialogTitle>Orders</DialogTitle>
            <DialogContent>
                <TextField
                    disabled={readOnly}
                    multiline
                    fullWidth
                    InputLabelProps={{shrink: true}}
                    label='Manually Add/Edit Orders'
                    value={orders}
                    onChange={ev => setOrders(ev.target.value)}
                    // onBlur={handleSave}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleSave}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default function Orders({ticket, actions}) {
    const [activeDialog, setActiveDialog] = useState();
    const [menuAnchor, setMenuAnchor] = useState();
    const selectActiveDialog = dialog => ev => {
        setActiveDialog(dialog);
        setMenuAnchor(null);
    };
    return (
        <>
            <ButtonGroup variant='contained' color='primary'>
                <Button onClick={selectActiveDialog('builder')}>
                    Add Orders
                </Button>
                <Button
                    color='primary'
                    size='small'
                    onClick={ev => setMenuAnchor(ev.currentTarget)}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Menu
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor()}
            >
                <MenuItem onClick={selectActiveDialog('editor')}>
                    Manually Edit Orders
                </MenuItem>
            </Menu>
            <OrderBuilderDialog
                open={activeDialog === 'builder'}
                onClose={selectActiveDialog(null)}
                ticket={ticket}
                actions={actions}
            />
            <OrderEditorDialog
                open={activeDialog === 'editor'}
                onClose={selectActiveDialog(null)}
                ticket={ticket}
                actions={actions}
            />
        </>
    );
}
