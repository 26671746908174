import React, {useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import MaterialTable from 'material-table';

import BasePage from 'components/BasePage';
import {useApi, ducks} from '@arborian/narrf';
import {toPhoneNumber} from 'lib/util';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        padding: 20,
    },
    wideTable: {
        width: '100%',
    },
    paperForm: {
        padding: 14,
        marginBottom: 5,
    },
    saveButton: {
        marginTop: 10,
    },
    buttonPosition: {
        display: 'flex',
        justifyContent: 'end',
    },
}));

function FacilityFaxTable({facility}) {
    const api = useApi();
    const classes = useStyles();
    const href = fp.get('relationships.fax_numbers.links.related', facility);

    const fetchData = useCallback(
        o => api.fetchMaterialTable(href, o),
        [api, href],
        {data: [], page: 0, totalCount: 0},
    );

    const phoneNumberField = rowData => (
        <div
            className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl'
            style={{fontSize: 13}}
        >
            <input
                className='MuiInputBase-input MuiInput-input'
                label='Number'
                value={rowData.value}
                onChange={e => rowData.onChange(toPhoneNumber(e.target.value))}
            />
        </div>
    );

    const columns = [
        {
            title: 'Label',
            field: 'data.attributes.name',
        },
        {
            title: 'Number',
            field: 'data.attributes.number',
            editComponent: phoneNumberField,
        },
    ];

    const onRowAdd = async row => {
        row.data.attributes.facility_id = facility.id;
        await api.fetchJson(href, {
            method: 'POST',
            json: {
                data: {
                    type: 'FacilityFax',
                    attributes: row.data.attributes,
                },
            },
        });
    };

    const onRowDelete = async row => {
        console.log(row);
        await api.fetchJson(row.data.links.self, {
            method: 'DELETE',
        });
    };

    const onRowUpdate = async (row, oldRow) => {
        await api.fetchJson(row.data.links.self, {
            method: 'PATCH',
            json: {
                data: {
                    type: 'FacilityFax',
                    attributes: row.data.attributes,
                },
            },
        });
    };

    if (!facility) return null;
    return (
        <div className={classes.wideTable}>
            <MaterialTable
                title={`Fax Numbers`}
                columns={columns}
                data={fetchData}
                options={{
                    pageSize: 5,
                    search: false,
                    sorting: true,
                    paging: true,
                    actionsColumnIndex: -1,
                }}
                editable={{onRowAdd, onRowDelete, onRowUpdate}}
            />
        </div>
    );
}

export default function FacilityPage({match}) {
    const api = useApi();
    const classes = useStyles();
    const facId = match.params.facid;

    const getFac = useCallback(() => {
        let url = api.url_for('facility.resource', {facility_id: facId});

        api.fetchJsonApi(url);
    }, [api, facId]);

    useEffect(() => {
        getFac();
        api.fetchAllJsonApi(api.url_for('auth.users'));
        api.fetchAllJsonApi(api.url_for('auth.roles'));
    }, [api, getFac]);

    const facility = useSelector(
        ducks.jsonapi.selectObject(['Facility', facId]),
    );

    return (
        <BasePage>
            <div className={classes.root}>
                <h5>{fp.get('attributes.name', facility)}</h5>
                <FacilityFaxTable facility={facility} />
            </div>
        </BasePage>
    );
}
