import React from 'react';

import PCCPatientDataTable from './PCCPatientDataTable';
import {FacilityDateTime} from './FacilityDates';


export default function NutritionTable({patient, facility}) {
    if(!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='nutrition'
            columns={[
                { title: 'Type', field: 'data.attributes.oralDiet.type' },
                { title: 'Status', field: 'data.attributes.status' },
                {
                    title: 'Start Date', field: 'data.attributes.oralDiet.startDateTime', type: 'datetime',
                    render: rd => <FacilityDateTime value={rd.data.attributes.startDateTime} facility={facility}/>
                },
                {
                    title: 'Order Date', field: 'data.attributes.orderDateTime', type: 'datetime',
                    render: rd => <FacilityDateTime value={rd.data.attributes.orderDateTime} facility={facility}/>
                },
                { title: 'Ordered by', field: 'data.attributes.orderedBy' },
            ]}
        />
    )
}
