import React, {useCallback, useRef} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import moment from 'moment';

import MaterialTable from 'material-table';
import {NoteAdd} from '@material-ui/icons';

import {history} from '../routes';
import {useApi, ducks} from '@arborian/narrf';

const formid = 1;

export function PatientSelectTablePCC({facility}) {
    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const tableRef = useRef();

    const fetchPatients = useCallback(
        async o => {
            const data = await api.fetchMaterialTable(
                fp.get('relationships.patients.links.related', facility),
                o,
            );
            return data;
        },
        [api, facility],
    );

    const refresh = () => {
        tableRef.current.onQueryChange();
    };

    const createSession = useCallback(
        async (ev, rowData) => {
            const url = api.url_for('session.collection');
            let session = await api.fetchJsonApi(url, {
                method: 'POST',
                body: JSON.stringify({
                    formid: formid,
                }),
                headers: {'Content-Type': 'application/json'},
            });
            const pcc_patient = rowData.data.attributes.pcc;

            let sessionData = {
                iam: 'staff',
                pccTicket: true,
                submitType: 'normalTicket',
                submittedBy: userinfo.name,
                ccEmail: userinfo.email,
                first: pcc_patient.firstName,
                last: pcc_patient.lastName,
                dob:
                    pcc_patient.birthDate &&
                    moment.utc(pcc_patient.birthDate).format('l'),
                patientName: `${pcc_patient.firstName} ${pcc_patient.lastName}`,
                roomNumber: `${pcc_patient.floorDesc} ${pcc_patient.roomDesc}`,
                ...pcc_patient,
            };
            let updateDoc = {
                type: 'Session',
                attributes: {
                    pcc_patient_id: rowData.data.id.toString(),
                    facility_id: facility.id,
                    by_staff: true,
                    session_data: sessionData,
                },
            };
            session = await api.fetchJson(session.links.self, {
                method: 'PUT',
                body: JSON.stringify({
                    data: updateDoc,
                }),
                headers: {'Content-Type': 'application/json'},
            });
            let nextPage = `/form/${formid}/page/${session.data.attributes.next_page}?sess=${session.data.id}`;
            history.push(nextPage);
        },
        [api, userinfo, facility],
    );

    const columns = [
        {
            title: 'Last Name',
            field: 'data.attributes.pcc.lastName',
            defaultSort: 'asc',
        },
        {title: 'First Name', field: 'data.attributes.pcc.firstName'},
        {
            title: 'DOB',
            field: 'data.attributes.pcc.birthDate',
            type: 'date',
            render: rowData =>
                rowData.data.attributes.pcc.birthDate &&
                moment.utc(rowData.data.attributes.pcc.birthDate).format('l'),
            filtering: false,
        },
    ];

    return (
        <MaterialTable
            title={null}
            columns={columns}
            data={fetchPatients}
            tableRef={tableRef}
            options={{
                pageSize: 20,
                padding: 'dense',
                sorting: true,
                paging: true,
                search: false,
                // We can't enable this because if filters don't match the
                // data inside the tickets precisely (so we get a filter for owner_id where
                // we need a filter for data.owner_id, for example)
                filtering: true,
                //filtering: false,
                actionsColumnIndex: -1,
            }}
            actions={[
                {
                    icon: 'refresh',
                    tooltip: 'Refresh',
                    isFreeAction: true,
                    onClick: refresh,
                },
                {
                    icon: NoteAdd,
                    tooltip: 'Create a ticket',
                    onClick: createSession,
                },
            ]}
        />
    );
}
