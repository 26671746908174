import React, { useState } from 'react';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField as MUITextField,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Autocomplete } from 'formik-material-ui-lab';

import { useConstants } from 'lib/util';




export default function RuleMetadataEditorDialog({ dialogRef }) {
    const [resolve, setResolve] = useState(null);
    const [initialValues, setInitialValues] = useState({});
    const TICKET_TYPES = fp.pipe([
        fp.toPairs,
        fp.map(([value, label]) => ({ value, label })),
    ])(fp.get('ticketTypes', useConstants()));
    const TIMEZONES = fp.get('timeZones', useConstants())

    const handleSubmit = values => {
        values.attributes.ticket_types = fp.map(
            'value',
            values.attributes.ticket_types,
        );
        resolve({ action: 'save', values });
        setResolve(null);
    };

    const handleDelete = () => {
        resolve({ action: 'delete' });
        setResolve(null);
    };

    const handleCancel = () => {
        resolve({ action: 'cancel' });
        setResolve(null);
    };

    dialogRef.current = rule => {
        return new Promise((resolve, reject) => {
            let old_ticket_types = fp.get('attributes.ticket_types', rule);
            let new_ticket_types = fp.map(
                tt => fp.find(option => option.value === tt, TICKET_TYPES),
                old_ticket_types,
            );
            let data = fp.set(
                'attributes.ticket_types',
                new_ticket_types,
                rule,
            );

            setInitialValues(data);
            setResolve(() => resolve);
        });
    };

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {({ touched, values, errors }) => (
                    <Form>
                        <DialogTitle>Rule Editor</DialogTitle>
                        <DialogContent>
                            <Field
                                name='attributes.name'
                                component={TextField}
                                fullWidth
                                label='Rule Name'
                            />
                            <Field
                                name='attributes.ticket_types'
                                component={Autocomplete}
                                multiple
                                fullWidth
                                autoSelect
                                autoComplete
                                getOptionLabel={o => o.label}
                                options={TICKET_TYPES}
                                renderInput={params => (
                                    <MUITextField
                                        {...params}
                                        label='Ticket type(s)'
                                    />
                                )}
                            />
                            <Field
                                name='attributes.timeZone'
                                component={Autocomplete}
                                fullWidth
                                autoSelect
                                autoComplete
                                options={TIMEZONES}
                                renderInput={params => (
                                    <MUITextField
                                        {...params}
                                        label='Time zone'
                                    />
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            {initialValues.id && (
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleDelete}
                                >
                                    Delete rule
                                </Button>
                            )}
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                            >
                                Save Rule
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
