import React, {useState, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {v4 as uuidv4} from 'uuid';

import {Calendar, dateFnsLocalizer} from 'react-big-calendar';
import {
    IconButton,
    Button,
    Menu,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    makeStyles,
} from '@material-ui/core';
import {Add, Edit} from '@material-ui/icons';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as locales from 'date-fns/locale';
import {add, format, parse, startOfWeek, getDay, parseISO} from 'date-fns';

import {useApi, useDialog, ducks} from '@arborian/narrf';

import {selectRuleShifts} from 'lib/selectors';

const CANONICAL_DATE = startOfWeek(new Date(2021, 1, 14));
const scrollToTime = add(CANONICAL_DATE, {hours: 8});
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const NEW_SHIFT = {
    id: 'new',
    attributes: {name: 'New shift', color: '#4285f480', slots: []},
};
const SHIFT_COLORS = [
    '#7cb342',
    '#4285f4',
    'rgb(121, 85, 72)',
    'rgb(142, 36, 170)',
];

const useStyles = makeStyles(theme => ({
    swatch: {
        display: 'inline',
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    shiftSelect: {
        '& .MuiListItemSecondaryAction-root': {
            right: theme.spacing(1),
        },
    },
}));

function ShiftSelect({value, options, onSelect, onCreate, onDelete, onUpdate}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const shiftMetadataEditor = useDialog('shiftMetadataEditor');

    const handleSelect = async shift => {
        console.log('Select shift', shift);
        setAnchorEl(null);
        if (shift.id === 'new') {
            const color = SHIFT_COLORS[options.length % SHIFT_COLORS.length];
            const newShift = {attributes: {color, name: '', slots: []}};
            let {action, values} = await shiftMetadataEditor(newShift);
            if (action === 'save') {
                onCreate(values);
            }
        } else {
            onSelect(shift);
        }
    };

    const allOptions = useMemo(() => {
        return fp.concat(options, NEW_SHIFT);
    }, [options]);

    const handleEditMetadata = async shift => {
        setAnchorEl(null);
        let {action, values} = await shiftMetadataEditor(shift);
        if (action === 'delete') {
            onDelete(shift);
        } else if (action === 'save') {
            onUpdate(values);
        }
    };

    return (
        <>
            <Button
                variant='contained'
                color='primary'
                onClick={ev => setAnchorEl(ev.currentTarget)}
            >
                {value ? (
                    <>
                        <div
                            className={classes.swatch}
                            style={{backgroundColor: value.attributes.color}}
                        />
                        {fp.get('attributes.name', value)}
                    </>
                ) : (
                    'Select a shift'
                )}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                className={classes.shiftSelect}
            >
                {fp.map(
                    option => (
                        <ListItem
                            button
                            key={option.id}
                            onClick={() => handleSelect(option)}
                        >
                            {option.id === 'new' ? null : (
                                <div
                                    className={classes.swatch}
                                    style={{
                                        backgroundColor:
                                            option.attributes.color,
                                    }}
                                />
                            )}
                            <ListItemText>
                                {option.attributes.name}
                            </ListItemText>
                            &nbsp;
                            <ListItemSecondaryAction>
                                {option.id === 'new' ? (
                                    <Add />
                                ) : (
                                    <IconButton
                                        edge='end'
                                        onClick={() =>
                                            handleEditMetadata(option)
                                        }
                                    >
                                        <Edit />
                                    </IconButton>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ),
                    allOptions,
                )}
            </Menu>
        </>
    );
}

export default function ShiftEditor({rule}) {
    const api = useApi();
    const dispatch = useDispatch();
    const shifts = useSelector(selectRuleShifts(rule));
    const [shiftId, setShiftId] = useState(null);
    const slotDialog = useDialog('slot');
    const shift = useSelector(ducks.jsonapi.selectObject(['Shift', shiftId]));

    const events = useMemo(() => {
        let result = [];
        fp.forEach(shift => {
            fp.forEach(slot => {
                result.push({shift, ...slot});
            }, shift.attributes.slots);
        }, shifts);
        return result;
    }, [shifts]);

    const handleCreateShift = async shift => {
        console.log('Create shift', shift);
        let data = fp.pipe([
            fp.set('type', 'Shift'),
            fp.set('relationships.rule.data', {id: rule.id, type: 'Rule'}),
        ])(shift);
        const resp = await api.fetchJsonApi(api.url_for('schedule.shifts'), {
            method: 'POST',
            json: {data},
        });
        setShiftId(resp.data.id);
    };
    const handleUpdateShift = async shift => {
        console.log('Update shift', shift);
        await api.fetchJsonApi(shift.links.self, {
            method: 'PATCH',
            json: {data: shift},
        });
    };
    const handleDeleteShift = async shift => {
        console.log('Delete shift', shift);
        await api.fetchJsonApi(shift.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(shift));
    };

    const handleCreateEvent = async ({start, end, ...other}) => {
        if (start.toTimeString() === end.toTimeString()) {
            console.log('Ignore all day event', start, end, other);
            return;
        }
        if (!shift) {
            console.log('No current shift');
            return;
        }
        start = start.toJSON();
        end = end.toJSON();

        const newSlot = {
            id: uuidv4(),
            start,
            end,
        };

        const newShift = fp.set(
            'attributes.slots',
            fp.concat(shift.attributes.slots, newSlot),
            shift,
        );
        console.log('New shift', {shift, newShift, newSlot});

        const resp = await api.fetchJsonApi(newShift.links.self, {
            method: 'PATCH',
            json: {data: newShift},
        });
        console.log('Updated shift', resp);
    };

    const handleUpdateEvent = async event => {
        const {action, values} = await slotDialog(event);
        const eventShift = event.shift;

        let newSlots = eventShift.slots;
        if (action === 'delete') {
            newSlots = fp.remove(
                slot => slot.id === event.id,
                eventShift.attributes.slots,
            );
        } else if (action === 'save') {
            newSlots = fp.map(slot => {
                if (slot.id === event.id) {
                    return values;
                } else {
                    return slot;
                }
            }, eventShift.attributes.slots);
        } else {
            console.log('Got action', action);
            return;
        }

        const newShift = fp.set('attributes.slots', newSlots, eventShift);

        const resp = await api.fetchJsonApi(newShift.links.self, {
            method: 'PATCH',
            json: {data: newShift},
        });
        console.log('Updated shift', resp);
    };

    const getEventProps = ev => {
        return {style: {backgroundColor: ev.shift.attributes.color}};
    };

    return (
        <div style={{display: 'block'}}>
            <ShiftSelect
                value={shift}
                options={shifts}
                onSelect={shift => setShiftId(shift.id)}
                onCreate={handleCreateShift}
                onUpdate={handleUpdateShift}
                onDelete={handleDeleteShift}
            />
            <div disabled={!shift}>
                <Calendar
                    selectable
                    toolbar={false}
                    localizer={localizer}
                    events={events}
                    date={CANONICAL_DATE}
                    eventPropGetter={getEventProps}
                    drilldownView={null}
                    startAccessor={ev => parseISO(ev.start)}
                    endAccessor={ev => parseISO(ev.end)}
                    titleAccessor={fp.get('shift.attributes.name')}
                    scrollToTime={scrollToTime}
                    defaultView='week'
                    views={['week']}
                    style={{height: 500}}
                    onSelectSlot={handleCreateEvent}
                    onSelectEvent={handleUpdateEvent}
                    formats={{
                        dayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'EEE', culture),
                    }}
                />
            </div>
        </div>
    );
}
