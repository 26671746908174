import React from 'react';
import * as R from 'react-router-dom';
import {
    AppBar as MUIAppBar,
    IconButton,
    Toolbar,
    Link,
    makeStyles,
    Hidden,
} from '@material-ui/core';

import {url_for} from '../routes';
import AuthInfo from './AuthInfo';
import {Menu} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    title: {
        margin: 0,
    },
    logo: {
        height: 48,
        marginRight: 8,
    },
}));

export default function AppBar({onToggleDrawer, title, subtitle}) {
    const classes = useStyles();

    return (
        <MUIAppBar position='static' color='inherit'>
            <Toolbar>
                <IconButton
                    edge='start'
                    onClick={onToggleDrawer}
                    color='inherit'
                >
                    <Menu />
                </IconButton>
                <Hidden smDown>
                    <Link component={R.Link} to={url_for('home')}>
                        <img
                            className={classes.logo}
                            alt='logo'
                            src='/img/logo.png'
                        />
                    </Link>
                </Hidden>
                <h6 className={classes.title}>
                    {title} {subtitle && ` | ${subtitle}`}
                </h6>
                <div style={{flex: 1}} />
                <AuthInfo />
            </Toolbar>
        </MUIAppBar>
    );
}
