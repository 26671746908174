import React, {useState} from 'react';

import {Dialog, TextField, Button, makeStyles} from '@material-ui/core';

import {useApi} from '@arborian/narrf';

import {useStorage} from 'lib/util';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    form: {
        padding: theme.spacing(1),
    },
    buttonSpacing: {
        marginTop: 14,
    },
}));

function LoginForm({disabled, setToken}) {
    const api = useApi();
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        const url = api.url_for('pf.login');
        let resp = await api.fetchJson(url, {
            method: 'POST',
            json: {username, password},
        });
        setToken(resp.fauxSessionToken);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
                fullWidth
                disabled={disabled}
                label='PracticeFusion Username'
                value={username}
                onChange={e => setUsername(e.target.value)}
            />
            <TextField
                fullWidth
                disabled={disabled}
                label='PracticeFusion Password'
                value={password}
                type='password'
                onChange={e => setPassword(e.target.value)}
            />
            <Button
                className={classes.buttonSpacing}
                disabled={disabled}
                variant='contained'
                color='primary'
                type='submit'
            >
                Get Code
            </Button>
        </form>
    );
}

function MFAForm({disabled, fauxToken, onSubmit}) {
    const api = useApi();
    const classes = useStyles();
    const [code, setCode] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        const url = api.url_for('pf.mfa');
        let resp = await api.fetchJson(url, {
            method: 'POST',
            json: {
                fauxSessionToken: fauxToken,
                validationCode: code,
            },
        });
        onSubmit(resp.token);
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <TextField
                fullWidth
                disabled={disabled}
                label='Code'
                value={code}
                onChange={e => setCode(e.target.value)}
            />
            <Button
                className={classes.buttonSpacing}
                disabled={disabled}
                variant='contained'
                color='primary'
                type='submit'
            >
                Login
            </Button>
        </form>
    );
}

export function PracticeFusionLogin({onSubmit}) {
    const [fauxToken, setFauxToken] = useState();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LoginForm disabled={!!fauxToken} setToken={setFauxToken} />
            <MFAForm
                disabled={!fauxToken}
                fauxToken={fauxToken}
                onSubmit={onSubmit}
            />
        </div>
    );
}

export function PracticeFusionLoginDialog({dialogRef}) {
    const [open, setOpen] = useState(false);
    const [resolve, setResolve] = useState(null);
    const [token, setToken] = useStorage(
        sessionStorage,
        'practiceFusion.sessionToken',
        null,
    );
    const handleSubmit = token => {
        setToken(token);
        resolve(token);
        setOpen(false);
    };
    const handleCancel = () => {
        resolve(null);
        setOpen(false);
    };

    dialogRef.current = {
        getToken: () => {
            return new Promise((resolve, reject) => {
                if (!token) {
                    console.log('Get the token');
                    setResolve(() => resolve);
                    setOpen(true);
                } else {
                    resolve(token);
                }
            });
        },
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <PracticeFusionLogin onSubmit={handleSubmit} />
        </Dialog>
    );
}

export default PracticeFusionLogin;
