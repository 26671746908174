import React, {useCallback, useRef, useState, useMemo, useEffect} from 'react';

import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {Tooltip} from '@material-ui/core';
import MaterialTable from 'material-table';
import {Refresh} from '@material-ui/icons';

import {useApi, ducks} from '@arborian/narrf';
import {selectAvailableFacilities} from 'lib/selectors';

export default function ProviderSchedulesTable() {
    const api = useApi();
    const tableRef = useRef();
    const [init, setInit] = useState(false);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isAdmin = fp.includes('__admin__', userinfo.scopes);
    const facilities = useSelector(selectAvailableFacilities);
    const data = useMemo(() => fp.map(data => ({data}), facilities), [
        facilities,
    ]);

    const refresh = useCallback(() => {
        api.fetchAllJsonApi(api.url_for('facility.collection'), {
            sort: ['name'],
        });
    }, [api]);

    useEffect(() => {
        if (!init) {
            setInit(true);
            refresh();
        }
    }, [init, setInit, refresh]);

    const onRowUpdate = async rowData => {
        await api.fetchJsonApi(rowData.data.links.self, {
            method: 'PATCH',
            json: {data: rowData.data},
        });
    };

    const urlCell = rowData => (
        <Tooltip title={rowData.data.attributes.schedule_url} placement='right'>
            <a
                href={rowData.data.attributes.schedule_url}
                target='_blank'
                rel='noopener noreferrer'
            >
                {rowData.data.attributes.schedule_url ? 'View Schedule' : null}
            </a>
        </Tooltip>
    );

    const columns = [
        {
            title: 'Facility',
            field: 'data.attributes.name',
            defaultSort: 'asc',
            editable: false,
        },
        {
            title: 'Schedule',
            field: 'data.attributes.schedule_url',
            filtering: false,
            render: urlCell,
        },
    ];

    return (
        <MaterialTable
            title='Provider Schedules'
            columns={columns}
            data={data}
            tableRef={tableRef}
            options={{
                pageSize: isAdmin ? 20 : 5,
                search: false,
                sorting: true,
                filtering: true,
                paging: true,
                actionsColumnIndex: -1,
            }}
            editable={isAdmin ? {onRowUpdate: onRowUpdate} : null}
            actions={[
                {
                    icon: Refresh,
                    tooltip: 'Refresh data',
                    isFreeAction: true,
                    onClick: refresh,
                },
            ]}
        />
    );
}
