import React from 'react';
import fp from 'lodash/fp';

import {
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    testSet: {
        padding: theme.spacing(2)
    }
}));

function TestResult({value}) {
    return (
        <TableRow>
            <TableCell>
                {value.codeDescription} ({value.codeSystem} {value.code})
            </TableCell>
            <TableCell>
                {value.observationName}
            </TableCell>
            <TableCell>
                {fp.get('valueQuantity.value', value)} {fp.get('valueQuantity.unitText', value)}
            </TableCell>
            <TableCell>
                {value.referenceRange}
            </TableCell>
            <TableCell>
                {value.resultStatus}
            </TableCell>
        </TableRow>
    );
}


function TestSet({value}) {
    const classes = useStyles();
    return (
        <TableContainer className={classes.testSet}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Observation</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Reference</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fp.map(r => <TestResult key={r.code} value={r}/>, value.results)}
                </TableBody>
            </Table>
        </TableContainer>
    );

}

export default function LabDetail({value}) {
    return (
        <div>
            {fp.map(ts => <TestSet key={ts.code} value={ts}/>, value.attributes.testSet)}
        </div>
    );
}
