import React, {useEffect, useState} from 'react';

import BasePage from 'components/BasePage';
import TicketsTable from 'components/TicketsTable';

import {useApi} from '@arborian/narrf';

export default function TicketsPage() {
    const api = useApi();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (!ready && api.hasDirectory) {
            Promise.all([
                api.fetchAllJsonApi(api.url_for('facility.collection')),
                api.fetchAllJsonApi(api.url_for('auth.users')),
                api.fetchAllJsonApi(api.url_for('auth.roles')),
            ]).then(() => setReady(true));
        }
    }, [api, ready]);
    return (
        <BasePage wide>
            {ready ? <TicketsTable /> : <div>Loading...</div>}
        </BasePage>
    );
}
