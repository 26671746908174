import React from 'react';
import {Button} from '@material-ui/core';
import {AssignmentLate} from '@material-ui/icons';

export default function Reopen({ticket, actions}) {
    const reopenTicket = () => {
        actions.patch(ticket, {status: 'pending'});
    };
    return (
        <Button
            variant='contained'
            startIcon={<AssignmentLate />}
            onClick={() => reopenTicket()}
        >
            Reopen Ticket
        </Button>
    );
}
