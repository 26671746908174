import React from 'react';

import BasePage from 'components/BasePage';
import ProviderSchedulesTable from 'components/ProviderSchedulesTable';

export default function ProviderSchedulesPage() {
    return (
        <BasePage title='Provider Schedules'>
            <>
                <ProviderSchedulesTable />
            </>
        </BasePage>
    )
}