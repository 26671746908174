import React, {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import fp from 'lodash/fp';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';

import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

import BasePage from 'components/BasePage';
import TextField from 'components/fields/TextField';
import MultilineTextField from 'components/fields/MultilineTextField';
import Autocomplete from 'components/fields/Autocomplete';
import FreeSolo from 'components/fields/FreeSolo';
import ImageAttachment from 'components/fields/ImageAttachment';
import RadioTable from 'components/fields/RadioTable';
import RadioGroup from 'components/fields/RadioGroup';
import RadioRow from 'components/fields/RadioRow';
import CheckboxGroup from 'components/fields/CheckboxGroup';
import DateField from 'components/fields/DateField';
import {useApi, ducks} from '@arborian/narrf';

import {useQuery, url_for} from '../routes';
import ImageMap from 'components/fields/ImageMap';

const useStyles = makeStyles(theme => ({
    pre: {
        textAlign: 'left',
    },
    formContainer: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    formTitle: {
        textAlign: 'center',
    },
    formShape: {
        flex: 1,
    },
    buttonBar: {
        textAlign: 'center',
    },
    fieldSpacing: {
        padding: 10,
    },
}));

function fieldSchema(field) {
    let result = Yup.string();
    if (field.required) {
        result = result.required('required');
    }
    if (field.initialValue === null || field.nullable) {
        result = result.nullable();
    }
    return result;
}

function buildSchema(formDef) {
    const fields = fp.pipe([
        fp.map(field => [field.id, fieldSchema(field)]),
        fp.fromPairs,
    ])(formDef.fields);
    return Yup.object().shape(fields);
}

const FIELD_COMPONENTS = {
    //    checkbox: DCheckBoxField,
    autocomplete: Autocomplete,
    freesolo: FreeSolo,
    image: ImageAttachment,
    text: TextField,
    multilineText: MultilineTextField,
    date: DateField,
    radio: RadioGroup,
    radioRow: RadioRow,
    radioTable: RadioTable,
    checkbox: CheckboxGroup,
    imageMap: ImageMap,
};

function DynaField({field}) {
    const Component = FIELD_COMPONENTS[field.fieldType];
    const classes = useStyles();

    if (!Component) {
        console.error('Unknown fieldType', field.fieldType);
        return null;
    }
    return (
        <div className={classes.fieldSpacing}>
            <Component field={field} />
        </div>
    );
}

export default function DynaFormPage({match}) {
    let history = useHistory();
    let query = useQuery();
    const formid = match.params.formid;
    const pageid = match.params.pageid;
    const sessionid = query.get('sess');
    const api = useApi();
    const formPage = useSelector(
        ducks.jsonapi.selectObject(['FormPage', pageid]),
    );
    const session = useSelector(
        ducks.jsonapi.selectObject(['Session', sessionid]),
    );

    const formPageUrl = useMemo(
        () => api.url_for('form.page', {formid, pageid}),
        [api, formid, pageid],
    );
    const sessionUrl = useMemo(
        () => api.url_for('session.resource', {sessionid}),
        [api, sessionid],
    );

    /*
    const [formDef, setFormDef] = useState({});
    const [sessionDef, setSessionData] = useState({});
    */
    const classes = useStyles();

    useEffect(() => {
        if (!formPage) {
            api.fetchJson(formPageUrl);
        }
        if (!session) {
            api.fetchJson(sessionUrl);
        }
    }, [api, formPage, session, formPageUrl, sessionUrl]);

    const formDef = useMemo(() => {
        console.log('Create formDef', {formPage, session});
        if (!formPage || !session) return {};
        let initialValues = fp.pipe([
            fp.get('attributes.page.fields'),
            fp.map(f => [f.id, f.initialValue]),
            fp.fromPairs,
        ])(formPage);
        initialValues = fp.assign(
            initialValues,
            fp.get('attributes.session_data', session),
        );

        return {
            title: fp.get('attributes.page.title', formPage),
            fields: fp.get('attributes.page.fields', formPage),
            schema: buildSchema(fp.get('attributes.page', formPage)),
            initialValues,
        };
    }, [formPage, session]);

    const submitHandler = async (data, formikProps) => {
        const session_data = fp.get('attributes.session_data', session);
        data['last_viewed'] = pageid;
        const updateDoc = {
            id: session.id,
            type: session.type,
            attributes: {
                ...fp.get('attributes', session),
                completed: fp.uniq([
                    ...fp.get('attributes.completed', session),
                    pageid,
                ]),
                session_data: fp.assign(session_data, data),
            },
        };
        let resp = await api.fetchJson(session.links.self, {
            method: 'PUT',
            json: {data: updateDoc},
        });
        const next_page = fp.get('data.attributes.next_page', resp);
        const newSessionid = fp.get('data.id', resp);
        if (next_page === 'complete') {
            history.push({
                pathname: url_for('formContact', {formid}),
                search: `?sess=${newSessionid}`,
            });
        } else {
            history.push({
                pathname: url_for('form', {formid, pageid: next_page}),
                search: `?sess=${newSessionid}`,
            });
        }
    };

    return (
        <BasePage>
            <Formik
                initialValues={formDef.initialValues}
                enableReinitialize={true}
                validationSchema={formDef.schema}
                onSubmit={submitHandler}
            >
                {({errors, handleSubmit, handleReset}) => (
                    <div className={classes.formContainer}>
                        <h4 className={classes.formTitle}>{formDef.title}</h4>
                        <Form className={classes.formShape}>
                            {fp.map(
                                field => (
                                    <DynaField key={field.id} field={field} />
                                ),
                                formDef.fields,
                            )}
                            <div className={classes.buttonBar}>
                                <Button onClick={() => history.go(-1)}>
                                    Back
                                </Button>
                                <Button
                                    style={{margin: 20}}
                                    variant='contained'
                                    onClick={handleSubmit}
                                >
                                    Next
                                </Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </BasePage>
    );
}
