import React, {forwardRef, useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector, useDispatch} from 'react-redux';
import {
    Card,
    CardHeader,
    CardContent,
    Avatar,
    Chip,
    Button,
    Menu,
    MenuItem,
    ListItemIcon,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import BasePage from 'components/BasePage';
import {useApi, ducks} from '@arborian/narrf';

import {selectProviders, selectCurrentSub} from 'lib/selectors';
import {url_for} from 'routes';

function IdentityChip({identity, provider, plugin, onDelete}) {
    const image = fp.get('attributes.image_url', plugin);
    const avatar = <Avatar src={image} icon={<I.Face />} />;
    const claim =
        fp.find({key: 'name'}, identity.attributes.claims) ||
        fp.find({key: 'email'}, identity.attributes.claims);

    const handleDelete = ev => onDelete(identity);

    return (
        <Chip
            label={fp.get('value', claim)}
            onDelete={onDelete && handleDelete}
            avatar={avatar}
        />
    );
}

const ProviderMenuItem = forwardRef(({value}, ref) => {
    const api = useApi();
    const plugin_id = fp.get('attributes.plugin_id', value);
    const plugin = useSelector(
        ducks.jsonapi.selectObject(['ProviderPlugin', plugin_id]),
    );
    const image = fp.get('attributes.image_url', plugin);
    const avatar = <Avatar src={image} icon={<I.Face />} />;
    const redirect_uri = new URL('/callback', window.location.href);
    const authorizeLink = api.authorizeLink({
        redirect_uri,
        provider_id: value.id,
        intent: 'link',
        state: {next: url_for('profile')},
    });
    const handleClick = ev => {
        console.log('visit', authorizeLink);
        window.location = authorizeLink;
    };
    if (!fp.get('attributes.allow_link', plugin)) return null;
    return (
        <MenuItem ref={ref} onClick={handleClick}>
            <ListItemIcon>{avatar}</ListItemIcon>
            Link new {fp.get('attributes.name', plugin)} account
        </MenuItem>
    );
});

function NewIdentity() {
    const [anchorEl, setAnchorEl] = useState();
    const providers = useSelector(selectProviders);
    const handleClose = () => setAnchorEl(null);
    const handleClick = ev => {
        setAnchorEl(ev.target);
    };
    return (
        <>
            <Button variant='outlined' onClick={handleClick}>
                <I.Add /> Add
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
            >
                {fp.map(
                    p => (
                        <ProviderMenuItem key={p.id} value={p} />
                    ),
                    providers,
                )}
            </Menu>
        </>
    );
}

export default function ProfilePage() {
    const api = useApi();
    const [init, setInit] = useState(false);
    const sub = useSelector(selectCurrentSub);
    const dispatch = useDispatch();

    console.log('Selected sub', sub);

    useEffect(() => {
        if (!init) {
            setInit(true);
            api.fetchJsonApi(api.url_for('auth.providers'), {
                include: ['plugin'],
            });
            api.fetchJsonApi(api.url_for('auth.subs'), {
                include: ['identities'],
            });
        }
    }, [init, setInit, api]);

    const onDelete = ident => {
        api.fetch(ident.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(ident));
    };
    const canDelete = fp.get('idents', sub).length > 1;

    return (
        <BasePage title='User Profile'>
            <Card>
                <CardHeader title='Your login credentials' />
                <CardContent>
                    {fp.map(
                        idprops => (
                            <IdentityChip
                                key={idprops.identity.id}
                                onDelete={canDelete && onDelete}
                                {...idprops}
                            />
                        ),
                        fp.get('idents', sub),
                    )}
                    <NewIdentity />
                </CardContent>
            </Card>
        </BasePage>
    );
}
