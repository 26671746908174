import React, { useMemo, useCallback } from 'react';
import fp from 'lodash/fp';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
    Button, Accordion, AccordionSummary, AccordionDetails, AccordionActions,
    Typography, Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';

import TextField from 'components/fields/TextField';
import MultilineTextField from 'components/fields/MultilineTextField';
import Autocomplete from 'components/fields/Autocomplete';
import FreeSolo from 'components/fields/FreeSolo';
import ImageAttachment from 'components/fields/ImageAttachment';
import RadioTable from 'components/fields/RadioTable';
import RadioGroup from 'components/fields/RadioGroup';
import CheckboxGroup from 'components/fields/CheckboxGroup';
import ReadOnlyChip from 'components/ReadOnlyChip';
import ImageMap from 'components/fields/ImageMap';
import DateField from 'components/fields/DateField';


const useStyles = makeStyles(theme => ({
    pre: {
        textAlign: 'left',
    },
    formParent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    formShape: {
        width: '100%',
    },
    fieldSpacing: {
        padding: 10,
    },
    fieldSet: {
      borderWidth: 0
    }
}));

function fieldSchema(field) {
    let result = Yup.string();
    if(field.required) {
        result = result.required('required');
    }
    if(field.initialValue === null || field.nullable) {
        result = result.nullable();
    }
    return result;
}

function buildSchema(formDef) {
    const fields = fp.pipe([
        fp.map(field => ([field.id, fieldSchema(field)])),
        fp.fromPairs
    ])(formDef.fields);
    return Yup.object().shape(fields);
}

const FIELD_COMPONENTS = {
    //    checkbox: DCheckBoxField,
    autocomplete: Autocomplete,
    freesolo: FreeSolo,
    image: ImageAttachment,
    text: TextField,
    multilineText: MultilineTextField,
    date: DateField,
    radio: RadioGroup,
    radioTable: RadioTable,
    checkbox: CheckboxGroup,
    imageMap: ImageMap,
};

function DynaField({field}) {
    const Component = FIELD_COMPONENTS[field.fieldType];
    const classes = useStyles();

    if(!Component) {
        console.error('Unknown fieldType', field.fieldType);
        return null;
    }
    return (
        <div className={classes.fieldSpacing}>
            <Component field={field}/>
        </div>
    )
}

export default function DynaFormExpansionPanel({
    form, session, pageid, expanded, onChangeExpanded, handleTicketChange, readOnly
}) {
    const classes = useStyles();
    const page = form[pageid];
    const schema = useMemo(() => buildSchema(page), [page]);
    const initialValues = useMemo(() => {
        const defaultFieldValues = fp.pipe([
            fp.map(field => ([field.id, field.initialValue])),
            fp.fromPairs
        ])(page.fields);
        return {...defaultFieldValues, ...session};
    }, [page, session]);

    const submitHandler = useCallback((data, formikProps) => {
        handleTicketChange(data);
    }, [handleTicketChange]);
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={submitHandler}
        >
            {({ errors, handleSubmit, handleReset }) => (
                <Form className={classes.formShape}>
                    <Accordion name="editor" key={page.pageid} expanded={!!expanded} onChange={onChangeExpanded}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="editor-content"
                            id="editor-header">
                            <Typography className={classes.heading}>
                                {page.title}
                            </Typography>
                            {readOnly ? <ReadOnlyChip /> : null}
                        </AccordionSummary>
                        <AccordionDetails className={classes.formParent}>
                            <fieldset className={classes.fieldSet} disabled={readOnly}>
                                {fp.map(field => <DynaField key={field.id} field={field}/>, page.fields)}
                            </fieldset>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button size="small" color="primary" onClick={handleSubmit}>Save</Button>
                        </AccordionActions>
                    </Accordion>
                </Form>
            )}
        </Formik>
    )
}
