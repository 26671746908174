import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
import moment from 'moment';

import {
    TextField,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Checkbox,
    makeStyles,
} from '@material-ui/core';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {useSnackbar} from 'notistack';

import {useApi} from '@arborian/narrf';
import {useStorage} from 'lib/util';
import PracticeFusionLogin from 'components/PracticeFusionLogin';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    form: {
        padding: theme.spacing(1),
    },
    checkboxList: {
        width: '100%',
        display: 'flex',
    },
    buttonSpacing: {
        marginTop: 14,
    },
}));

const sectionTranslation = {
    S: 'Subjective',
    O: 'Objective',
    A: 'Assessment',
    P: 'Plan',
};

const carePlanTemplate = 'Please view Care Plan through SamePage MD Portal';

/*
const carePlanTemplate = `Care Plan Items:

-Blood pressure management - management and documentation of BP log recommended at least once a day
-Glycemic management and control - patient/caregivers to document blood glucose as indicated and screening for DM as per screening schedule
-Medication adherence - discussion of importance of medications and adherence
-Environmental review and assessment - reviewed and appropriate
-Fall Risks - patient will continue to monitor physical health and gait and recommend mitigation of falls with use of stabilization techniques and avoidance of rapid postural changes
-Advanced directives - documented and updated in patient chart
-Physical health - patient/caregivers to manage physical health diary related to physical health and improvement vs decline. Facility educated on communication tool and need to promptly relay medical concerns
-Stress management - recommended that patient use simple techniques such as breathing and exercise as well as meditation to reduce stress
-Depression management - education on depression reviewed and documentation made available via web resources
-Cognitive assessment - education on need for yearly screening and interview with pertinent family on cognitive monitoring
-Respiratory Care Plan - Deep breathing as well as monitoring of sputum and lower extremity swelling
-Hydration Status - patient educated on need for adequate oral hydration and caregivers educated on signs of dehydration which should prompt increased oral hydration to include but not limited to: dry mucous membranes, poor skin turgor, elevated body temp, concentrated or cloudy or foul smelling urine, postural dizziness, etc.

Education documents available at: https://www.tcpamd.org/resources`;
*/

function Autocomplete({
    token,
    name,
    label,
    url,
    getOptionLabel,
    values,
    setValues,
}) {
    const api = useApi();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const f = async () => {
            let resp = await api.fetchJson(url, {
                headers: {'x-pf-token': token},
            });
            setOptions(resp);
        };
        f();
    }, [setOptions, api, token, url]);

    return (
        <MUIAutocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            value={fp.get(values, name)}
            onChange={(ev, newValue) => {
                setValues({...values, [name]: newValue});
            }}
            renderInput={params => <TextField {...params} label={label} />}
        />
    );
}

function TemplateSections({values, setValues}) {
    const classes = useStyles();
    const template = fp.get('template', values);
    const sections = fp.pipe([
        fp.get('template.templateSections'),
        fp.map(fp.get('sectionFilters')),
        fp.uniq,
    ])(values);
    const handleChange = ev => {
        console.log('handleChange', ev.currentTarget);
        const newValues = fp.set(
            ['prev', ev.currentTarget.name],
            ev.currentTarget.checked,
            values,
        );
        setValues(newValues);
    };
    if (!template) return null;
    return (
        <FormControl className={classes.checkboxList} component='fieldset'>
            <FormLabel component='legend'>
                Bring forward previous note?
            </FormLabel>
            <FormGroup>
                {fp.map(
                    s => (
                        <FormControlLabel
                            key={s}
                            control={
                                <Checkbox
                                    name={s}
                                    checked={fp.getOr(
                                        false,
                                        ['prev', s],
                                        values,
                                    )}
                                    onChange={handleChange}
                                />
                            }
                            label={fp.getOr(s, s, sectionTranslation)}
                        />
                    ),
                    sections,
                )}
            </FormGroup>
        </FormControl>
    );
}

function BotForm({token}) {
    const api = useApi();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [values, setValues] = useState({
        chiefComplaint: 'CCM and Case Review',
        carePlan: carePlanTemplate,
        date: moment.utc().endOf('month'),
    });

    const setFormValue = (name, newValue) => {
        setValues({...values, [name]: newValue});
    };

    const handleSubmit = async e => {
        e.preventDefault();
        const url = api.url_for('pf.encounters');
        await api.fetch(url, {
            method: 'POST',
            headers: {'x-pf-token': token},
            json: values,
        });
        enqueueSnackbar('Bot task created.', {
            persist: false,
            variant: 'success',
        });
    };

    const setDate = date => {
        console.log('setDate(', date, ')');
        setValues({...values, date});
    };

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Autocomplete
                token={token}
                name='patientSource'
                label='Patients List'
                url={api.url_for('pf.patientSources')}
                values={values}
                setValues={setValues}
                getOptionLabel={fp.get('title')}
            />
            <br />
            <KeyboardDatePicker
                disableToolbar
                variant='inline'
                format='MM/dd/yyy'
                margin='normal'
                label='Date of Notes'
                value={fp.get('date', values)}
                onChange={setDate}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
            <TextField
                fullWidth
                label='Chief Complaint'
                value={fp.getOr('', 'chiefComplaint', values)}
                onChange={e => setFormValue('chiefComplaint', e.target.value)}
            />
            <Autocomplete
                token={token}
                name='chartNoteType'
                label='Chart Note Type'
                url={api.url_for('pf.transcript_types')}
                values={values}
                setValues={setValues}
                getOptionLabel={fp.get('transcriptTypeName')}
            />
            <Autocomplete
                token={token}
                name='encounterType'
                label='Encounter Type'
                url={api.url_for('pf.encounter_types')}
                values={values}
                setValues={setValues}
                getOptionLabel={fp.get('displayName')}
            />
            <Autocomplete
                token={token}
                name='provider'
                label='Provider'
                url={api.url_for('pf.providers')}
                values={values}
                setValues={setValues}
                getOptionLabel={fp.get('providerName')}
            />
            <Autocomplete
                token={token}
                name='template'
                label='Template'
                url={api.url_for('pf.templates')}
                values={values}
                setValues={setValues}
                getOptionLabel={fp.get('name')}
            />
            <TemplateSections values={values} setValues={setValues} />
            <TextField
                fullWidth
                multiline
                label='Care Plan'
                value={fp.getOr('', 'carePlan', values)}
                onChange={e => setFormValue('carePlan', e.target.value)}
            />

            <Button
                className={classes.buttonSpacing}
                variant='contained'
                color='primary'
                type='submit'
            >
                Create Notes
            </Button>
        </form>
    );
}

export default function PracticeFusion() {
    const [token, setToken] = useStorage(
        sessionStorage,
        'practiceFusion.sessionToken',
        null,
    );
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h3>PracticeFusion Bot</h3>
            {token ? (
                <BotForm token={token} />
            ) : (
                <PracticeFusionLogin onSubmit={setToken} />
            )}
        </div>
    );
}
