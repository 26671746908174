import fp from 'lodash/fp';
import {ducks} from '@arborian/narrf';
import {createSelector} from '@reduxjs/toolkit';

export const selectAsList = type =>
    createSelector(ducks.jsonapi.selectObject(type), fp.values);

export const selectRolesByName = createSelector(
    selectAsList('Role'),
    fp.pipe([fp.map(role => [role.attributes.name, role]), fp.fromPairs]),
);

export const selectProviders = selectAsList('Provider');
export const selectRoles = selectAsList('Role');
export const selectFacilityFaxes = selectAsList('FacilityFax');

export const selectAllFacilities = createSelector(
    selectAsList('Facility'),
    fp.sortBy('attributes.name'),
);

export const selectAllFacilitiesById = createSelector(
    selectAllFacilities,
    fp.pipe([fp.map(f => [f.id, f]), fp.fromPairs]),
);

export const selectFacilities = createSelector(
    selectAllFacilities,
    fp.filter(f => f.id !== '__all__'),
);

export const selectRuleShifts = rule =>
    createSelector(
        ducks.jsonapi.selectObject('Shift'),
        fp.pipe([
            fp.values,
            fp.filter(
                shift =>
                    rule &&
                    fp.get('relationships.rule.data.id', shift) === rule.id,
            ),
        ]),
    );

export const selectAvailableFacilities = createSelector(
    ducks.auth.selectUserinfo,
    selectFacilities,
    (ui, facs) => {
        const userScopes = fp.getOr([], 'scopes', ui);
        const userFacs = fp.getOr([], 'facilities', ui);
        if (
            fp.contains('__all__', userFacs) ||
            fp.contains('__admin__', userScopes)
        )
            return facs;
        return fp.filter(f => fp.contains(f.id, userFacs), facs);
    },
);

export const progressNoteInitialValues = (narrative, progressNoteTypes) => {
    // DEAD CODE?
    const ordersType = fp.find(
        pnt => pnt.attributes.noteType === 'Orders',
        progressNoteTypes,
    );
    const physOrdersType = fp.find(
        pnt => pnt.attributes.noteType === "Physician's Order Note",
        progressNoteTypes,
    );
    return {
        progressNoteType: physOrdersType || ordersType || null,
        'Note Text': fp.get('attributes.narrative', narrative),
    };
};

export const selectProgressNoteInitialValues = ticketid =>
    createSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketid]),
        ducks.jsonapi.selectObject('TicketNarrative'),
        ducks.jsonapi.selectObject('Facility'),
        ducks.jsonapi.selectObject('PCCFacility'),
        ducks.jsonapi.selectObject('ProgressNoteType'),
        (
            ticket,
            narratives,
            facilities,
            pcc_facilities,
            progress_note_types,
        ) => {
            const narrativeId = fp.get(
                'relationships.narrative.data.id',
                ticket,
            );
            const facId = fp.get('relationships.facility.data.id', ticket);
            const pccFacId = fp.get(
                'relationships.pcc_facility.data.id',
                ticket,
            );
            const narrative = fp.find(o => o.id === narrativeId, narratives);
            const fac = fp.find(o => o.id === facId, facilities);
            const pccFac = fp.find(o => o.id === pccFacId, pcc_facilities);

            const facProgressNoteType = fp.get(
                'attributes.pcc_progress_note_type',
                fac,
            );
            const allProgressNoteTypes = fp.filter(
                pnt =>
                    fp.get('relationships.pcc_facility.data.id', pnt) ===
                    fp.get('id', pccFac),
                progress_note_types,
            );

            const defaultType = fp.find(
                pnt => pnt.attributes.noteType === facProgressNoteType,
                allProgressNoteTypes,
            );
            const ordersType = fp.find(
                pnt => pnt.attributes.noteType === 'Orders',
                allProgressNoteTypes,
            );
            const physOrdersType = fp.find(
                pnt => pnt.attributes.noteType === "Physician's Order Note",
                allProgressNoteTypes,
            );
            const progressNoteType =
                defaultType || physOrdersType || ordersType || null;

            if (!ticket)
                return {
                    progressNoteType: null,
                    'Note Text': '',
                };
            return {
                progressNoteType,
                'Note Text': fp.get('attributes.narrative', narrative),
            };
        },
    );

export const selectProgressNotesByFac = createSelector(
    ducks.jsonapi.selectObject('ProgressNoteType'),
    ducks.jsonapi.selectObject('PCCFacility'),
    (noteTypes, pccFacs) => {
        const facMap = fp.fromPairs(
            fp.map(f => [f.attributes.pcc.facId, f.id])(pccFacs),
        );
        const grouped = fp.pipe([
            fp.map(n => [n.id, {tcpaFacId: facMap[n.attributes.facId], ...n}]),
            fp.fromPairs,
            fp.groupBy(n => n.tcpaFacId),
        ])(noteTypes);
        return grouped;
    },
);

export const debugPipe = stages => x => {
    console.log('Enter pipeline with', x);
    fp.forEach(stage => {
        x = stage(x);
        console.log('... => ', x);
    }, stages);
    return x;
};

export const selectUsersWithScope = scope =>
    createSelector(selectAsList('User'), selectRolesByName, (users, roles) => {
        return fp.filter(u => {
            let userScopes = fp.pipe([
                fp.get('attributes.roles'),
                fp.map(rname => {
                    return roles[rname];
                }),
                fp.filter(r => r),
                fp.map(role => role.attributes.scopes),
                fp.flatten,
            ])(u);
            return fp.indexOf(scope, userScopes) !== -1;
        }, users);
    });

export const selectTicketOwners = selectUsersWithScope('owner');
export const selectHealthcareProviders = selectUsersWithScope('provider');

export const selectAvailableFacilitiesLookup = createSelector(
    selectAvailableFacilities,
    fp.pipe([fp.values, fp.map(o => [o.id, o.attributes.name]), fp.fromPairs]),
);

export const selectTicketOwnersLookup = createSelector(
    selectTicketOwners,
    fp.pipe([
        fp.values,
        fp.map(o => [
            o.id,
            o.attributes.userinfo.name || o.attributes.userinfo.email,
        ]),
        fp.fromPairs,
    ]),
);

export const selectHealthcareProvidersLookup = createSelector(
    selectHealthcareProviders,
    fp.pipe([
        fp.values,
        fp.map(o => [
            o.id,
            o.attributes.userinfo.name || o.attributes.userinfo.email,
        ]),
        fp.fromPairs,
    ]),
);

export const selectCurrentSub = createSelector(
    ducks.auth.selectUserinfo,
    ducks.jsonapi.selectObject('Sub'),
    selectAsList('Identity'),
    ducks.jsonapi.selectObject('Provider'),
    ducks.jsonapi.selectObject('ProviderPlugin'),
    (ui, subs, identities, providers, plugins) => {
        const sub = fp.get(ui.sub, subs);
        const idents = fp.pipe([
            fp.filter(ident => fp.includes(sub.id, ident.attributes.sub_ids)),
            fp.map(identity => {
                const provider = fp.get(
                    identity.attributes.provider_id,
                    providers,
                );
                const plugin = fp.get(identity.attributes.plugin_id, plugins);
                return {identity, provider, plugin};
            }),
        ])(identities);
        return {sub, idents};
    },
);

export const selectTicketComments = ticket_id =>
    createSelector(ducks.jsonapi.selectObject('TicketComment'), all_comments =>
        fp.filter(c => c.attributes.ticket_id === ticket_id, all_comments),
    );
