import React, {useState, useEffect, useRef, useCallback} from 'react';
import fp from 'lodash/fp';
import {useDispatch, useSelector} from 'react-redux';
import MaterialTable from 'material-table';

import {makeStyles} from '@material-ui/core';

import {ducks, useApi} from '@arborian/narrf';
import {selectRolesByName, selectAllFacilitiesById} from 'lib/selectors';
import ChipList from 'components/ChipList';

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
    },
}));

const sortByLastName = (n1, n2, ...props) => {
    let name1 = fp.words(n1.data.attributes.name).reverse().join();
    let name2 = fp.words(n2.data.attributes.name).reverse().join();
    return name1.localeCompare(name2);
};

function FacilitiesList({value, onChange}) {
    const facilities = useSelector(selectAllFacilitiesById);
    const valueOptions = fp.map(item => fp.get(item, facilities), value);
    const handleChange = newValue => {
        const facIds = fp.pipe([fp.map(fp.get('id')), fp.filter(fp.identity)])(
            newValue,
        );
        onChange(facIds);
    };
    return (
        <ChipList
            value={valueOptions}
            onChange={onChange && handleChange}
            options={facilities}
            addLabel='Add facility'
            getOptionLabel={fp.get('attributes.name')}
            getOptionSelected={(o, value) => {
                const name = fp.get('id', o);
                return !fp.includes(name, value);
            }}
        />
    );
}

function RoleList({value, onChange}) {
    const roles = useSelector(selectRolesByName);
    const valueOptions = fp.map(item => fp.get(item, roles), value);
    const handleChange = newValue => {
        const roleNames = fp.pipe([
            fp.map(fp.get('attributes.name')),
            fp.filter(fp.identity),
        ])(newValue);
        onChange(roleNames);
    };

    return (
        <ChipList
            value={valueOptions}
            onChange={onChange && handleChange}
            options={roles}
            addLabel='Add role'
            getOptionLabel={fp.get('attributes.description')}
            getOptionSelected={(o, value) => {
                const name = fp.get('attributes.name', o);
                return !fp.includes(name, value);
            }}
        />
    );
}

const columns = [
    {
        title: 'Email',
        field: 'data.attributes.userinfo.email',
        defaultSort: 'asc',
    },
    {
        title: 'Name',
        field: 'data.attributes.userinfo.name',
        customSort: sortByLastName,
    },
    {
        title: 'Signup Facility',
        field: 'data.attributes.userinfo.custom:Facility',
        editable: false,
    },
    {
        title: 'Roles',
        field: 'data.attributes.roles',
        render: rowData => (
            <RoleList value={fp.get('data.attributes.roles', rowData)} />
        ),
        editComponent: RoleList,
        filtering: false,
    },
    {
        title: 'Facilities',
        field: 'data.attributes.userinfo.facilities',
        render: rowData => (
            <FacilitiesList
                value={fp.get('data.attributes.userinfo.facilities', rowData)}
            />
        ),
        editComponent: FacilitiesList,
        filtering: false,
    },
];

export default function UserTable() {
    const api = useApi();
    const classes = useStyles();
    const [init, setInit] = useState(false);
    const dispatch = useDispatch();
    const tableRef = useRef();
    useEffect(() => {
        if (!init) {
            setInit(true);
            api.fetchAllJsonApi(api.url_for('facility.collection'));
            api.fetchAllJsonApi(api.url_for('auth.roles'));
        }
    }, [api, init, setInit]);

    const fetchData = useCallback(
        async o => {
            const data = await api.fetchMaterialTable(
                api.url_for('auth.users'),
                o,
            );
            return data;
        },
        [api],
        {data: [], page: 0, totalCount: 0},
    );

    const onRowDelete = async rowData => {
        api.fetch(rowData.data.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(rowData.data));
        tableRef.current.onQueryChange();
    };

    const onRowUpdate = async (rowData, oldRowData) => {
        const {data} = rowData;
        await api.fetchJson(data.links.self, {
            method: 'PATCH',
            json: {data},
        });
    };

    return (
        <MaterialTable
            title='User Permissions'
            className={classes.table}
            columns={columns}
            data={fetchData}
            tableRef={tableRef}
            options={{
                pageSize: 15,
                search: false,
                sorting: true,
                paging: true,
                filtering: true,
                actionsColumnIndex: -1,
            }}
            editable={{onRowUpdate, onRowDelete}}
        />
    );
}
