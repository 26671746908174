import React from 'react';
import {Link} from 'react-router-dom';

import {
    makeStyles,
    Button,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Security, ExpandMore, OpenInNew} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

import BasePage from 'components/BasePage';
import {url_for} from '../routes';

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
        padding: 16,
        [theme.breakpoints.down('xs')]: {
            // width: 400,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 30,
        marginBottom: 40,
    },
    logo: {
        width: '100%',
        height: '100%',
    },
    createButton: {
        marginBottom: 140,
        padding: '16px 40px',
        fontSize: 16,
        flexGrow: 0,
    },
    staffButton: {
        margin: 10,
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const RedirectButton = () => {
    const classes = useStyles();
    return (
        <div className={classes.columnContainer}>
            <Alert severity='info'>
                <AlertTitle>
                    SamePageMD is the new version of the TCPA communication
                    tool.
                </AlertTitle>
                {/* <br /> */}
                Please register & sign-in with SamePageMD to create a
                communication.
            </Alert>
            <Button
                className={classes.createButton}
                size='large'
                color='primary'
                variant='contained'
                href='https://app.samepagemd.com/tcpaLanding'
                startIcon={<OpenInNew />}
            >
                Start with SamePageMD
            </Button>
        </div>
    );
};

function AuthBody() {
    const classes = useStyles();
    return (
        <div className={classes.body}>
            <RedirectButton />
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <small>I still need to use the old TCPA app</small>
                </AccordionSummary>
                <AccordionDetails className={classes.columnContainer}>
                    <Button
                        component={Link}
                        to={{
                            pathname: url_for('login'),
                            state: {next: url_for('communicateStaff')},
                        }}
                        startIcon={<Security />}
                        color='default'
                        variant='contained'
                    >
                        Start
                    </Button>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

function AnonBody() {
    const classes = useStyles();
    return (
        <div className={classes.body}>
            <RedirectButton />
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <small>I still need to use the old TCPA app</small>
                </AccordionSummary>
                <AccordionDetails className={classes.columnContainer}>
                    <Button
                        component={Link}
                        to={url_for('communicate')}
                        color='default'
                        variant='contained'
                    >
                        Start
                    </Button>
                    <Button
                        component={Link}
                        to={{
                            pathname: url_for('login'),
                            state: {next: url_for('communicateStaff')},
                        }}
                        color='default'
                        variant='outlined'
                    >
                        Staff
                    </Button>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export default function HomePage() {
    const api = useApi();
    const classes = useStyles();

    return (
        <BasePage>
            <header className={classes.header}>
                <Paper className={classes.logoContainer}>
                    <img
                        className={classes.logo}
                        src='/img/tcpa.png'
                        alt='TCPA logo'
                    />
                </Paper>
                <h3>Communicate with Us</h3>
            </header>
            {/* No more TCPA tickets - force SamePageMD */}
            {/* {api.isAuthorized() ? <AuthBody /> : <AnonBody />} */}
            <div className={classes.body}>
                <RedirectButton />
            </div>
        </BasePage>
    );
}
