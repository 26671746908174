import React, {useState} from 'react';
import fp from 'lodash/fp';
import {FastField} from 'formik';
import {KeyboardDatePicker} from '@material-ui/pickers';


function DateField({
    field, form,  // From Formik
    label, caption, required,
}) {
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const [error, setError] = useState(null);

    const handleChange = (date, value) => {
        form.setFieldValue(field.name, value || "", true);
        if (!date || isNaN(date)) {
            form.setFieldError(field.name, error)
        } else {
            setError(null);
            form.setFieldError(field.name, null);
        }
    }

    return(
        <KeyboardDatePicker
            disableToolbar
            fullWidth
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Patient Birthdate (MM/DD/YYYY)"
            value={field.value || null}
            inputValue={field.value || ""}
            onError={setError}
            onChange={handleChange}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            error={(error || touched) && fp.get(field.name, form.errors)}
            helperText={(error || touched) && fp.get(field.name, form.errors)}
        />
    )
}

export default ({field}) => {
    return <FastField component={DateField} name={field.id}
        label={field.label}
        caption={field.caption}
        required={field.required}
    />
}
