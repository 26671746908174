import React from 'react';
import fp from 'lodash/fp';

import {parseISO} from 'date-fns';
import {format} from 'date-fns-tz';

export function FacilityDate({value, facility}) {
    if(!value) return null;
    const date = parseISO(value);
    const timeZone = fp.get('attributes.pcc.timeZone', facility);
    const formatted = format(date, 'yyyy-MM-dd', {timeZone});
    return <>{formatted}</>
}

export function FacilityDateTime({value, facility}) {
    if(!value) return null;
    const date = parseISO(value);
    const timeZone = fp.get('attributes.pcc.timeZone', facility);
    const formatted = format(date, 'yyyy-MM-dd HH:mm (zzz)', {timeZone});
    return <>{formatted}</>
}
