import React, {useCallback, useMemo, useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core';
import {Refresh, Link} from '@material-ui/icons';
import MaterialTable from 'material-table';

import BasePage from 'components/BasePage';
import {useApi, ducks} from '@arborian/narrf';

import {PatientSelectTable} from 'components/PatientSelectTable';
import {PatientSelectTablePCC} from 'components/PatientSelectTablePCC';

import {selectAvailableFacilities} from 'lib/selectors';

const useStyles = makeStyles(theme => ({}));

export default function CommunicateStaffPage() {
    const api = useApi();
    const [init, setInit] = useState(false);
    const classes = useStyles();
    const facilities = useSelector(selectAvailableFacilities);
    const pccFacilities = useSelector(
        ducks.jsonapi.selectObject('PCCFacility'),
    );
    const data = useMemo(() => fp.map(data => ({data}), facilities), [
        facilities,
    ]);

    const refresh = useCallback(() => {
        api.fetchAllJsonApi(api.url_for('facility.collection'), {
            sort: ['name'],
        });
        api.fetchAllJsonApi(api.url_for('pcc-facility.collection'));
    }, [api]);

    useEffect(() => {
        if (!init) {
            setInit(true);
            refresh();
        }
    }, [init, setInit, refresh]);

    const columns = [
        {title: 'Facility', field: 'data.attributes.name', defaultSort: 'asc'},
        {
            title: 'PointClickCare',
            filtering: false,
            render: rowData => {
                if (fp.get(rowData.data.id, pccFacilities)) {
                    return (
                        <span>
                            <Link />
                        </span>
                    );
                }
            },
        },
    ];

    const detailPanel = rowData => {
        const pccFac = fp.get(rowData.data.id, pccFacilities);
        if (pccFac) {
            return <PatientSelectTablePCC facility={pccFac} />;
        } else {
            return <PatientSelectTable facility={rowData.data} />;
        }
    };

    return (
        <BasePage>
            <header className={classes.header}>
                <div className={classes.wideTable}>
                    <div className={classes.muiTable}>
                        <MaterialTable
                            title='Select a Facility'
                            columns={columns}
                            data={data}
                            options={{
                                pageSize: 10,
                                search: false,
                                sorting: true,
                                paging: true,
                                filtering: true,
                                actionsColumnIndex: -1,
                            }}
                            actions={[
                                {
                                    icon: Refresh,
                                    tooltip: 'Refresh data',
                                    isFreeAction: true,
                                    onClick: refresh,
                                },
                            ]}
                            detailPanel={detailPanel}
                            onRowClick={(event, rowData, togglePanel) =>
                                togglePanel()
                            }
                        />
                    </div>
                </div>
            </header>
        </BasePage>
    );
}
