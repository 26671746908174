import React, {useRef} from 'react';
import {useField} from 'formik';
import fp from 'lodash/fp';

import {
    IconButton,
    FormControl,
    FormLabel,
    FormHelperText,
    Card,
    CardMedia,
    CardHeader,
    CardActionArea,
    CardContent,
    TextField,
    Button,
    Grid,
    makeStyles,
} from '@material-ui/core';
import {Attachment, Delete} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    centered: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        maxWidth: 440,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
    hidden: {
        display: 'none',
    },
}));

function ImageAttachment({item, onChange, onDelete}) {
    const content_href = fp.get('data.links.content', item);
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton onClick={onDelete}>
                        <Delete />
                    </IconButton>
                }
                title={item.data.attributes.filename}
            />
            <CardActionArea href={content_href} target='_blank'>
                <CardMedia
                    image={content_href}
                    component='img'
                    title={item.data.attributes.filename}
                    alt={item.data.attributes.filename}
                />
            </CardActionArea>
            <CardContent>
                <TextField
                    value={item.comment}
                    fullWidth
                    label='Attachment description'
                    onChange={ev => onChange(item, ev.target.value)}
                />
            </CardContent>
        </Card>
    );
}

export function ImageList({value, onChange, ...props}) {
    const api = useApi();
    const inputRef = useRef();
    const classes = useStyles();

    const handleAdd = async ev => {
        let file = ev.currentTarget.files[0];
        let body = new FormData();
        body.append('file', file);
        let resp = await api.fetch(api.url_for('blob.upload'), {
            method: 'POST',
            body,
        });
        let blob = await resp.json();
        onChange([...value, {...blob, comment: ''}]);
        inputRef.current.value = null;
    };

    const handleCommentChange = (item, comment) => {
        console.log({item, comment});
        onChange(
            fp.map(it => {
                if (it.data.id === item.data.id) {
                    return fp.set('comment', comment, it);
                } else {
                    return it;
                }
            }, value),
        );
    };

    const handleDelete = obj => {
        api.fetch(obj.data.links['self'], {method: 'DELETE'});
        onChange(fp.remove(v => v.data.id === obj.data.id, value));
    };

    const handleClick = ev => {
        inputRef.current.click();
    };

    return (
        <div>
            <div className={classes.attachmentList}>
                {fp.map(item => {
                    return (
                        <ImageAttachment
                            key={item.data.id}
                            item={item}
                            onChange={handleCommentChange}
                            onDelete={() => handleDelete(item)}
                        />
                    );
                }, value)}
            </div>
            <Grid container justify='center' alignItems='center'>
                <Grid>
                    <Button onClick={handleClick} variant='outlined'>
                        <Attachment /> Add
                    </Button>
                    <input
                        type='file'
                        ref={inputRef}
                        onChange={handleAdd}
                        className={classes.hidden}
                        accept='image/png, image/jpeg'
                        {...props}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export function ImageAttachmentField({label, ...props}) {
    const classes = useStyles();
    const [field, , helpers] = useField(props);

    const handleChange = newValue => {
        helpers.setValue(newValue);
    };

    return (
        <FormControl className={classes.centered}>
            <FormLabel>{label}</FormLabel>
            <ImageList {...props} {...field} onChange={handleChange} />
        </FormControl>
    );
}

export default ({field}) => {
    return (
        <>
            <ImageAttachmentField
                name={field.id}
                label={field.label}
                required={field.required}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
};
