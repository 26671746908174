import React from 'react';

import {components} from '@arborian/narrf';

import TicketFaxDialog from 'components/dialogs/TicketFaxDialog';
import PracticeFusionCredentialsDialog from 'components/dialogs/PracticeFusionCredentialsDialog';
import PracticeFusionValidationCodeDialog from 'components/dialogs/PracticeFusionValidationCodeDialog';
import PracticeFusionLoginDialog from 'components/dialogs/PracticeFusionLoginDialog';
import PCCProgressNoteDialog from 'components/dialogs/PCCProgressNoteDialog';
import TicketResolveDialog from 'components/dialogs/TicketResolveDialog';
import ShiftDialog from 'components/dialogs/ShiftDialog';
import SlotDialog from 'components/dialogs/SlotDialog';
import RuleMetadataEditorDialog from 'components/dialogs/RuleMetadataEditorDialog';
import ShiftMetadataEditorDialog from 'components/dialogs/ShiftMetadataEditorDialog';
import ShiftAssignmentDialog from 'components/dialogs/ShiftAssignmentDialog';
import PasswordRegistrationDialog from 'components/dialogs/PasswordRegistrationDialog';

export const useDialog = components.useDialog;

export default function DialogProvider({children}) {
    return (
        <components.DialogProvider
            dialogs={{
                faxTicket: TicketFaxDialog,
                pfLogin: PracticeFusionLoginDialog,
                pfCredentials: PracticeFusionCredentialsDialog,
                pfValidationCode: PracticeFusionValidationCodeDialog,
                shift: ShiftDialog,
                pccProgressNote: PCCProgressNoteDialog,
                ticketResolve: TicketResolveDialog,
                shiftMetadataEditor: ShiftMetadataEditorDialog,
                ruleMetadataEditor: RuleMetadataEditorDialog,
                slot: SlotDialog,
                shiftAssignment: ShiftAssignmentDialog,
                passwordRegistration: PasswordRegistrationDialog,
            }}
        >
            {children}
        </components.DialogProvider>
    );
}
