import React, {useEffect, useState, useCallback} from 'react';

import fp from 'lodash/fp';

import {Button, makeStyles} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import BasePage from 'components/BasePage';

import {useApi, useDialog} from '@arborian/narrf';

import PccLoginButton from 'components/pcc/LoginButton';
import BaseLoginButton from 'components/BaseLoginButton';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
    },
    loginButton: {
        width: 244,
        height: 40,
        marginTop: 6,
        marginBottom: 6,
        marginRight: 4,
    },
}));

export default function RegisterPage() {
    const api = useApi();
    const classes = useStyles();
    const [providers, setProviders] = useState();
    const passwordRegistrationDialog = useDialog('passwordRegistration');
    const [showForm, setShowForm] = useState(false);

    const fetchProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            console.log('Got error, trying direct redirect');
            window.location = authorizeLink;
        }
    }, [api, setProviders]);

    const handleRegistration = async () => {
        let resp = await passwordRegistrationDialog();
        if (!resp) return;
        setShowForm(true);
    };

    useEffect(() => {
        if (!providers) {
            fetchProviders();
        }
    }, [providers, fetchProviders]);

    const pcc = fp.find(p => p.attributes.plugin_id === 'pcc', providers);
    const cognito = fp.find(
        p => p.attributes.plugin_id === 'aws-cognito',
        providers,
    );
    const google = fp.find(p => p.attributes.plugin_id === 'google', providers);
    console.log('Register', {pcc, cognito, google});

    return (
        <BasePage title='Register'>
            <div className={classes.root}>
                <div>
                    {fp.get('attributes.allow_signup', pcc) && (
                        <PccLoginButton provider={pcc} intent='register' />
                    )}
                    {fp.get('attributes.allow_signup', google) && (
                        <BaseLoginButton
                            provider={google}
                            intent='register'
                            image='/img/tcpa-square.svg'
                        >
                            TCPA Staff Sign Up
                        </BaseLoginButton>
                    )}
                    {/*fp.get('attributes.allow_signup', cognito) && (
                        <BaseLoginButton provider={cognito} intent='register'>
                            Sign up with Password
                        </BaseLoginButton>
                    )*/}
                    <Button classes={{root: classes.loginButton}} variant='outlined' onClick={handleRegistration}>
                        Password Sign up
                    </Button>
                </div>
                {showForm && (
                    <div>
                        <br />
                        <br />
                        <MuiAlert elevation={6} variant="filled" severity="info">
                            Sign-up successful. Please check your email for further instructions.
                        </MuiAlert>
                    </div>
                )}
            </div>
        </BasePage>
    );
}
