import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';

import {makeStyles, Button} from '@material-ui/core';

import BasePage from 'components/BasePage';
import {history} from '../routes';
import {useApi} from '@arborian/narrf';
import {Formik, Form} from 'formik';
import {FaxOptionsField} from 'components/fields/FaxOptions';

const useStyles = makeStyles(theme => ({
    pageParent: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        textAlign: 'center',
        width: '50%',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function TicketContactPage() {
    const api = useApi();
    const classes = useStyles();
    let query = useQuery();
    const sessionid = query.get('sess');
    const formid = 1;

    const [session, setSession] = useState({});
    const [faxOptions, setFaxOptions] = useState([]);
    const [defaultVals, setDefaultVals] = useState({
        fax_number: [],
    });

    useEffect(() => {
        const asyncFunc = async () => {
            const url = api.url_for('session.resource', {sessionid});
            const resp = await api.fetchJson(url);
            setSession(resp.data);

            const facUrl = api.url_for('facility.resource', {
                facility_id: resp.data.attributes.facility_id,
            });
            let facResp = await api.fetchJsonApi(facUrl, {
                include: ['fax_numbers'],
            });
            // Freesolo only allows string options; only displays number
            // setFaxOptions(fp.map('attributes.number', faxResp.data));
            setFaxOptions(
                fp.map(
                    f => `${f.attributes.name}:${f.attributes.number}`,
                    facResp.included,
                ),
            );
            const sessionFaxNumber = fp.get('data.attributes.fax_number')(resp);
            setDefaultVals({fax_number: sessionFaxNumber});
        };
        asyncFunc();
    }, [api, sessionid]);

    const handleSubmit = async (data, actions) => {
        let updateDoc = {fax_number: data.fax_number};
        const resp = await api.fetchJsonApi(session.links.self, {
            method: 'PUT',
            body: JSON.stringify({
                data: {
                    type: 'Session',
                    attributes: updateDoc,
                },
            }),
            headers: {'Content-Type': 'application/json'},
        });
        let nextPageid = resp.data.attributes.next_page;
        let nextPage = '';
        if (nextPageid === 'complete') {
            nextPage = `/form/${formid}/submit?sess=${sessionid}`;
        } else {
            nextPage = `/form/${formid}/page/${nextPageid}?sess=${sessionid}`;
        }
        history.push(nextPage);
    };

    return (
        <BasePage>
            <div className={classes.pageParent}>
                <h2>Fax</h2>
                <p>Please select/provide a fax number</p>
                <Formik
                    initialValues={defaultVals}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <Form>
                            <FaxOptionsField
                                name='fax_number'
                                label='Fax Number'
                                options={faxOptions || []}
                                multiple={true}
                                required={true}
                            />
                            <div className={classes.buttons}>
                                <Button onClick={() => history.go(-1)}>
                                    Back
                                </Button>
                                <Button
                                    type='submit'
                                    style={{margin: 20}}
                                    variant='contained'
                                >
                                    Next
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </BasePage>
    );
}
