import React from 'react';
import * as R from 'react-router-dom';

import routes, {history} from '../routes';
import {ApiRoute} from '@arborian/narrf';

import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import LogoutRedirectPage from 'pages/LogoutRedirectPage';
import ProfilePage from 'pages/ProfilePage';
import RegisterPage from 'pages/RegisterPage';
import RegistrationRedirectPage from 'pages/RegistrationRedirectPage';

import AboutPage from 'pages/AboutPage';
import TOSPrivacyPage from 'pages/TOSPrivacyPage';
import CommunicatePage from 'pages/CommunicatePage';
import CommunicateStaffPage from 'pages/CommunicateStaffPage';
import CallbackPage from 'pages/CallbackPage';
import DynaFormPage from 'pages/DynaFormPage';
import SubmitPage from 'pages/SubmitPage';
import TicketContactPage from 'pages/TicketContactPage';
import SuccessPage from 'pages/SuccessPage';
import TicketsPage from 'pages/TicketsPage';
import TicketPage from 'pages/TicketPage';
import FacilitiesPage from 'pages/FacilitiesPage';
import FacilityPage from 'pages/FacilityPage';
import OrdersPage from 'pages/OrdersPage';
import PermissionsPage from 'pages/PermissionsPage';
import FaxDirectoryPage from 'pages/FaxDirectoryPage';
import PCCPatientDataPage from 'pages/PCCPatientDataPage';
import SchedulePage from 'pages/FacSchedule';
import ProviderSchedulesPage from 'pages/ProviderSchedulesPage';
import EventReportPage from 'pages/EventReportPage';

import PracticeFusionPage from 'pages/PracticeFusionPage';
import MFAPage from 'pages/MFAPage';

import TestPage from 'pages/TestPage';

const StaticRoute = path => <R.Route path={path} />;

export default function Router() {
    return (
        <R.Router history={history}>
            <R.Switch>
                <ApiRoute exact path={routes.mfa.path} component={MFAPage} />
                <ApiRoute exact path='/test' component={TestPage} />
                <ApiRoute exact path={routes.home.path} component={HomePage} />
                <ApiRoute
                    exact
                    path={routes.login.path}
                    component={LoginPage}
                />
                <ApiRoute
                    exact
                    path={routes.logout.path}
                    component={LogoutPage}
                />
                <ApiRoute
                    exact
                    path={routes.logoutRedirect.path}
                    component={LogoutRedirectPage}
                />
                <ApiRoute
                    exact
                    path={routes.register.path}
                    component={RegisterPage}
                />
                <ApiRoute
                    exact
                    path={routes.registrationRedirect.path}
                    component={RegistrationRedirectPage}
                />
                <ApiRoute
                    exact
                    path={routes.about.path}
                    component={AboutPage}
                />
                <StaticRoute path='/about.html' />
                <ApiRoute
                    exact
                    path={routes.tos.path}
                    component={TOSPrivacyPage}
                />
                <StaticRoute path='/tos.html' />
                <ApiRoute
                    authorized
                    exact
                    path={routes.profile.path}
                    component={ProfilePage}
                />

                <ApiRoute
                    exact
                    path={routes.communicate.path}
                    component={CommunicatePage}
                />
                <ApiRoute
                    exact
                    path={routes.callback.path}
                    component={CallbackPage}
                />
                <ApiRoute
                    exact
                    path={routes.form.path}
                    component={DynaFormPage}
                />
                <ApiRoute
                    exact
                    path={routes.formPCCData.path}
                    component={PCCPatientDataPage}
                />
                <ApiRoute
                    exact
                    path={routes.formSubmit.path}
                    component={SubmitPage}
                />
                <ApiRoute
                    exact
                    path={routes.formContact.path}
                    component={TicketContactPage}
                />
                <ApiRoute
                    exact
                    path={routes.formSuccess.path}
                    component={SuccessPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.communicateStaff.path}
                    component={CommunicateStaffPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.tickets.path}
                    component={TicketsPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.ticketDetails.path}
                    component={TicketPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminFacilities.path}
                    component={FacilitiesPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminFacility.path}
                    component={FacilityPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminPermissions.path}
                    component={PermissionsPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminFaxDirectory.path}
                    component={FaxDirectoryPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminSchedule.path}
                    component={SchedulePage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.providerSchedules.path}
                    component={ProviderSchedulesPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.orders.path}
                    component={OrdersPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.practiceFusion.path}
                    component={PracticeFusionPage}
                />
                <ApiRoute
                    authorized
                    exact
                    path={routes.adminTools.path}
                    component={EventReportPage}
                />
            </R.Switch>
        </R.Router>
    );
}
