import React, {useEffect, useState} from 'react';
import fp from 'lodash/fp';

import {useApi} from '@arborian/narrf';
import {url_for, useQuery, history} from 'routes';
import BasePage from 'components/BasePage';

export default function CallbackPage() {
    const [init, setInit] = useState();
    const query = useQuery();
    const api = useApi();
    const [content, setContent] = useState(null);

    useEffect(() => {
        async function main() {
            const code = query.get('code');
            const intent = query.get('intent');
            const error = query.get('error');
            const state = query.get('state') || '{}';
            let next = url_for('tickets');
            try {
                next = fp.getOr('/', 'next', JSON.parse(state));
                if (next === '/callback') {
                    next = url_for('tickets');
                }
            } catch (e) {
                console.error('Error interpreting state param', state, e);
            }
            if (error) {
                setContent(`Auth error: ${error}`);
                return;
            }

            if (intent === 'link') {
                let refreshed = await api.tryRefresh();
                if (refreshed) {
                    await api.codeLink(code);
                    history.push(next);
                } else {
                    console.error('Failed to refresh');
                    history.push(url_for('home'));
                }
            } else {
                let tok = await api.codeLogin(code);
                if (intent === 'register') {
                    api.fetchJson(
                        api.url_for('auth.registration_notification'),
                        {
                            method: 'POST',
                            json: tok,
                        },
                    );
                }
                console.log('Got token response', tok);
                history.push(next);
            }
        }
        if (!init) {
            setInit(true);
            main();
        }
    }, [query, api, init, setContent]);
    return <BasePage title='Logging you in...'>{content}</BasePage>;
}
