import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import MaterialTable from 'material-table';

import {useApi, useDialog, ducks} from '@arborian/narrf';

import {
    selectAsList,
    selectTicketOwners,
    selectHealthcareProviders,
    selectRuleShifts,
} from 'lib/selectors';

const useStyles = makeStyles(theme => ({
    tableTitle: {
        '& h6': {
            margin: 0,
        },
    },
    thin: {
        fontWeight: 100,
    },
}));

const FacilitySelector = createSelector(
    selectAsList('Facility'),
    fp.pipe([fp.filter(f => f.id !== '__all__'), fp.map(data => ({data}))]),
);

function AssignmentsTable({rule, onChangeAssignments}) {
    const api = useApi();
    const classes = useStyles();
    const shiftDialog = useDialog('shift');
    const assignmentDialog = useDialog('shiftAssignment');

    const facilities = useSelector(FacilitySelector);
    const owners = useSelector(selectTicketOwners);
    const providers = useSelector(selectHealthcareProviders);

    const shifts = useSelector(selectRuleShifts(rule));

    const editShifts = useCallback(async () => {
        await shiftDialog(rule);
        await api.fetchJsonApi(rule.links.self, {include: ['shifts']});
    }, [shiftDialog, rule, api]);

    const assignments = useSelector(ducks.jsonapi.selectObject('Assignment'));
    const assignmentIndex = useMemo(() => {
        let result = {};
        fp.forEach(asg => {
            const key = [
                asg.relationships.facility.data.id,
                asg.relationships.shift.data.id,
            ];
            result = fp.set(key, asg, result);
        }, assignments);
        return result;
    }, [assignments]);

    const formatAssignments = useCallback(
        (rowData, shift) => {
            var owner, provider;
            if (shift) {
                const asg = fp.get(
                    [rowData.data.id, shift.id],
                    assignmentIndex,
                );
                const oid = fp.get('attributes.owner_id', asg);
                const pid = fp.get('attributes.provider_id', asg);

                owner = fp.find(o => o.id === oid, owners);
                provider = fp.find(p => p.id === pid, providers);
            } else {
                const oid = fp.get('data.attributes.default_owner_id', rowData);
                const pid = fp.get(
                    'data.attributes.default_provider_id',
                    rowData,
                );

                owner = fp.find(o => o.id === oid, owners);
                provider = fp.find(p => p.id === pid, providers);
            }
            return (
                <>
                    <span className={classes.thin}>Owner:</span>{' '}
                    {fp.get('attributes.userinfo.name', owner)}
                    <br />
                    <span className={classes.thin}>Provider:</span>{' '}
                    {fp.get('attributes.userinfo.name', provider)}
                </>
            );
        },
        [classes.thin, owners, providers, assignmentIndex],
    );

    const editAssignments = async rows => {
        const {action, changes} = await assignmentDialog({
            rows,
            shifts,
            assignmentIndex,
        });
        console.log('Got', action, changes);
        if (action === 'save') {
            onChangeAssignments({rows, changes});
        }
    };

    const columns = useMemo(() => {
        let cols = [
            {
                title: 'Facility',
                field: 'data.attributes.name',
                defaultSort: 'asc',
            },
            {
                title: 'Fallback',
                field: '',
                render: rowData => formatAssignments(rowData, null),
            },
        ];
        fp.forEach(shift => {
            cols.push({
                title: fp.get('attributes.name', shift),
                filtering: false,
                sorting: false,
                render: rowData => formatAssignments(rowData, shift),
            });
        }, shifts);
        return cols;
    }, [shifts, formatAssignments]);

    const actions = [
        {
            tooltip: 'Edit Shifts',
            icon: 'schedule',
            isFreeAction: true,
            onClick: editShifts,
        },
        {
            tooltip: 'Edit',
            icon: 'edit',
            onClick: (evt, data) => editAssignments(data),
        },
    ];

    const narrow = {fontSize: 12};
    const striped = (data, index) => {
        if (index % 2) {
            return {backgroundColor: '#EFF1F2'};
        }
    };

    console.log('Render material table with rule', rule);

    return (
        <MaterialTable
            title={
                <div className={classes.tableTitle}>
                    <h6>{rule.attributes.name} Assignments</h6>
                </div>
            }
            columns={columns}
            data={facilities}
            options={{
                pageSize: 10,
                search: false,
                filtering: true,
                sorting: true,
                paging: true,
                selection: true,
                actionsColumnIndex: -1,
                headerStyle: narrow,
                cellStyle: narrow,
                filterCellStyle: {padding: 3, fontSize: 12},
                rowStyle: striped,
            }}
            actions={actions}
        />
    );
}

export default function RuleEditor({rule}) {
    const api = useApi();
    const rules = useSelector(ducks.jsonapi.selectObject('Rule'));
    const [ruleId, setRuleId] = useState(null);

    useEffect(() => {
        if (!ruleId) {
            if (!fp.isEmpty(rules)) {
                setRuleId(fp.keys(rules)[0]);
            }
        } else if (fp.isEmpty(rules)) {
            setRuleId(null);
        }
    }, [rules, ruleId]);

    const handleChangeAssignments = async ({rows, changes}) => {
        fp.forEach(row => {
            const fac = row.data;
            fp.forEach(chg => {
                const shift = chg.newData.data;
                // Fallback Assignments
                if (!shift.type) {
                    let data = row.data;
                    if (chg.newData.owner_id) {
                        data = fp.set(
                            'attributes.default_owner_id',
                            chg.newData.owner_id,
                            data,
                        );
                    }
                    if (chg.newData.provider_id) {
                        data = fp.set(
                            'attributes.default_provider_id',
                            chg.newData.provider_id,
                            data,
                        );
                    }
                    api.fetchJsonApi(data.links.self, {
                        method: 'PATCH',
                        json: {data},
                    });
                    return;
                }
                // All other assignments
                let data = {
                    type: 'Assignment',
                    relationships: {
                        facility: {data: {type: 'Facility', id: fac.id}},
                        rule: {
                            data: {
                                type: 'Rule',
                                id: shift.relationships.rule.data.id,
                            },
                        },
                        shift: {data: {type: 'Shift', id: shift.id}},
                    },
                };
                if (chg.newData.owner_id) {
                    data = fp.set(
                        'attributes.owner_id',
                        chg.newData.owner_id,
                        data,
                    );
                }
                if (chg.newData.provider_id) {
                    data = fp.set(
                        'attributes.provider_id',
                        chg.newData.provider_id,
                        data,
                    );
                }

                api.fetchJsonApi(api.url_for('schedule.assignments'), {
                    method: 'POST',
                    json: {data},
                });
            }, changes);
        }, rows);
    };

    return (
        <div>
            {rule && (
                <AssignmentsTable
                    rule={rule}
                    onChangeAssignments={handleChangeAssignments}
                />
            )}
        </div>
    );
}
