import React from 'react';
import * as R from 'react-router-dom';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles, Button, Link} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';

import {url_for} from 'routes';
import {useApi, ducks} from '@arborian/narrf';
// import {selectCognitoLinks, selectUserInfo} from 'ducks/apiDuck';


const useStyles = makeStyles(theme => ({
    authLabel: {
        display: "flex",
        alignItems: "center",
        '& a': {
            paddingTop: 4,
        },
    },
    userLabel: {
        padding: 6,
        marginTop: 4
    },

}));

export default function AuthInfo() {
    const classes = useStyles();

    const api = useApi();
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    let userNameStr = fp.getOr('USER', 'name', userinfo)
    let userLabel = fp.getOr(userNameStr, 'email', userinfo);
    if (!userLabel) {
        userLabel = fp.get('name', userinfo);
    }

    if (!api.isAuthorized()) {
        return (
            <div className={classes.authLabel}>
                <Button color="inherit" component={R.Link} to={url_for('login')}>
                    Staff sign-in
                </Button>
                <Link component={R.Link} to={url_for('register')}>Register</Link>
            </div>
        )
    }
    return (
        <div className={classes.authLabel}>
            <AccountCircle />
            <Link component={R.Link} to={url_for('profile')}>{userLabel}</Link>
            <Button color="inherit" component={R.Link} to={url_for('logout')}>
                Sign out
            </Button>
        </div>
    );
}
