import React from 'react';

import {makeStyles, Button} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import {useDialog, useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    buttonStyle: {},
}));

export default function SyncPatientsButton() {
    const api = useApi();
    const classes = useStyles();
    const pfLoginDialog = useDialog('pfLogin');
    const {enqueueSnackbar} = useSnackbar();

    const handleSync = async () => {
        const token = await pfLoginDialog();
        await api.fetchJson(api.url_for('pf.syncActivePatients'), {
            method: 'POST',
            headers: {'x-pf-token': token},
        });
        enqueueSnackbar('Syncing Active Patients with Practice Fusion.', {
            persist: false,
            variant: 'success',
        });
    };

    return (
        <Button
            styles={classes.buttonStyle}
            variant='contained'
            onClick={handleSync}
        >
            Sync Active Patients List
        </Button>
    );
}
