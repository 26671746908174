import React from 'react';
import fp from 'lodash/fp';

import PCCPatientDataTable from './PCCPatientDataTable';
import {FacilityDate, FacilityDateTime} from './FacilityDates';

function AmountAdministered({value}) {
    let parts = [fp.get('data.attributes.amountAdministered.value', value)];
    let unit = fp.get('data.attributes.amountAdministered.unit', value);
    if(unit) {
        parts.push(unit);
    }
    return <>{parts.join(' ')}</>
}


export default function ImmunizationsTable({patient, facility}) {
    if(!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='immunizations'
            columns={[
                { title: 'Immunization', field: 'data.attributes.immunization' },
                { title: 'Location', field: 'data.attributes.locationGiven' },
                {
                    title: 'Substance Expiration', field: 'data.attributes.substanceExpirationDate', type: 'datetime',
                    render: rd => <FacilityDate value={rd.data.attributes.substanceExpirationDate} facility={facility}/>
                },
                {
                    title: 'Administered', field: 'data.attributes.administrationDateTime', type: 'datetime',
                    render: rd => <FacilityDateTime value={rd.data.attributes.administrationDateTime} facility={facility}/>
                },
                {
                    title: 'Amount', field: 'data.attributes.amountAdministered.value',
                    render: rd => <AmountAdministered value={rd}/>
                },
            ]}
        />
    )
}
