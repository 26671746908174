import React, {useCallback} from 'react';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {
    Radio, FormControl, FormLabel, FormHelperText, makeStyles, Grid,
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    centered: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
}));

function RadioRow({row, columns, value, onChange}) {
    const handleChange = ev => {
        if(!ev.target.checked) return;
        onChange(row.value, ev.target.value);
    }

    return (
        <TableRow>
            <TableCell>{row.label}</TableCell>
            {fp.map(col => (
                <TableCell key={col.value}>
                    <Radio
                        value={col.value} onChange={handleChange}
                        checked={col.value === value}
                    />
                </TableCell>
            ), columns)}
        </TableRow>
    );
}

function RadioTable({
    field, form,  // From Formik
    label, caption, required, rows, columns,
}) {
    const classes = useStyles();
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);
    const handleRowChange = useCallback((k, v) => {
        const newValue = {
            ...field.value,
            [k]: v
        };
        form.setFieldValue(field.name, newValue);
    }, [field, form]);

    return (
            <FormControl component="fieldset"
                className={classes.centered}
                error={!!(touched && errors)}
                required={required}
            >
                <FormLabel>{label}</FormLabel>
                {touched && errors && <FormHelperText>{errors}</FormHelperText>}
                {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
                <Grid justify="center">
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                {fp.map(col => <TableCell key={col.value}>{col.label}</TableCell>, columns)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fp.map(row => <RadioRow key={row.value} row={row} columns={columns}
                                value={fp.get(row.value, field.value)}
                                onChange={handleRowChange}
                            />, rows)}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>

            </FormControl>
    )
}

export default ({field}) => {
    return <Field component={RadioTable} name={field.id}
        label={field.label}
        caption={field.caption}
        required={field.required}
        rows={field.rows}
        columns={field.columns}
    />
}
