import React, {useRef, useCallback, useState} from 'react';
import fp from 'lodash/fp';

import {Refresh} from '@material-ui/icons';
import MaterialTable from 'material-table'

import {useApi} from '@arborian/narrf';

import PCCPagination from './PCCPagination';


export default function PCCPatientDataTable({patient, rel, ...props}) {
    const api = useApi();
    const tableRef = useRef();
    const [hasMore, setHasMore] = useState();


    const fetchData = useCallback(async (o) => {
        const url = patient.relationships[rel].links.related;
        let resp = await api.fetchJsonApi(
            url,
            {...o, page: {number: o.page + 1, size: o.pageSize}});
        setHasMore(fp.getOr(false, 'meta.hasMore', resp));
        let totalCount = o.page * o.pageSize + resp.data.length; // Errors if page exceeds totalCount
        return {
            data: fp.map(row => ({data: row}), resp.data), // prevent mutation of state
            page: o.page,
            totalCount: totalCount,
        }
    }, [api, patient, rel])

    const refresh = () => {
        tableRef.current.onQueryChange();
    }

    if (!patient) return null;

    return (
        <MaterialTable
            tableRef={tableRef}
            style={{width: "100%"}}
            data={fetchData}
            components={{
                Pagination: props => <PCCPagination hasMore={hasMore} {...props} />
            }}
            options={{
                pageSize: 20,
                search: false,
                sorting: false,
                paging: true,
            }}
            actions={[
                {
                    icon: Refresh,
                    tooltip: 'Refresh data',
                    isFreeAction: true,
                    onClick: refresh
                },
            ]}
            {...props}
        />
    );
}
