import React from 'react';

import PCCPatientDataTable from './PCCPatientDataTable';


export default function AllergiesTable({patient, facility}) {
    if(!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='allergies'
            columns={[
                { title: 'Allergen', field: 'data.attributes.allergen' },
                { title: 'Category', field: 'data.attributes.category' },
                { title: 'Clinical Status', field: 'data.attributes.clinicalStatus' },
                { title: 'Created By', field: 'data.attributes.createdBy' },
                { title: 'Reaction Note', field: 'data.attributes.reactionNote' },
                { title: 'Severity', field: 'data.attributes.severity' },
                { title: 'Type', field: 'data.attributes.type' },
            ]}
        />
    )
}
