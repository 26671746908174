import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';
import fp from 'lodash/fp';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';
import {Print} from '@material-ui/icons';
import {Formik, Form} from 'formik';

import {selectFacilityFaxes} from 'lib/selectors';
import {FaxOptionsField} from 'components/fields/FaxOptions';

const selectFaxOptions = createSelector(
    selectFacilityFaxes,
    fp.map(f => `${f.attributes.name}:${f.attributes.number}`),
);

export default function TicketFaxDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const faxOptions = useSelector(selectFaxOptions);
    const [initialValues, setInitialValues] = useState({});

    const handleSubmit = values => {
        resolve(values.fax);
        setResolve(null);
    };
    const handleCancel = () => {
        resolve(null);
        setResolve(null);
    };

    dialogRef.current = fax =>
        new Promise((resolve, reject) => {
            if (fax) {
                setInitialValues({fax});
            } else {
                setInitialValues({fax: []});
            }
            setResolve(() => resolve);
        });

    return (
        <Dialog open={!!resolve} onClose={handleCancel}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <DialogTitle>Fax Ticket?</DialogTitle>
                        <DialogContent>
                            <FaxOptionsField
                                name='fax'
                                options={faxOptions}
                                multiple
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                startIcon={<Print />}
                            >
                                Fax Ticket
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}
