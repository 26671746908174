import React from 'react';

import {Formik, Form, Field} from 'formik';
import {Button} from '@material-ui/core';
import {TextField} from 'formik-material-ui';

import {useApi} from '@arborian/narrf';
import BasePage from 'components/BasePage';

const INITIAL_VALUES = {
    To: '',
    Code: '',
};

export default function MFAPage() {
    const api = useApi();

    const handleSubmit = async values => {
        const resp = await api.fetchJson(
            api.url_for('webhook.webhook') + '/twilio',
            {
                method: 'POST',
                json: {
                    To: values.To,
                    Body: `Your code is: ${values.Code}. Thank you.`,
                },
            },
        );
        console.log('Webhook post got', resp);
    };

    return (
        <BasePage title='MFA Relay'>
            <Formik onSubmit={handleSubmit} initialValues={INITIAL_VALUES}>
                {formikProps => (
                    <Form>
                        <Field
                            label='Your number'
                            name='To'
                            fullWidth
                            component={TextField}
                            helperText='Number receiving the MFA, format +1##########, no dashes or spaces'
                        />
                        <Field
                            label='Your code'
                            name='Code'
                            fullWidth
                            component={TextField}
                        />
                        <Button type='submit'>Submit</Button>
                    </Form>
                )}
            </Formik>
        </BasePage>
    );
}
