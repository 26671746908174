import React, {useMemo, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createSelector} from '@reduxjs/toolkit';

import {Draggable} from 'react-smooth-dnd';
import fp from 'lodash/fp';

import {
    makeStyles,
    ListItemIcon,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Chip,
} from '@material-ui/core';
import {Add, Edit, DragHandle} from '@material-ui/icons';
import SortableList from 'components/SortableList';

import {useApi, useDialog, ducks} from '@arborian/narrf';
import {useConstants} from 'lib/util';

const useStyles = makeStyles(theme => ({
    typesChip: {
        marginLeft: theme.spacing(1),
    },
}));

function RuleItem({rule, onSelect, handleDialog}) {
    const classes = useStyles();
    const TicketTypes = fp.get('ticketTypes', useConstants());
    return (
        <Draggable>
            <ListItem button onClick={() => onSelect(rule)}>
                <ListItemIcon className='drag-handle'>
                    <DragHandle />
                </ListItemIcon>
                <ListItemText>
                    {rule.attributes.name}
                    {fp.map(
                        tag => (
                            <Chip
                                className={classes.typesChip}
                                size='small'
                                key={tag}
                                label={fp.get(tag, TicketTypes)}
                            />
                        ),
                        rule.attributes.ticket_types,
                    )}
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton edge='end' onClick={() => handleDialog(rule)}>
                        <Edit />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </Draggable>
    );
}

const selectRules = escalation =>
    createSelector(
        ducks.jsonapi.selectObject('Rule'),
        fp.pipe([
            fp.filter(r => r.attributes.escalation === escalation),
            fp.sortBy('attributes.ordinal'),
        ]),
    );

export default function RuleList({escalation, include, onSelect, onDeleted}) {
    const api = useApi();
    const selector = useMemo(() => selectRules(escalation), [escalation]);
    const _rules = useSelector(selector);
    const [rules, setRules] = useState({items: [], busy: false});

    const ruleMetadataEditor = useDialog('ruleMetadataEditor');
    const [, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!rules.busy) {
            if (rules.items !== _rules) {
                setRules(fp.set('items', _rules, rules));
            }
        }
    }, [_rules, rules, setRules]);

    const onDrop = async ({removedIndex, addedIndex}) => {
        removedIndex--;
        addedIndex--;
        let newList = [...rules.items];
        let [it] = newList.splice(removedIndex, 1);
        newList.splice(addedIndex, 0, it);
        setRules({busy: true, items: newList});
        try {
            let results = await Promise.all(
                fp.map(i => {
                    let item = newList[i];
                    let newOrd = i - newList.length;
                    console.log({name: item.attributes.name, i});
                    return api.fetchJsonApi(item.links.self, {
                        method: 'PATCH',
                        json: {
                            data: fp.set('attributes.ordinal', newOrd, item),
                        },
                        include,
                    });
                }, fp.range(0, fp.size(newList))),
            );
            setRules({busy: false, items: fp.map('data', results)});
        } catch (e) {
            setRules(fp.set('busy', false, rules));
            throw e;
        }
    };

    const handleCreate = async rule => {
        const NEW_RULE = {
            type: 'Rule',
            attributes: {
                name: 'New rule',
                ordinal: rules.items.length
                    ? fp.minBy('attributes.ordinal', rules.items).attributes
                          .ordinal - 1
                    : 0,
                escalation: escalation,
                ticket_types: [],
            },
        };
        const resp = await api.fetchJsonApi(api.url_for('schedule.rules'), {
            method: 'POST',
            json: {data: NEW_RULE},
            include: ['shifts'],
        });
        console.log('Got response', resp);
        onSelect(resp.data);
        handleEditMetadata(resp.data);
    };

    const handleDelete = async rule => {
        await api.fetchJsonApi(rule.links.self, {method: 'DELETE'});
        dispatch(ducks.jsonapi.deleteData(rule));
        onDeleted && onDeleted(rule);
    };

    const handleUpdate = async rule => {
        await api.fetchJsonApi(rule.links.self, {
            method: 'PATCH',
            json: {data: rule},
            include: ['shifts'],
        });
    };

    const handleEditMetadata = async item => {
        setAnchorEl(null);
        let {action, values} = await ruleMetadataEditor(item);
        if (action === 'delete') {
            handleDelete(item);
        } else if (action === 'save') {
            handleUpdate(values);
        }
    };

    return (
        <SortableList dragHandleSelector='.drag-handle' onDrop={onDrop}>
            <ListItem button onClick={() => handleCreate()}>
                <ListItemIcon>
                    <Add />
                </ListItemIcon>
                <ListItemText>New Rule</ListItemText>
            </ListItem>
            {fp.map(
                r => (
                    <RuleItem
                        key={r.id}
                        rule={r}
                        onSelect={onSelect}
                        handleDialog={handleEditMetadata}
                    />
                ),
                rules.items,
            )}
        </SortableList>
    );
}
