import React from 'react';
import {useField} from 'formik';
import fp from 'lodash/fp';

import {TextField, FormHelperText} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

/* FreeSolo that reformats the values before setting them.
 * Allows for some type of labeling. Expects "key:val" strings.
 */

export function FaxOptionsField({label, fullWidth, ...props}) {
    const [field, , helpers] = useField(props);
    const requiredTextField = (inputProps, props) => {
        console.log({props});
        return (
            <TextField
                label={label}
                fullWidth={fullWidth}
                required={props.required ? field.value.length === 0 : false}
                {...inputProps}
            />
        );
    };
    const renderInput = inputProps => requiredTextField(inputProps, props);
    const onChange = (ev, value, reason) => {
        value = fp.pipe(
            fp.map(v => v.split(':').slice(-1).pop()),
            fp.uniq,
        )(value);
        helpers.setValue(value);
    };
    const allProps = {
        ...field,
        ...props,
        freeSolo: true,
        autoSelect: true,
        renderInput,
        onChange,
    };
    return <Autocomplete {...allProps} />;
}

export default ({field}) => {
    return (
        <>
            <FaxOptionsField
                name={field.id}
                label={field.label}
                options={field.options}
                required={field.required}
                multiple={field.multiple}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
};
