import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField as MUITextField,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {Autocomplete} from 'formik-material-ui-lab';
import * as Yup from 'yup';
import {useSnackbar} from 'notistack';

import {useApi} from '@arborian/narrf';

import {selectFacilities} from 'lib/selectors';

const RegistrationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email('Invalid email').required(),
    facility: Yup.mixed().required(),
});

export default function PasswordRegistrationDialog({dialogRef}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const api = useApi();
    const facilities = useSelector(selectFacilities);
    const {enqueueSnackbar} = useSnackbar();
    const [resolve, setResolve] = useState(null);

    useEffect(() => {
        if (!api || !api.hasDirectory) {
            return;
        }
        if (!facilities.length) {
            api.fetchAllJsonApi(api.url_for('facility.collection'));
        }
    }, [api, facilities]);

    dialogRef.current = () => {
        return new Promise((resolve, reject) => {
            setResolve(() => resolve);
        });
    };

    const initialValues = {
        name: '',
        email: '',
        facility: null,
    };

    const handleSubmit = async (values, {resetForm}) => {
        try {
            await api.fetchJson(api.url_for('auth.registration'), {
                method: 'POST',
                json: {
                    data: {
                        type: 'Registration',
                        attributes: fp.set(
                            'facility',
                            fp.get('facility.id', values),
                            values,
                        ),
                    },
                },
            });
            console.log('Success submitting', values);
            resetForm();
            setResolve(null);
            resolve && resolve(true);
        } catch (e) {
            enqueueSnackbar('Error creating registration', {variant: 'error'});
            throw e;
        }
    };

    const handleCancel = ({handleReset}) => {
        handleReset({});
        setResolve(null);
        resolve && resolve(null);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={RegistrationSchema}
        >
            {formikProps => (
                <Form>
                    <Dialog
                        fullScreen={fullScreen}
                        open={!!resolve}
                        onClose={() => handleCancel(formikProps)}
                        fullWidth
                        maxWidth='md'
                    >
                        <DialogTitle>Staff Registration</DialogTitle>
                        <DialogContent>
                            <Field
                                name='name'
                                component={TextField}
                                fullWidth
                                label='Name'
                            />
                            <Field
                                name='email'
                                component={TextField}
                                fullWidth
                                label='Email'
                            />
                            <Field
                                fullWidth
                                name='facility'
                                component={Autocomplete}
                                options={facilities}
                                getOptionLabel={fp.get('attributes.name')}
                                renderInput={params => (
                                    <MUITextField
                                        label='Facility'
                                        error={
                                            formikProps.touched['facility'] &&
                                            !!formikProps.errors['facility']
                                        }
                                        helperText={
                                            formikProps.errors['facility']
                                        }
                                        {...params}
                                    />
                                )}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleCancel(formikProps)}>
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                onClick={formikProps.handleSubmit}
                            >
                                Register
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
}
