import {useLocation} from 'react-router-dom';
import fp from 'lodash/fp';
import {reverse} from 'named-urls';
import {createBrowserHistory} from 'history';

export const history = createBrowserHistory();

const routeList = [
    {name: 'home', path: '/'},
    {name: 'login', path: '/login'},
    {name: 'logout', path: '/logout'},
    {name: 'logoutRedirect', path: '/logoutRedirect'},
    {name: 'register', path: '/register'},
    {name: 'registrationRedirect', path: '/register/complete'},
    {name: 'profile', path: '/profile'},

    {name: 'about', path: '/about'},
    {name: 'staticAbout', path: '/about.html'},
    {name: 'tos', path: '/tos'},
    {name: 'staticTOS', path: '/tos.html'},

    {name: 'communicate', path: '/communicate'},
    {name: 'communicateStaff', path: '/StaffCommunicate'},
    {name: 'callback', path: '/callback'},
    {name: 'tickets', path: '/ticket'},
    {name: 'ticketDetails', path: '/ticket/:ticketid'},
    {name: 'form', path: '/form/:formid/page/:pageid'},
    {name: 'formPCCData', path: '/form/pcc'},
    {name: 'formSubmit', path: '/form/:formid/submit'},
    {name: 'formContact', path: '/form/:formid/contact'},
    {name: 'formSuccess', path: '/form/success'},
    {name: 'orders', path: '/orders'},
    {name: 'adminFacilities', path: '/admin/facility'},
    {name: 'adminFacility', path: '/admin/facility/:facid'},
    {name: 'adminPermissions', path: '/admin/permissions'},
    {name: 'adminFaxDirectory', path: '/admin/faxDirectory'},
    {name: 'adminSchedule', path: '/schedule'},
    {name: 'adminTools', path: '/adminTools'},
    {name: 'providerSchedules', path: '/providerSchedules'},
    {name: 'practiceFusion', path: '/pf'},

    {name: 'mfa', path: '/mfa'},
];

export const routes = fp.transform(
    (acc, r) => {
        acc[r.name] = r;
    },
    {},
    routeList,
);

export const useQuery = () => new URLSearchParams(useLocation().search);

export const routeByType = fp.transform(
    routeList,
    (acc, r) => {
        if (r.type) {
            acc[r.type] = r;
        }
    },
    {},
);

export const url_for = (route, params) => {
    try {
        let {path} = routes[route];
        return reverse(path, params);
    } catch (e) {
        console.error('Error looking up route', route, params);
        throw e;
    }
};

export default routes;
