import React, { useState, useRef, useCallback } from 'react';
import fp from 'lodash/fp';

import { Button, TextareaAutosize, Paper, makeStyles } from '@material-ui/core';
import { FilterNone } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    textArea: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        width: "100%",
        padding: 25,
        paddingTop: 20,
        border: 'none'
    },
    buttonArea: {
        textAlign: 'end',
    },
    button: {
        margin: 10,
    },
}));


export default function TicketNarrative({value, hideCopy, classes}) {
    classes = {...useStyles(), ...classes};
    const [copySuccess, setCopySuccess] = useState('Copy');
    const textAreaRef = useRef(null);

    const copyToClipboard = useCallback(e => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.blur();
        setCopySuccess('Copied!');
    }, [textAreaRef, setCopySuccess]);

    return (
        <>
            <Paper elevation={2}>
                {
                   document.queryCommandSupported('copy') &&
                    <div className={classes.buttonArea} hidden={hideCopy || false}>
                      <Button className={classes.button}
                        variant="outlined"
                        onClick={copyToClipboard}>
                        <FilterNone style={{padding: 4}}/> {copySuccess}
                    </Button>
                    </div>
                }
                <TextareaAutosize
                    ref={textAreaRef}
                    className={classes.textArea}
                    rowsMin={5}
                    aria-label="textarea"
                    placeholder=""
                    value={fp.get('attributes.narrative', value)}>
                </TextareaAutosize>
                
            </Paper>
            
        </>
    )
}



