import React from 'react';

import {makeStyles, Button} from '@material-ui/core';
import * as I from '@material-ui/icons';
import {Formik, Form} from 'formik';
import fp from 'lodash/fp';
import * as Yup from 'yup';

import BasePage from 'components/BasePage';
import {history, url_for} from '../routes';
import {useApi} from '@arborian/narrf';
import {useSnackbar} from 'notistack';

import {FacilitySelectField} from 'components/fields/FacilitySelect';
import {AutocompleteField} from 'components/fields/Autocomplete';

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: 'center',
        padding: 16,
    },
    body: {
        textAlign: 'center',
        padding: 16,
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 40,
    },
    logo: {
        maxWidth: 800,
    },
    createButton: {
        margin: 40,
    },
    PCCModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
}));

export default function CommunicatePage() {
    const api = useApi();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    let formid = 1; // Only one form for now; will get from ui menu?
    const iamOptions = [
        {name: 'Staff', value: 'staff'},
        {name: 'Patient, family, or non-staff', value: 'nonstaff'},
    ];
    const validationSchema = Yup.object().shape({
        facility: Yup.object().required(),
        iam: Yup.string().required(),
    });

    const createSession = async data => {
        const url = api.url_for('session.collection');
        const resp = await api.fetchJson(url, {
            method: 'POST',
            body: JSON.stringify({
                formid: formid,
            }),
            headers: {'Content-Type': 'application/json'},
        });
        let pageid = resp.data.attributes.next_page;
        let session = resp.data.id;
        let nextPage = `/form/${formid}/page/${pageid}?sess=${session}`;
        // Update session with values
        let updateDoc = {
            type: 'Session',
            attributes: {
                facility_id: data.facility.id,
                by_staff: data.iam.value === 'staff' ? true : false,
                session_data: {
                    submitType: 'normalTicket',
                    iam: data.iam.value,
                    pccTicket: false,
                },
            },
        };
        await api.fetchJson(`${url}/${session}`, {
            method: 'PUT',
            body: JSON.stringify({
                data: updateDoc,
            }),
            headers: {'Content-Type': 'application/json'},
        });
        return nextPage;
    };

    const handleSubmit = async (data, formikProps) => {
        let nextPage = null;
        if (
            fp.get('relationships.pcc_facility.data', data.facility) &&
            data.iam.value === 'staff'
        ) {
            enqueueSnackbar(
                'Sign-in required for PointClickCare Facility. Please sign-in to create a ticket.',
                {
                    persist: false,
                    variant: 'warning',
                },
            );
            nextPage = {
                pathname: url_for('login'),
                state: {next: url_for('communicateStaff')},
            };
        } else {
            nextPage = await createSession(data);
        }
        history.push(nextPage);
    };

    return (
        <BasePage>
            <header className={classes.header}>
                <h3>Communicate with Us</h3>
            </header>
            <div className={classes.body}>
                <Formik
                    initialValues={{facility: null, iam: null}}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <Form onSubmit={formikProps.handleSubmit}>
                            <strong>Please choose your location:</strong>
                            <FacilitySelectField
                                fullWidth
                                name='facility'
                                label='Select a Facility'
                                required={true}
                            />
                            <br />
                            <strong>I am:</strong>
                            <AutocompleteField
                                name='iam'
                                label='I am...'
                                options={iamOptions}
                                getOptionLabel={fp.get('name')}
                                required={true}
                            />
                            <div>
                                <Button
                                    className={classes.createButton}
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    startIcon={<I.Create />}
                                >
                                    New Ticket
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </BasePage>
    );
}
