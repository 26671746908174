import React from 'react';
import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    makeStyles,
} from '@material-ui/core';

import {AttachFile, CloudDownload, ExpandMore} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    attachment: {
        '& .MuiAccordionDetails-root': {
            flexDirection: 'column',
        },
        '& .MuiButtonBase-root': {
            alignSelf: 'flex-start',
        },
    },
}));

export default function Attachment({value}) {
    const classes = useStyles();
    const href = fp.get('links.content', value);
    return (
        <Accordion className={classes.attachment}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <AttachFile />
                {fp.get('attributes.filename', value)}
            </AccordionSummary>
            <AccordionDetails>
                <Button
                    href={href}
                    target='_blank'
                    startIcon={<CloudDownload />}
                >
                    Download
                </Button>
                {fp.startsWith(
                    'image/',
                    fp.get('attributes.contentType', value),
                ) ? (
                    <img
                        src={href}
                        alt={fp.get('attributes.filename', value)}
                    />
                ) : null}
            </AccordionDetails>
        </Accordion>
    );
}
