import React, {useCallback} from 'react';
import fp from 'lodash/fp';
import {Field} from 'formik';

import {
    RadioGroup as MUIRadioGroup,
    Radio, FormControl, FormLabel, FormControlLabel, FormHelperText,
    makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    img: {
        maxWidth: "100%",
    },
    centered: {
        width: '100%',
        alignItems: "center",
        justifyContent: "center"
    },
}));

function RadioGroup({
    field, form,  // From Formik
    label, caption, options, required, row, image,
}) {
    const classes = useStyles();
    const touched = form.submitCount > 0 || fp.get(field.name, form.touched);
    const errors = fp.get(field.name, form.errors);

    let img = null;
    if (image) {
        try {
            const images = require.context('../../images', true);
            img = images('./' + image);
        }
        catch {}
    }

    return (
        <FormControl component="fieldset"
            className={classes.centered}
            error={!!(touched && errors)}
            required={required}
        >
            <FormLabel>{label}</FormLabel>
            <img className={classes.img} alt='' src={img} />
            {touched && errors && <FormHelperText>{errors}</FormHelperText>}
            <MUIRadioGroup
                row={row}
                value={field.value || null}
                onChange={useCallback((ev, newValue) => {
                    form.setFieldValue(field.name, newValue);
                }, [form, field])}
            >
                {fp.map(option => (
                    <FormControlLabel key={option.value}
                        value={option.value} 
                        control={<Radio/>}
                        label={option.label}
                        labelPlacement={row ? 'bottom' : 'end'}
                    />
                ), options)}
            </MUIRadioGroup>
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}

        </FormControl>
    )
}

export default ({field}) => {
    return <Field component={RadioGroup} name={field.id}
        label={field.label}
        caption={field.caption}
        required={field.required}
        row={field.fieldDirection === 'row'}
        options={field.options}
        image={field.image}
    />
}
