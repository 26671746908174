import React from 'react';
import fp from 'lodash/fp';

import {Avatar, Tooltip, makeStyles} from '@material-ui/core';
import {
    Assignment,
    AssignmentTurnedIn,
    AssignmentLate,
    Block,
    Publish,
} from '@material-ui/icons';

import {useFeatures} from 'lib/util';

const useStyles = makeStyles(theme => ({
    stavatar: {
        display: 'flex',
        '& .MuiAvatar-root': {
            margin: theme.spacing(0, 0.25),
        },
    },
    pccGreen: {
        backgroundColor: '#8EC428',
    },
    pfBlue: {
        backgroundColor: '#00A3FB',
    },
}));

function TicketStatusAvatar({ticket, classes}) {
    var title, className, Icon;

    if (fp.get('attributes.status', ticket) === 'resolved') {
        title = 'Resolved';
        className = classes.success;
        Icon = AssignmentTurnedIn;
    } else {
        title = 'Pending';
        className = classes.pending;
        Icon = AssignmentLate;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

function PFStatusAvatar({ticket, classes}) {
    var title, className, Icon;
    if (fp.get('attributes.uploaded_PF', ticket)) {
        title = 'Uploaded to Practice Fusion';
        className = classes.pfBlue;
        Icon = Publish;
    } else {
        title = 'Not uploaded to Practice Fusion';
        className = null;
        Icon = Block;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

function PCCStatusAvatar({ticket, classes}) {
    var title, className, Icon;
    const showPCC = fp.pipe([
        fp.get('attributes.patient_identifier'),
        fp.find(ident => ident.system === 'http://www.pointclickcare.com'),
    ])(ticket);
    if (!showPCC) {
        return null;
    }
    if (fp.get('attributes.uploaded_PCC', ticket)) {
        title = 'Uploaded to PointClickCare';
        className = classes.pccGreen;
        Icon = Publish;
    } else {
        title = 'Not uploaded to PointClickCare';
        className = null;
        Icon = Block;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

function PCCProgressNoteAvatar({ticket, classes}) {
    var title, className, Icon;
    const showPCC = fp.pipe([
        fp.get('attributes.patient_identifier'),
        fp.find(ident => ident.system === 'http://www.pointclickcare.com'),
    ])(ticket);
    if (!showPCC) {
        return null;
    }
    const pnId = fp.get('attributes.pcc_progressNoteId', ticket);
    if (pnId) {
        title = `Progress note created in PointClickCare: ${pnId}`;
        className = classes.pccGreen;
        Icon = Assignment;
    } else {
        title = 'No progress note created in PointClickCare';
        className = null;
        Icon = Block;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className}>
                <Icon />
            </Avatar>
        </Tooltip>
    );
}

export default function Stavatar({ticket, classes}) {
    const features = useFeatures();
    classes = {...classes, ...useStyles()};
    return (
        <div className={classes.stavatar}>
            <TicketStatusAvatar ticket={ticket} classes={classes} />
            <PFStatusAvatar ticket={ticket} classes={classes} />
            {features.pcc_pdf_upload && (
                <PCCStatusAvatar ticket={ticket} classes={classes} />
            )}
            {features.pcc_progress_note_upload && (
                <PCCProgressNoteAvatar ticket={ticket} classes={classes} />
            )}
        </div>
    );
}
