import React from 'react';
import * as R from 'react-router-dom';

import {makeStyles, Link} from '@material-ui/core';

import {url_for} from 'routes';

const useStyles = makeStyles(theme => ({
    
    footerContainer: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgb(250,250,250)',
        borderTop: '1px solid #ccc',
    },
    footerLinks: {
        display: "flex",
        justifyContent: "center",
        '& a': {
            margin: 10,
        },
    },
    footerModal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    tosPaper: {
        paddingLeft: 10,
        paddingRight: 10
    }
}));

export default function Footer({title, classes={}, children}) {
    classes = {...useStyles(), ...classes};

    return (
        <div className={classes.footerContainer}>
            <span className={classes.footerLinks}>
                <Link component={R.Link} to={url_for('about')}>About</Link>
                <Link href="mailto:services@tcpamd.com">Support</Link>
                <Link href="/tos.html" target="_blank">Terms of Service</Link>
                <Link href="/privacy.html" target="_blank">Privacy Policy</Link>
            </span>
        </div>
    )
}
