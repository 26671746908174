import React, {useState, useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {makeStyles, Typography, Button} from '@material-ui/core';
import MaterialTable from 'material-table';

import {history} from '../routes';
import BasePage from 'components/BasePage';
import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({}));

const formatObservationValue = obs => {
    switch (obs.type) {
        case 'bloodPressure':
            return `${obs.diastolicValue} / ${obs.systolicValue} ${obs.unit}`;
        case 'painLevel':
            return `${obs.value}`;
        default:
            return `${obs.value} ${obs.unit}`;
    }
};

function SimpleTable({data}) {
    if (!data.length) return <Typography>No data</Typography>;

    const columns = [
        {
            title: 'Recorded Date',
            field: 'recordedDate',
            type: 'datetime',
            defaultSort: 'desc',
        },
        {title: 'Recorded By', field: 'recordedBy'},
        {title: 'Observation', field: 'type'},
        {title: 'Value', field: 'value', render: formatObservationValue},
    ];
    return (
        <MaterialTable
            title='PointClickCare Vitals'
            columns={columns}
            data={data}
            options={{
                sorting: true,
                paging: false,
                filtering: false,
                search: false,
            }}
        />
    );
}

export default function PatientDataPage({match}) {
    const classes = useStyles();
    const api = useApi();
    const query = new URLSearchParams(useLocation().search);
    const sessionid = query.get('sess');
    const sessionUrl = api.url_for('session.collection');
    const [session, setSession] = useState();
    const [pcc, setPcc] = useState();

    const fetchSession = useCallback(async () => {
        const resp = await api.fetchJson(`${sessionUrl}/${sessionid}`);
        setSession(resp.data);
        setPcc(resp.data.attributes.session_data.pcc);
    }, [api, setSession, setPcc, sessionUrl, sessionid]);

    // const updateSession = async () => {
    //     const formid = 1;
    //     let nextPage = `/form/${formid}/page/pcc?sess=${sessionid}`;
    //     let updateDoc = {
    //         type: 'Session',
    //         attributes: {
    //             facility_id: session.attributes.facility_id,
    //             by_staff: true,
    //             'session_data': {pcc: ''}
    //         }
    //     }
    //     await api.fetchJson(
    //         `${sessionUrl}/${sessionid}`, {
    //             method: 'PUT',
    //             body: JSON.stringify({
    //                 "data": updateDoc}),
    //             headers: {'Content-Type': 'application/json'},
    //         }
    //     );
    //     history.push(nextPage);
    // };

    const nextPage = () => {
        const formid = 1;
        let next = '';
        if (session.attributes.next_page === 'complete') {
            next = `/form/${formid}/submit?sess=${sessionid}`;
        } else {
            next = `/form/${formid}/page/${session.attributes.next_page}?sess=${sessionid}`;
        }
        history.push(next);
    };

    useEffect(() => {
        fetchSession();
    }, [api, fetchSession]);

    if (!pcc) return null;
    console.log('pdp', pcc);
    return (
        <BasePage class={classes}>
            <SimpleTable data={pcc.attributes.vitals} />
            <Button style={{margin: 20}} variant='contained' onClick={nextPage}>
                Continue
            </Button>
        </BasePage>
    );
}
