import React, {useState} from 'react';

import {Dialog, DialogContent, DialogActions} from '@material-ui/core';
import {makeStyles, TextField, Button} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    /*
    root: {
        '& form': {
            padding: theme.spacing(2),
        },
    },
    */
}));

function PracticeFusionValidationCode({onCancel, onSubmit}) {
    const [validationCode, setValidationCode] = useState('');

    const handleSubmit = ev => {
        ev.preventDefault();
        onSubmit && onSubmit({validationCode});
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextField
                    fullWidth
                    label='Validation code'
                    value={validationCode}
                    onChange={e => setValidationCode(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type='submit' color='primary' variant='contained'>
                    Save code
                </Button>
            </DialogActions>
        </form>
    );
}

export default function PracticeFusionValidationCodeDIalog({dialogRef}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [resolve, setResolve] = useState(null);

    const handleSubmit = credentials => {
        resolve(credentials);
        setOpen(false);
    };

    const handleCancel = () => {
        resolve(null);
        setOpen(false);
    };

    dialogRef.current = async () => {
        const promise = new Promise((resolve, reject) => {
            setResolve(() => resolve);
            setOpen(true);
        });
        return await promise;
    };

    return (
        <Dialog className={classes.root} open={open} onClose={handleCancel}>
            <PracticeFusionValidationCode
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            />
        </Dialog>
    );
}
