import React, {useState} from 'react';
import fp from 'lodash/fp';
import moment from 'moment';

import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';

import {useApi} from '@arborian/narrf';
import {
    useFeatures,
    strWithNewline,
    useAsyncMemo,
    resolveRelationships,
} from 'lib/util';

import VitalsTable from 'components/pcc/VitalsTable';
import MedicationsTable from 'components/pcc/MedicationsTable';
import ConditionsTable from 'components/pcc/ConditionsTable';
import AllergiesTable from 'components/pcc/AllergiesTable';
import NutritionTable from 'components/pcc/NutritionTable';
import LabsTable from 'components/pcc/LabsTable';
import RadiologyTable from 'components/pcc/RadiologyTable';
import ImmunizationsTable from 'components/pcc/ImmunizationsTable';
import ProgressNotesTable from 'components/pcc/ProgressNotesTable';

const useStyles = makeStyles(theme => ({
    textArea: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        width: '100%',
        padding: 25,
        border: 'none',
    },
    buttonArea: {
        textAlign: 'center',
    },
    button: {
        margin: 10,
    },
}));

function PatientDataPanel({rel, title, expanded, handleExpand, children}) {
    return (
        <Accordion expanded={expanded === rel} onChange={handleExpand(rel)}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${rel}-panel-content`}
                id={`${rel}-panel-header`}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>
                {expanded === rel ? children : null}
            </AccordionDetails>
        </Accordion>
    );
}

export default function PCCPatientBrowser({ticket, actions, classes}) {
    classes = {...useStyles(), ...classes};
    const api = useApi();
    const features = useFeatures();
    const [expanded, setExpanded] = useState('');

    const pdata = useAsyncMemo(async () => {
        let data = await api.fetchJsonApi(
            ticket.relationships.pcc_patient.links.related,
            {include: ['facility']},
        );
        const rels = resolveRelationships(data);
        return {patient: data.data, ...rels};
    }, [api, ticket]);
    const patient = fp.get('patient', pdata);
    const facility = fp.get('facility', pdata);

    const handleExpand = panel => async (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        let reviewedMsg = `Provider reviewed PointClickCare ${panel}`;
        if (
            isExpanded &&
            !(ticket.attributes.notes || '').includes(reviewedMsg)
        ) {
            let nowStr = moment().format('YYYY-MM-DD hh:mm a');
            addToTicket(`${nowStr}: ${reviewedMsg}`);
        }
    };

    const addToTicket = data => {
        console.log('Add data to ticket', data, ticket);
        let notes = strWithNewline(ticket.attributes.notes || '');
        notes += data;
        return actions.patch(ticket, {notes});
    };

    return (
        <div>
            <PatientDataPanel
                rel='vitals'
                title='Vitals'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <VitalsTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='medications'
                title='Medications'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <MedicationsTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='conditions'
                title='Conditions'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <ConditionsTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='allergies'
                title='Allergies'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <AllergiesTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='nutrition'
                title='Nutrition'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <NutritionTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='labs'
                title='Labs'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <LabsTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='radiology'
                title='Radiology'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <RadiologyTable patient={patient} facility={facility} />
            </PatientDataPanel>

            <PatientDataPanel
                rel='immunizations'
                title='Immunizations'
                expanded={expanded}
                handleExpand={handleExpand}
            >
                <ImmunizationsTable patient={patient} facility={facility} />
            </PatientDataPanel>

            {features.pcc_progress_note_upload && (
                <PatientDataPanel
                    rel='progressNotes'
                    title='Progress Notes'
                    expanded={expanded}
                    handleExpand={handleExpand}
                >
                    <ProgressNotesTable patient={patient} facility={facility} />
                </PatientDataPanel>
            )}
        </div>
    );
}
