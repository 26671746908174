import React from 'react';
import {useField} from 'formik';

import {TextField, FormHelperText} from '@material-ui/core';

import {Autocomplete} from '@material-ui/lab';

/* FreeSolo is a version of the Autocomplete, but all options must be
 * strings and the user is allowed to type in things that are not on the list
 */

export function FreeSoloField({ label, fullWidth, ...props }) {
    const [field, meta, helpers] = useField(props);
    const renderInput = props => {
        const error = (meta.error === "required");
        return (
            <TextField
                label={label} 
                fullWidth={fullWidth} 
                helperText={error ? "required" : null}
                error={error}
                {...props} 
            />
        )
    }
    const onChange = (ev, value, reason) => {
        helpers.setValue(value);
    }
    let allProps = {
        ...field, ...props,
        freeSolo: true,
        autoSelect: true,
        renderInput, onChange
    };
    allProps.value = allProps.value || (props.multiple ? [] : '');
    return <Autocomplete {...allProps}/>
}

export default ({field}) => {
    return (
        <>
            <FreeSoloField
                name={field.id}
                label={field.label}
                options={field.options}
                required={field.required}
                multiple={field.multiple}
            />
            {field.caption && <FormHelperText>{field.caption}</FormHelperText>}
        </>
    );
}
