import React from 'react';

import { Icon, IconButton, TablePagination } from '@material-ui/core';

export function PCCPaginationActions({
    page, count, hasMore, onChangePage, className, 
    ...props
}) {
    const firstPage = e => {
        onChangePage(e, 0);
    };
    const nextPage = e => {
        onChangePage(e, page + 1);
    };
    const prevPage = e => {
        onChangePage(e, page - 1);
    };
    return (
        <div className={className}>
            <IconButton disabled={page === 0} onClick={firstPage}>
                <Icon>first_page</Icon>
            </IconButton>
            <IconButton disabled={page === 0} onClick={prevPage}>
                <Icon>chevron_left</Icon>
            </IconButton>
            <IconButton disabled={!hasMore} onClick={nextPage}>
                <Icon>chevron_right</Icon>
            </IconButton>
        </div>
    )
}

export default function PCCPagination({ActionsComponent, hasMore, ...props}) {
    return (
        <TablePagination {...props}
            ActionsComponent={props => <PCCPaginationActions hasMore={hasMore} {...props}/>}
            rowsPerPageOptions={[5, 10, 20]}
            rowsPerPage={props.rowsPerPage}
        />
    );
}
