import React, {useCallback, useRef, useState, useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles, IconButton, Tooltip, TextField} from '@material-ui/core';
import {OpenInNew, Check} from '@material-ui/icons';
import MaterialTable from 'material-table';
import {Autocomplete} from '@material-ui/lab';

import BasePage from 'components/BasePage';
import {useApi, ducks} from '@arborian/narrf';
import {
    selectAvailableFacilities,
    selectProgressNotesByFac,
} from 'lib/selectors';
import {url_for} from '../routes';

const useStyles = makeStyles(theme => ({
    header: {
        'text-align': 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    wideTable: {},
}));

function EditProgressNoteType(props) {
    const facId = props.rowData.data.id;
    const options = fp.get(facId, useSelector(selectProgressNotesByFac));
    const [value, setValue] = useState(
        fp.find(o => o.attributes.noteType === props.value),
    );

    const handleChange = (evt, newValue) => {
        setValue(newValue);
        props.onChange(fp.get('attributes.noteType', newValue));
    };
    if (!options) return null;
    return (
        <Autocomplete
            options={options}
            getOptionLabel={fp.get('attributes.noteType')}
            value={value}
            onChange={handleChange}
            renderInput={params => <TextField {...params} />}
        />
    );
}

export default function FacilitiesPage() {
    const api = useApi();
    const classes = useStyles();
    const tableRef = useRef();
    const [init, setInit] = useState(false);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const isAdmin = fp.includes('__admin__', userinfo.scopes);
    const facilities = useSelector(selectAvailableFacilities);

    const data = useMemo(() => fp.map(data => ({data}), facilities), [
        facilities,
    ]);

    const refresh = useCallback(() => {
        api.fetchAllJsonApi(api.url_for('facility.collection'), {
            sort: ['name'],
            include: ['pcc_facility', 'pcc_facility.progressNoteTypes'],
        });
    }, [api]);

    useEffect(() => {
        if (!init) {
            setInit(true);
            refresh();
        }
    }, [init, setInit, refresh]);

    const onRowUpdate = async rowData => {
        await api.fetchJsonApi(rowData.data.links.self, {
            method: 'PATCH',
            json: {data: rowData.data},
        });
    };

    const editFaxNumbers = rowData => (
        <a href={url_for('adminFacility', {facid: rowData.data.id})}>
            <IconButton>
                <OpenInNew />
            </IconButton>
        </a>
    );

    const urlCell = rowData => (
        <Tooltip title={rowData.data.attributes.schedule_url} placement='right'>
            <a
                href={rowData.data.attributes.schedule_url}
                target='_blank'
                rel='noopener noreferrer'
            >
                {rowData.data.attributes.schedule_url ? 'View Schedule' : null}
            </a>
        </Tooltip>
    );

    const pccLinkStatus = rowData =>
        !fp.get('data.relationships.pcc_facility.data', rowData) ? null : (
            <Check />
        );

    const columns = useMemo(() => {
        if (isAdmin) {
            return [
                {
                    title: 'Facility',
                    field: 'data.attributes.name',
                    defaultSort: 'asc',
                    editable: false,
                },
                {
                    title: 'PointClickCare Enabled',
                    field: 'data.relationships.pcc_facility.data',
                    editable: false,
                    filtering: false,
                    render: pccLinkStatus,
                },
                {
                    title: 'Progress Note Type',
                    field: 'data.attributes.pcc_progress_note_type',
                    sorting: false,
                    filtering: false,
                    editable: (colData, rowData) => {
                        console.log({colData, rowData});
                        return !fp.get(
                            'data.relationships.pcc_facility.data',
                            rowData,
                        )
                            ? false
                            : true;
                    },
                    editComponent: EditProgressNoteType,
                },
                {
                    title: 'Provider Schedule',
                    field: 'data.attributes.schedule_url',
                    sorting: false,
                    filtering: false,
                    render: urlCell,
                },
                {
                    title: 'Fax Directory',
                    field: 'data.attributes',
                    sorting: false,
                    editable: false,
                    filtering: false,
                    render: editFaxNumbers,
                },
            ];
        }
        return [
            {
                title: 'Facility',
                field: 'data.attributes.name',
                defaultSort: 'asc',
                editable: false,
            },
            {
                title: 'Provider Schedule',
                field: 'data.attributes.schedule_url',
                sorting: false,
                filtering: false,
                render: urlCell,
            },
        ];
    }, [isAdmin]);

    return (
        <BasePage>
            <div className={classes.wideTable}>
                <MaterialTable
                    title='Facilities'
                    columns={columns}
                    data={data}
                    tableRef={tableRef}
                    options={{
                        pageSize: isAdmin ? 20 : 5,
                        search: false, // Not working; needs index
                        filtering: true,
                        sorting: true,
                        paging: true,
                        actionsColumnIndex: -1,
                    }}
                    editable={isAdmin ? {onRowUpdate: onRowUpdate} : null}
                    actions={[]}
                />
            </div>
        </BasePage>
    );
}
