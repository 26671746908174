import React from 'react';
import fp from 'lodash/fp';

import {
    Chip,
    Table, TableBody, TableCell, TableRow,
    makeStyles
} from '@material-ui/core';
import MaterialTable from 'material-table'

import {FacilityDate, FacilityDateTime} from './FacilityDates';

const useStyles = makeStyles(theme => ({
    panel: {
        padding: theme.spacing(2)
    }
}));

function AttrDisplay({attr, value}) {
    const attrVal = fp.get(attr.id, value);
    if(attr.render) {
        return attr.render(attrVal, value);
    } else {
        return <>{attrVal}</>
    }
}


function AttributeTable({value, attrs}) {
    return (
        <Table>
            <TableBody>
                {fp.pipe([
                    fp.filter(a => fp.has(a.id, value)),
                    fp.map(a => (
                        <TableRow key={a.id}>
                            <TableCell>{a.label}</TableCell>
                            <TableCell><AttrDisplay attr={a} value={value}/></TableCell>
                        </TableRow>
                    ))
                ])(attrs)}
            </TableBody>
        </Table>
    )
}

function MedDetailHead({value, facility}) {
    const renderDate = attrVal => (
        <FacilityDate facility={facility} value={attrVal} />
    );
    const attrs = [
        {id: 'description', label: 'Description'},
        {id: 'generic', label: 'Medication'},
        {id: 'pnId', label: 'Medispan Product ID'},
        {id: 'ddId', label: 'Medispan Drug ID'},
        {id: 'rxNormId', label: 'UMLS RXNorm ID'},
        {id: 'directions', label: 'Directions'},
        {id: 'startDate', label: 'Start Date', render: renderDate},
        {id: 'endDate', label: 'End Date', render: renderDate},
        {id: 'discontinueDate', label: 'Discontinue Date', render: renderDate},
    ];
    return <AttributeTable value={value.attributes} attrs={attrs}/>
}

function MedDetailRoute({value}) {
    const coding = fp.get('attributes.administration.route.coding', value);
    return (
        <div>
            Administration: {fp.map(c => <Chip key={c.code} title={c.code} label={c.display}/>, coding)}
        </div>
    );
}

function LastAdministration({value, facility}) {
    const classes = useStyles();
    return (
        <div className={classes.panel}>
            Last Administration
            <AttributeTable value={value} attrs={[
                {id: 'administeredAmount', label: 'Amount'},
                {id: 'administeredBy', label: 'Administered by'},
                {
                    id: 'administeredDateTime', label: 'Administered at',
                    render: attrVal => <FacilityDateTime value={attrVal} facility={facility}/>
                },
                {id: 'codeDescription', label: 'Description'},
            ]}/>
        </div>
    )

}

function MedDetailSchedules({value, facility}) {
    const schedules = fp.get('attributes.schedules', value);
    return (
        <MaterialTable
            title="Schedule(s)"
            data={schedules}
            columns={[
                {title: 'Type', field: 'scheduleType'},
                {
                    title: 'Start', field: 'startDateTime',
                    render: rowData => <FacilityDateTime value={rowData.startDateTime} facility={facility}/>
                },
                {title: 'Directions', field: 'directions'},
                {title: 'Dose', render: rowData => `${rowData.dose} ${rowData.doseUOM}`},
                {title: 'Frequency', field: 'frequency'},
                {title: 'Indications', field: 'indicationsForUse'},

            ]}
            options={{
                pageSize: schedules.length,
                search: false,
                sorting: true,
                paging: false,
            }}
            detailPanel={[
                {
                    tooltip: 'Last administration',
                    render: rowData => <LastAdministration value={rowData.lastAdministration} facility={facility}/>
                },
            ]}
        />
    );
}

export default function MedicationDetail({value, facility}) {
    const classes = useStyles();
    return (
        <div className={classes.panel}>
            <MedDetailHead value={value} facility={facility}/>
            <MedDetailRoute value={value} />
            <MedDetailSchedules value={value} facility={facility}/>
        </div>
    )
}
