import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core';

import AppBar from './AppBar';
import NavDrawer from './NavDrawer';
import AutoLogout from './AutoLogout';
import Footer from 'components/Footer';

const useStyles = makeStyles(theme => ({
    root: {
        '& h1': {
            ...theme.typography.h1,
            margin: 0,
        },
        '& h2': {
            ...theme.typography.h2,
            margin: 0,
        },
        '& h3': theme.typography.h3,
        '& h4': {
            ...theme.typography.h4,
            margin: 0,
        },
        '& h5': theme.typography.h5,
        '& h6': theme.typography.h6,
        '& p': theme.typography.body1,
    },
    contentContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 800,
        padding: 16,
    },
    contentContainerWide: {
        padding: 16,
    },
    spacer: {
        height: theme.spacing(4),
    },
}));

export default function BasePage({
    title,
    subtitle,
    wide,
    classes = {},
    children,
}) {
    classes = {...useStyles(), ...classes};
    const [drawerOpen, setDrawerOpen] = useState(false);
    const contentContainerClass = wide
        ? classes.contentContainerWide
        : classes.contentContainer;
    let idleTimeout = 1000 * 60 * 15;
    return (
        <div className={classes.root}>
            <AutoLogout timeout={idleTimeout} />
            <NavDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
            <AppBar
                title={title}
                subtitle={subtitle}
                onToggleDrawer={() => setDrawerOpen(!drawerOpen)}
            />
            <div className={contentContainerClass}>
                {children}
                <div className={classes.spacer} />
                <Footer />
            </div>
        </div>
    );
}
