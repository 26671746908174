import React, {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as R from 'react-router-dom';
import fp from 'lodash/fp';

import {useApi, components} from '@arborian/narrf';
import {Avatar, Tooltip, Chip, makeStyles} from '@material-ui/core';
import {
    FilterList,
    Publish,
    Block,
    Assignment,
    AssignmentTurnedIn,
    AssignmentLate,
    Archive,
    Inbox,
} from '@material-ui/icons';

import {url_for} from '../routes';
import {useFeatures, useConstants} from 'lib/util';
import {useTicketActions} from 'components/ticket';

import {
    selectAvailableFacilitiesLookup,
    selectTicketOwnersLookup,
    selectHealthcareProvidersLookup,
    selectProgressNoteInitialValues,
} from 'lib/selectors';

const {
    DataTable,
    Column,
    Action,
    useLocationFetchOptions,
    updateLocationFetchOptions,
} = components;

const useStyles = makeStyles(theme => ({
    hbox: {
        display: 'flex',
        '& .MuiAvatar-root': {
            margin: theme.spacing(0, 0.25),
            width: theme.spacing(3),
            height: theme.spacing(3),
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1rem',
        },
    },
    chipMargin: {
        margin: '12px -10px',
    },
    pccGreen: {
        color: '#fff',
        backgroundColor: '#8EC428',
        '& .MuiChip-icon': {
            color: 'white',
        },
    },
    pfBlue: {
        color: '#fff',
        backgroundColor: '#00A3FB',
        '& .MuiChip-icon': {
            color: 'white',
        },
    },
    success: {
        backgroundColor: 'green',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

function TriState({
    value,
    trueClass,
    trueTitle,
    trueIcon,
    falseClass,
    falseTitle,
    falseIcon,
    defaultClass,
    defaultTitle,
    defaultIcon,
    ...props
}) {
    var title, className, TheIcon;
    if (value === true) {
        title = trueTitle;
        className = trueClass;
        TheIcon = trueIcon;
    } else if (value === false) {
        title = falseTitle;
        className = falseClass;
        TheIcon = falseIcon;
    } else {
        title = defaultTitle;
        className = defaultClass;
        TheIcon = defaultIcon;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className} {...props}>
                <TheIcon />
            </Avatar>
        </Tooltip>
    );
}

function BooleanState({
    value,
    trueClass,
    trueTitle,
    trueIcon,
    falseClass,
    falseTitle,
    falseIcon,
    ...props
}) {
    var title, className, TheIcon;
    if (value === true) {
        title = trueTitle;
        className = trueClass;
        TheIcon = trueIcon;
    } else {
        title = falseTitle;
        className = falseClass;
        TheIcon = falseIcon;
    }
    return (
        <Tooltip title={title}>
            <Avatar className={className} {...props}>
                <TheIcon />
            </Avatar>
        </Tooltip>
    );
}

function Status({rowData, ...props}) {
    const classes = useStyles();
    const features = useFeatures();
    return (
        <div className={classes.hbox}>
            <TriState
                trueTitle='Resolved'
                falseTitle='Pending'
                trueClass={classes.success}
                falseClass={classes.secondary}
                trueIcon={AssignmentTurnedIn}
                falseIcon={AssignmentLate}
                value={fp.get('data.attributes.status', rowData) === 'resolved'}
                {...props}
            />
            <TriState
                trueTitle='uploaded to Practice Fusion'
                falseTitle='not uploaded to Practice Fusion'
                trueClass={classes.pfBlue}
                trueIcon={Publish}
                falseIcon={Block}
                value={fp.get('data.attributes.uploaded_PF', rowData)}
                {...props}
            />
            {features.pcc_pdf_upload && (
                <TriState
                    trueClass={classes.pccGreen}
                    trueTitle='uploaded to PointClickCare'
                    falseTitle='not uploaded to PointClickCare'
                    trueIcon={Publish}
                    falseIcon={Block}
                    value={fp.get('data.attributes.uploaded_PCC', rowData)}
                    {...props}
                />
            )}
            {features.pcc_progress_note_upload && (
                <TriState
                    trueClass={classes.pccGreen}
                    trueTitle='progress note created'
                    falseTitle='progress note not created'
                    trueIcon={Assignment}
                    falseIcon={Block}
                    value={
                        null !==
                        fp.get('data.attributes.pcc_progressNoteId', rowData)
                    }
                    {...props}
                />
            )}
            <BooleanState
                trueTitle='archived'
                falseClass={classes.success}
                falseTitle='active'
                trueIcon={Archive}
                falseIcon={Inbox}
                value={fp.get('data.attributes.archived', rowData)}
                {...props}
            />
        </div>
    );
}

function StatusFilter({column, filter, onChangeFilter}) {
    const classes = useStyles();
    const features = useFeatures();
    const yes = {$ne: null};
    const no = {$eq: null};
    const fields = {
        status: {
            field: 'attributes.status',
            options: ['resolved', 'pending'],
        },
        pf: {
            field: 'attributes.uploaded_PF',
            options: [true, false],
        },
        pcc: {
            field: 'attributes.uploaded_PCC',
            options: [true, false],
        },
        pcc_pn: {
            field: 'attributes.pcc_progressNoteId',
            options: [yes, no],
        },
    };
    fp.forEach(f => {
        const curValue = fp.get(f.field, filter);
        const curIndex = fp.findIndex(fp.isEqual(curValue), f.options);
        f.value = [true, false][curIndex];
    }, fields);

    const handleClick = f => ev => {
        const curIndex = fp.indexOf(f.value, [true, false]);
        const newIndex = curIndex + 1;
        const newValue = f.options[newIndex];
        onChangeFilter(f.field, newValue);
    };

    const toggleArchived = () => {
        const curValue = fp.get('attributes.archived', filter);
        onChangeFilter('attributes.archived', !curValue);
    };
    // console.log('Fields are', fields);

    return (
        <div className={classes.hbox}>
            <TriState
                trueTitle='Resolved'
                falseTitle='Pending'
                defaultTitle='filter on ticket status'
                trueClass={classes.success}
                falseClass={classes.secondary}
                trueIcon={AssignmentTurnedIn}
                falseIcon={AssignmentLate}
                defaultIcon={FilterList}
                value={fields.status.value}
                onClick={handleClick(fields.status)}
            />
            <TriState
                trueClass={classes.pfBlue}
                trueTitle='uploaded to Practice Fusion'
                falseTitle='not uploaded to Practice Fusion'
                defaultTitle='filter on uploaded to Practice Fusion'
                trueIcon={Publish}
                falseIcon={Block}
                defaultIcon={FilterList}
                value={fields.pf.value}
                onClick={handleClick(fields.pf)}
            />
            {features.pcc_pdf_upload && (
                <TriState
                    trueClass={classes.pccGreen}
                    trueTitle='uploaded to PointClickCare'
                    falseTitle='not uploaded to PointClickCare'
                    defaultTitle='filter on uploaded to PointClickCare'
                    trueIcon={Publish}
                    falseIcon={Block}
                    defaultIcon={FilterList}
                    value={fields.pcc.value}
                    onClick={handleClick(fields.pcc)}
                />
            )}
            {features.pcc_progress_note_upload && (
                <TriState
                    trueClass={classes.pccGreen}
                    trueTitle='progress note created'
                    falseTitle='progress note not created'
                    defaultTitle='filter on progress note created'
                    trueIcon={Assignment}
                    falseIcon={Block}
                    defaultIcon={FilterList}
                    value={fields.pcc_pn.value}
                    onClick={handleClick(fields.pcc_pn)}
                />
            )}
            <BooleanState
                trueTitle='archived'
                falseClass={classes.success}
                falseTitle='active'
                trueIcon={Archive}
                falseIcon={Inbox}
                value={fp.get('attributes.archived', filter)}
                onClick={toggleArchived}
            />
        </div>
    );
}

export default function TicketsTable({facilities, owners, providers}) {
    const api = useApi();
    const features = useFeatures();
    const dispatch = useDispatch();
    const tableRef = useRef();
    const ticketActions = useTicketActions();

    const lookupProviders = useSelector(selectHealthcareProvidersLookup);
    const lookupOwners = useSelector(selectTicketOwnersLookup);
    const lookupFacilities = useSelector(selectAvailableFacilitiesLookup);

    const refresh = useCallback(() => tableRef.current.fetch(), [tableRef]);

    const ticketTypes = fp.get('ticketTypes', useConstants());

    const handleBulkArchive = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets archived successfully',
            error_message: 'Some tickets failed to archive',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (row.data.attributes.archived) {
                    batch.error(`Ticket ${row.data.id} already archived`);
                    return false;
                }
                if (row.data.attributes.status !== 'resolved') {
                    batch.error(
                        `Ticket ${row.data.id} cannot be archived while unresolved`,
                    );
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.archive(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkUnarchive = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets un-archived successfully',
            error_message: 'Some tickets failed to un-archive',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (!row.data.attributes.orders) {
                    batch.error(`Ticket ${row.data.id} is already active`);
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.unarchive(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkUploadPF = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets uploaded successfully',
            error_message: 'Some tickets failed to upload',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (!row.data.attributes.orders) {
                    batch.error(`Ticket ${row.data.id} has no orders`);
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.uploadPF(row.data)),
        ])(rows);
        await Promise.all(promises);
        await batch.flush();
        refresh();
    };

    const handleBulkUploadPCC = async rows => {
        const batch = ticketActions.batch({
            success_message: 'Tickets uploaded successfully',
            error_message: 'Some tickets failed to upload',
        });
        const promises = fp.pipe([
            fp.filter(row => {
                if (!row.data.attributes.orders) {
                    batch.error(`Ticket ${row.data.id} has no orders`);
                    return false;
                }
                const isPCCTicket = fp.pipe([
                    fp.get('attributes.patient_identifier'),
                    fp.find(
                        ident =>
                            ident.system === 'http://www.pointclickcare.com',
                    ),
                ])(row.data);
                if (!isPCCTicket) {
                    batch.error(`Ticket ${row.data.id} is not a PCC ticket`);
                    return false;
                }
                return true;
            }),
            fp.map(row => batch.uploadPCC(row.data)),
        ])(rows);
        await Promise.all(promises);
        batch.flush();
        refresh();
    };

    // Needs to be an action so we can get the state
    const handleBulkCreateProgressNote = rows => async (dispatch, getState) => {
        const batch = ticketActions.batch({
            success_message: 'Progress notes created',
            error_message: 'Some progress notes failed',
        });
        let errors = [];
        rows = fp.filter(row => {
            if (!row.data.attributes.orders) {
                errors.push(batch.error(`Ticket ${row.data.id} has no orders`));
                return false;
            }
            const isPCCTicket = fp.pipe([
                fp.get('attributes.patient_identifier'),
                fp.find(
                    ident => ident.system === 'http://www.pointclickcare.com',
                ),
            ])(row.data);
            if (!isPCCTicket) {
                errors.push(
                    batch.error(`Ticket ${row.data.id} is not a PCC ticket`),
                );
                return false;
            }
            return true;
        }, rows);
        if (errors.length) {
            await Promise.all(errors);
        }
        if (!rows.length) {
            batch.flush();
            return;
        }

        // Re-fetch the tickets we're uploading along with the info we need to
        // create progress notes; we MUST fetch the progress note types for the selectProgressNoteInitialValues
        // below to work properly.
        const ticket_ids = fp.map(row => ({$oid: row.data.id}), rows);
        await api.fetchJsonApi(api.url_for('ticket.collection'), {
            filter: {id: JSON.stringify({$in: ticket_ids})},
            include: [
                'facility',
                'narrative',
                'pcc_facility',
                'pcc_facility',
                'pcc_facility.progressNoteTypes',
            ],
        });

        await Promise.all(
            fp.map(row => {
                const state = getState();
                const progressNote = selectProgressNoteInitialValues(
                    row.data.id,
                )(state);
                return batch.createPCCProgressNote(row.data, progressNote);
            }, rows),
        );
        batch.flush();
        refresh();
    };

    const fetchTickets = useCallback(
        fetchOptions => {
            return api.fetchDataTable(
                api.url_for('ticket.collection'),
                fetchOptions,
            );
        },
        [api],
    );

    const history = R.useHistory();
    const defaultFetchOptions = {
        filter: {'attributes.archived': false},
        sort: {field: 'attributes.created', direction: 'desc'},
        page: {size: 20},
    };
    const fetchOptions = useLocationFetchOptions(defaultFetchOptions, 'table.');
    const onChangeFetchOptions = o => {
        const newParams = updateLocationFetchOptions(o, 'table.');
        history.push('?' + newParams.toString());
    };

    return (
        <DataTable
            title='Tickets'
            size='small'
            data={fetchTickets}
            tableRef={tableRef}
            fetchOptions={fetchOptions}
            onChangeFetchOptions={onChangeFetchOptions}
            options={{
                filtering: true,
                selection: true,
                search: true,
            }}
        >
            <Column
                title='Ticket ID'
                field='id'
                filtering={false}
                sorting={false}
                render={row => (
                    <Tooltip title={row.id}>
                        <R.Link
                            to={url_for('ticketDetails', {
                                ticketid: row.id,
                            })}
                        >
                            {fp.truncate({length: 10}, row.id)}
                        </R.Link>
                    </Tooltip>
                )}
            />
            <Column
                title='Submitted on'
                field='attributes.created'
                type='date'
            />
            <Column title='Submitted by' field='attributes.submitter_name' />
            <Column title='Patient First' field='attributes.patient_first' />
            <Column title='Patient Last' field='attributes.patient_last' />
            <Column
                title='Facility'
                field='attributes.facility_id'
                lookup={lookupFacilities}
            />
            <Column
                title='Ticket Type'
                field='attributes.ticket_type'
                lookup={ticketTypes}
            />
            <Column
                title='Owner'
                field='attributes.owner_id'
                lookup={lookupOwners}
            />
            <Column
                title='Provider'
                field='attributes.provider_id'
                lookup={lookupProviders}
            />
            <Column
                title='Status'
                render={rowData => <Status rowData={rowData} />}
                FilterComponent={StatusFilter}
                sorting={false}
            />
            <Action
                name='upload-pf'
                tooltip='Upload to PracticeFusion'
                icon={
                    <Chip
                        icon={<Publish />}
                        label='Upload to PracticeFusion'
                        color='secondary'
                    />
                }
                onClick={(evt, row) => handleBulkUploadPF(row)}
            />
            <Action
                name='upload-pcc'
                tooltip='Upload to PointClickCare'
                hidden={!features.pcc_pdf_upload}
                icon={
                    <Chip
                        icon={<Publish />}
                        label='Upload to PointClickCare'
                        color='secondary'
                    />
                }
                onClick={(evt, row) => handleBulkUploadPCC(row)}
            />
            <Action
                name='pn-pcc'
                hidden={!features.pcc_progress_note_upload}
                tooltip='Create PointClickCare Progress Note(s)'
                icon={
                    <Chip
                        icon={<Assignment />}
                        label='Create Progress Notes'
                        color='secondary'
                    />
                }
                onClick={(evt, row) =>
                    dispatch(handleBulkCreateProgressNote(row))
                }
            />
            <Action
                name='unarchive'
                tooltip='Un-archive ticket(s)'
                icon='unarchive'
                onClick={(evt, row) => handleBulkUnarchive(row)}
            />
            <Action
                name='archive'
                tooltip='Archive ticket(s)'
                icon='archive'
                onClick={(evt, row) => handleBulkArchive(row)}
            />
            <Action
                name='refresh'
                free
                onClick={refresh}
                tooltip='Refresh'
                icon='refresh'
            />
        </DataTable>
    );
}
