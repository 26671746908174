import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AppBar,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    Chip,
    Paper,
    Tabs,
    Tab,
    TextField,
    makeStyles,
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {ExpandMore, Error} from '@material-ui/icons';

import {ducks} from '@arborian/narrf';

import TabPanel from 'components/TabPanel';

import {useTicketActions} from './ticketActions';
import TicketMenu from './TicketMenu';
import Attachment from './Attachment';
import Orders from './Orders';
import Resolve from './Resolve';
import Reopen from './Reopen';
import Stavatar from './Stavatar';
import PCCPatientBrowser from './PCCPatientBrowser';
import TicketFormData from './TicketFormData';
import TicketHistoryTable from './TicketHistoryTable';

import {
    selectAvailableFacilitiesLookup,
    selectTicketOwnersLookup,
    selectHealthcareProvidersLookup,
} from 'lib/selectors';

const useStyles = makeStyles(theme => ({
    ticket: {
        '& .MuiCardActions-root': {
            padding: theme.spacing(0, 2, 2, 2),
        },
    },
    chipList: {
        '& .MuiChip-root': {
            margin: theme.spacing(0, 0.5, 0.5, 0.5),
        },
    },
    success: {
        backgroundColor: 'green',
    },
    pending: {
        backgroundColor: theme.palette.secondary.main,
    },
    textArea: {
        whiteSpace: 'pre-wrap',
        textAlign: 'left',
        width: '100%',
        padding: 25,
        paddingTop: 20,
        border: 'none',
    },
    accordionContent: {
        width: '100%',
    },
    ticketActions: {
        justifyContent: 'flex-end',
    },
}));

function MenuButton({label, value, onChange, lookup}) {
    return (
        <Autocomplete
            fullWidth
            autoComplete
            autoHighlight
            options={fp.keys(lookup)}
            value={value}
            onChange={onChange}
            getOptionLabel={o => lookup[o]}
            renderInput={params => (
                <TextField autoFocus label={label} {...params} />
            )}
        />
    );
}

function TicketMetadata({ticket, actions}) {
    const classes = useStyles();
    const facilities = useSelector(selectAvailableFacilitiesLookup);
    const owners = useSelector(selectTicketOwnersLookup);
    const providers = useSelector(selectHealthcareProvidersLookup);

    const facility_label = facilities[fp.get('attributes.facility_id', ticket)];
    const owner_label = owners[fp.get('attributes.owner_id', ticket)];
    const provider_label = providers[fp.get('attributes.provider_id', ticket)];

    const handleChange = field => async (ev, value) => {
        actions.patch(ticket, {[field]: value});
    };

    const handleChangeFacility = async (ev, facility_id) => {
        actions.patch(ticket, {
            facility_id,
            facility_label: facilities[facility_id],
        });
    };
    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                Facility: {facility_label} | Owner: {owner_label} | Provider:{' '}
                {provider_label}
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.accordionContent}>
                    <MenuButton
                        label='Facility'
                        value={fp.get('attributes.facility_id', ticket)}
                        onChange={handleChangeFacility}
                        lookup={facilities}
                    />
                    <MenuButton
                        label='Owner'
                        value={fp.get('attributes.owner_id', ticket)}
                        onChange={handleChange('owner_id')}
                        lookup={owners}
                    />
                    <MenuButton
                        label='Provider'
                        value={fp.get('attributes.provider_id', ticket)}
                        onChange={handleChange('provider_id')}
                        lookup={providers}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function TicketMainPanel({ticket}) {
    const classes = useStyles();
    const narrative = useSelector(
        ducks.jsonapi.selectObject(['TicketNarrative', ticket.id]),
    );
    const attachments = fp.getOr([], 'attributes.session_data.image', ticket);
    return (
        <Paper>
            {fp.map(
                att => (
                    <Attachment key={att.data.id} value={att.data} />
                ),
                attachments,
            )}

            <div className={classes.textArea}>
                {fp.get('attributes.narrative', narrative)}
            </div>
        </Paper>
    );
}

function TicketTags({ticket}) {
    const classes = useStyles();
    return (
        <div className={classes.chipList}>
            {fp.pipe([
                fp.get('attributes.patient_identifier'),
                fp.map(ident =>
                    ident.value === 'unknown' ? (
                        <Chip
                            key={ident.system}
                            label={ident.label}
                            color='secondary'
                            icon={<Error />}
                        />
                    ) : (
                        <Chip key={ident.system} label={ident.label} />
                    ),
                ),
            ])(ticket)}
        </div>
    );
}

export default function Ticket({ticket, include, onChanged}) {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState('main');
    const [attemptedPCCRefresh, setAttemptedPCCRefresh] = useState(0);
    const actions = useTicketActions(include);
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    const showPCC = fp.pipe([
        fp.get('attributes.patient_identifier'),
        fp.find(ident => ident.system === 'http://www.pointclickcare.com'),
    ])(ticket);
    const hasPCCSnapshots = fp.get('attributes.hasPCCSnapshots', ticket);
    useEffect(() => {
        if (
            ticket &&
            showPCC &&
            !hasPCCSnapshots &&
            attemptedPCCRefresh === 0
        ) {
            setAttemptedPCCRefresh(attemptedPCCRefresh + 1);
            actions.updatePCC(ticket);
        }
    }, [
        ticket,
        showPCC,
        hasPCCSnapshots,
        actions,
        attemptedPCCRefresh,
        setAttemptedPCCRefresh,
    ]);

    const title = `
        ${fp.get('attributes.patient_last', ticket)},
        ${fp.get('attributes.patient_first', ticket)}
    `;
    const subheader = `Ticket status: ${fp.get('attributes.status', ticket)}`;
    const ticketStatus = fp.getOr('pending', 'attributes.status', ticket);
    const isFacAdmin = fp.includes('facility_admin', userinfo.scopes);
    if (isFacAdmin) {
        return (
            <Card className={classes.ticket}>
                <CardHeader
                    title={title}
                    subheader={subheader}
                    avatar={<Stavatar ticket={ticket} classes={classes} />}
                />
                <CardContent>
                    <TicketMainPanel ticket={ticket} />
                </CardContent>
            </Card>
        );
    }

    return (
        <Card className={classes.ticket}>
            <CardHeader
                title={title}
                subheader={subheader}
                avatar={<Stavatar ticket={ticket} classes={classes} />}
                action={
                    !isFacAdmin && (
                        <TicketMenu ticket={ticket} actions={actions} />
                    )
                }
            />
            <CardContent>
                <TicketTags ticket={ticket} />
                <TicketMetadata ticket={ticket} actions={actions} />
                <AppBar position='relative'>
                    <Tabs
                        variant='fullWidth'
                        value={tabValue}
                        onChange={(ev, newValue) => setTabValue(newValue)}
                    >
                        <Tab label='Main' value='main' />
                        {showPCC && <Tab label='PointClickCare' value='pcc' />}
                        <Tab label='Form data' value='form' />
                        <Tab label='Ticket activity' value='history' />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index='main'>
                    <TicketMainPanel ticket={ticket} />
                </TabPanel>
                <TabPanel value={tabValue} index='pcc'>
                    <PCCPatientBrowser
                        ticket={ticket}
                        actions={actions}
                        onChanged={onChanged}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index='form'>
                    <TicketFormData
                        ticket={ticket}
                        actions={actions}
                        onChanged={onChanged}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index='history'>
                    <TicketHistoryTable
                        ticket={ticket}
                        actions={actions}
                        onChanged={onChanged}
                    />
                </TabPanel>
            </CardContent>

            <CardActions className={classes.ticketActions}>
                {ticketStatus === 'pending' ? (
                    <>
                        <Orders ticket={ticket} actions={actions} />
                        <Resolve ticket={ticket} actions={actions} />
                    </>
                ) : (
                    <Reopen ticket={ticket} actions={actions} />
                )}
            </CardActions>
        </Card>
    );
}
