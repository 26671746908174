import React from 'react';

import PCCPatientDataTable from './PCCPatientDataTable';
import {FacilityDate} from './FacilityDates';


export default function ConditionsTable({patient, facility}) {
    if(!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='conditions'
            columns={[
                { title: 'Rank', field: 'data.attributes.rankDescription' },
                { title: 'Status', field: 'data.attributes.clinicalStatus' },
                { title: 'Description', render: rd => `${rd.data.attributes.icd10}: ${rd.data.attributes.icd10Description}`},
                { title: 'Created By', field: 'data.attributes.createdBy' },
                {
                    title: 'Onset', field: 'data.attributes.onsetDate', type: 'datetime',
                    render: rd => <FacilityDate value={rd.data.attributes.onsetDate} facility={facility}/>
                },
                { title: 'Principal Diagnosis?', field: 'data.attributes.principalDiagnosis' },
                { title: 'Therapy?', field: 'data.attributes.therapy' },
            ]}
        />
    )
}
