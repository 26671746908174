import React, {useState, useEffect} from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    TextField as MUITextField,
} from '@material-ui/core';
import {Formik, Form, Field} from 'formik';
import {TextField} from 'formik-material-ui';
import {Autocomplete} from 'formik-material-ui-lab';

import {useApi, ducks} from '@arborian/narrf';

import {selectProgressNoteInitialValues} from 'lib/selectors';

export default function PCCProgressNoteDialog({dialogRef}) {
    const api = useApi();
    const [ticketId, setTicketId] = useState();
    const [resolve, setResolve] = useState(null);
    const initialValues = useSelector(
        selectProgressNoteInitialValues(ticketId),
    );

    const ticket = useSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketId]),
    );
    const relTicket = useSelector(ducks.jsonapi.selectRelated(ticket));
    const relPccFac = useSelector(
        ducks.jsonapi.selectRelated(relTicket.pcc_facility),
    );
    const progressNoteTypes = fp.get('progressNoteTypes', relPccFac) || [];
    useEffect(() => {
        const url = fp.get('relationships.pcc_facility.links.related', ticket);
        if (url) {
            api.fetchJsonApi(url, {include: ['progressNoteTypes']});
        }
    }, [api, ticket]);

    const handleSubmit = values => {
        setResolve(null);
        resolve && resolve(values);
    };

    const handleCancel = () => {
        setResolve(null);
        resolve && resolve(null);
    };

    dialogRef.current = ticketId => {
        return new Promise((resolve, reject) => {
            setTicketId(ticketId);
            setResolve(() => resolve);
        });
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form>
                    <Dialog
                        open={!!resolve}
                        onClose={handleCancel}
                        maxWidth='lg'
                        fullWidth
                        scroll='paper'
                    >
                        <DialogContent dividers>
                            <Field
                                fullWidth
                                name='progressNoteType'
                                component={Autocomplete}
                                options={progressNoteTypes}
                                getOptionLabel={fp.get('attributes.noteType')}
                                renderInput={params => (
                                    <MUITextField
                                        label='Progress Note Type'
                                        error={
                                            formikProps.touched[
                                                'progressNoteType'
                                            ] &&
                                            !!formikProps.errors[
                                                'progressNoteType'
                                            ]
                                        }
                                        helperText={
                                            formikProps.errors[
                                                'progressNoteType'
                                            ]
                                        }
                                        {...params}
                                    />
                                )}
                            />
                            {fp.map(
                                s => (
                                    <Field
                                        key={s}
                                        multiline
                                        fullWidth
                                        name={s}
                                        label={s}
                                        component={TextField}
                                    />
                                ),
                                fp.get(
                                    'progressNoteType.attributes.sections',
                                    formikProps.values,
                                ),
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={formikProps.handleSubmit}
                            >
                                Create Progress Note
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
}
