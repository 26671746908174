import React from 'react';

import { makeStyles, Paper } from '@material-ui/core';

import BasePage from 'components/BasePage';


const useStyles = makeStyles(theme => ({
    body: {
        '& h3': {
            textAlign: 'center',
            margin: 0,
        },
        maxWidth: 800,
        padding: theme.spacing(2),
    },
    logo: {
        width: '100%',
        maxWidth: 800,
    },
}));

export default function HomePage() {
    const classes = useStyles();

    return (
        <>
            <BasePage title="About">
                <Paper className={classes.body}>
                    <img className={classes.logo} src="/img/tcpa.png" alt="TCPA logo" />
                    <h3>TCPA Connect</h3>
                    <p>
                        TCPA Connect is an application which allows patients, families, and staff to communicate
                        with their physicians. In particular, TPCA connect allows users to alert physicians to changes
                        in patient status, request prescription, and other activities.
                    </p>
                    <p>
                        TCPA Connect also provides a healthcare provider-facing interface which allows physicians and
                        other TCPA staff to respond to communications. Since this interface deals with protected health
                        information, providers must login to the system to access this functionality. Additionally,
                        some functionality for sending communications to TCPA requires login.
                    </p>
                    <p>
                        TCPA Connect provides multiple options for registration and login, including username and password,
                        direct login using PointClickCare, or login with a Google account. If using the PointClickCare or Google
                        login options, TCPA connect will retrieve the email address and name of the account holder to customize
                        the dispay of information in the application.
                    </p>
                </Paper>
            </BasePage>
        </>
    )
}
