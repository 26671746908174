import React, {useState} from 'react';

import {Dialog} from '@material-ui/core';

import {useStorage} from 'lib/util';
import PracticeFusionLogin from 'components/PracticeFusionLogin';
import {useApi} from '@arborian/narrf';

export default function PracticeFusionLoginDialog({dialogRef}) {
    const api = useApi();
    const [open, setOpen] = useState(false);
    const [resolve, setResolve] = useState(null);
    const [token, setToken] = useStorage(
        sessionStorage,
        'practiceFusion.sessionToken',
        null,
    );

    const handleSubmit = token => {
        setToken(token);
        resolve(token);
        setOpen(false);
    };

    const handleCancel = () => {
        resolve(null);
        setOpen(false);
    };

    dialogRef.current = async () => {
        if (token) {
            try {
                console.log('Validate token');
                await api.fetchJson(api.url_for('pf.validate_token'), {
                    headers: {'x-pf-token': token},
                });
                console.log('Token is valid and', token);
                return token;
            } catch (err) {
                console.log('token invalid', err);
            }
        }
        const promise = new Promise((resolve, reject) => {
            setResolve(() => resolve);
            setOpen(true);
        });
        return await promise;
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <PracticeFusionLogin onSubmit={handleSubmit} />
        </Dialog>
    );
}
