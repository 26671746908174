import React, {useState, useEffect} from 'react';
import {Tabs, Tab, Paper, makeStyles} from '@material-ui/core';
import {RecentActors, PriorityHigh} from '@material-ui/icons';
import {Alert, AlertTitle} from '@material-ui/lab';

import BasePage from 'components/BasePage';
import RuleList from 'components/RuleList';
import RuleEditor from 'components/RuleEditor';
import {useApi} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    panel: {
        margin: theme.spacing(0, 0, 1, 0),
        '& p': {
            padding: theme.spacing(1),
        },
    },
}));

function RuleListPanel({onSelect, onDeleted}) {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const handleChange = (ev, val) => {
        onSelect(null);
        setValue(val);
    };
    return (
        <Paper className={classes.panel}>
            <Tabs value={value} onChange={handleChange}>
                <Tab icon={<RecentActors />} label='Normal Rules' />
                <Tab icon={<PriorityHigh />} label='Escalation Rules' />
            </Tabs>
            {value === 0 ? (
                <div>
                    <Alert severity='info'>
                        <AlertTitle>
                            These rules define how new tickets are routed.
                        </AlertTitle>
                        Rules are evaluated from top to bottom until a matching
                        assignment is found.
                    </Alert>
                    <RuleList
                        onSelect={onSelect}
                        onDeleted={onDeleted}
                        escalation={false}
                        include={['shifts']}
                    />
                </div>
            ) : (
                <div>
                    <Alert severity='info'>
                        <AlertTitle>
                            These rules define how tickets are reassigned due to
                            inactivity.
                        </AlertTitle>
                        Rules are evaluated from top to bottom until a matching
                        assignment is found.
                    </Alert>
                    <RuleList
                        onSelect={onSelect}
                        onDeleted={onDeleted}
                        escalation={true}
                        include={['shifts']}
                    />
                </div>
            )}
        </Paper>
    );
}

export default function SchedulePage() {
    const api = useApi();
    const [rule, setRule] = useState();

    const [init, setInit] = useState(false);
    useEffect(() => {
        if (!init) {
            setInit(true);
            api.fetchAllJsonApi(api.url_for('facility.collection'));
            api.fetchAllJsonApi(api.url_for('facility.routing'));
            api.fetchAllJsonApi(api.url_for('auth.users'));
            api.fetchAllJsonApi(api.url_for('auth.roles'));
            api.fetchAllJsonApi(api.url_for('schedule.assignments'));
            api.fetchAllJsonApi(api.url_for('schedule.rules'), {
                include: ['shifts'],
            });
        }
    }, [api, init, setInit]);

    const handleDeleted = deletedRule => {
        if (rule === deletedRule) {
            setRule(null);
        }
    };

    return (
        <BasePage wide>
            <RuleListPanel onSelect={setRule} onDeleted={handleDeleted} />
            {rule && <RuleEditor rule={rule} />}
        </BasePage>
    );
}
