import {useState, useCallback, useEffect} from 'react';
import fp from 'lodash/fp';
import moment from 'moment';

import {useApi} from '@arborian/narrf';

const GLOBALS = {
    features: null,
    constants: null,
};
const EMPTY = {};

export const strWithNewline = s => {
    if (!s.length || fp.endsWith('\n', s)) return s;
    return s + '\n';
};

export const debounce = f => {
    let cur = null;
    return async (...args) => {
        if (cur) {
            let result = await cur;
            return result;
        } else {
            cur = f(...args);
            try {
                return await cur;
            } finally {
                cur = null;
            }
        }
    };
};

const useConstant = (name, link = null) => {
    const api = useApi();
    let value = fp.get(name, GLOBALS);
    if (!link) {
        link = `${name}.root`;
    }
    useEffect(() => {
        if (!api.hasDirectory) {
            return;
        }
        const href = api.url_for(link);
        if (value === null) {
            api.fetchJson(href).then(result => {
                GLOBALS[name] = result;
            });
        }
    }, [api, link, name, value]);
    return value || EMPTY;
};

export const useFeatures = () => useConstant('features');
export const useConstants = () => useConstant('constants');

export const useAsyncMemo = (f, deps = [], defaultValue = undefined) => {
    const [value, setValue] = useState(defaultValue);
    useEffect(
        () => {
            f().then(setValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    );
    return value;
};

export const useStorage = (storage, key, initialValue) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const svalue = storage.getItem(key);
            return svalue ? JSON.parse(svalue) : initialValue;
        } catch (error) {
            console.error('Error loading storage', error);
            return initialValue;
        }
    });

    const setValue = useCallback(
        value => {
            try {
                const valueToStore =
                    value instanceof Function ? value(storedValue) : value;
                setStoredValue(valueToStore);
                storage.setItem(key, JSON.stringify(valueToStore));
            } catch (error) {
                console.error('Error saving storage', error);
            }
        },
        [storage, key, storedValue, setStoredValue],
    );
    return [storedValue, setValue];
};

export const resolveRelationships = resp => {
    // Index the included data by type + id
    let includedIndex = {};
    fp.pipe([
        fp.getOr([], 'included'),
        fp.forEach(it => {
            includedIndex = fp.set(`${it.type}.${it.id}`, it, includedIndex);
        }),
    ])(resp);

    // Helper function to look up included items by type/id
    const lookup = ({type, id}) => fp.get(`${type}.${id}`, includedIndex);

    // materialize relationships
    const result = fp.pipe([
        fp.getOr({}, 'data.relationships'),
        fp.toPairs,
        fp.map(([rname, rel]) => [rname, rel.data]),
        fp.filter(([rname, rdata]) => !!rdata),
        fp.map(([rname, rdata]) => {
            const result = fp.isArray(rdata)
                ? fp.map(lookup, rdata)
                : lookup(rdata);
            return [rname, result];
        }),
        fp.fromPairs,
    ])(resp);
    return result;
};

export const toPhoneNumber = str => {
    let val = str.replace(/\D/g, '');
    return val.slice(0, 10);
};

export const strParseDate = date => {
    let val = moment(date, 'MM/DD/YYYY');
    if (val.isValid()) {
        console.log({date});
        return val.toDate();
    }
    return null;
};

export const strFormatDate = date => {
    const valid = moment(date);
    return valid.format('MM/DD/YYYY');
};

export const logError = async (error, level = 40) => {
    console.error(error);
    let errData = {
        LOGLEVEL: level,
        message: error.message,
        name: error.name,
        stack: error.stack.toString(),
    };
    if (fp.get('response', error)) {
        try {
            errData.json = await error.json();
            errData.text = await error.text();
        } catch (e) {
            console.error('Error loading json/text for logging', e, error);
        }
        errData = {
            status: error.response.status,
            url: error.response.url,
            ...errData,
        };
    }
    fetch(process.env.REACT_APP_API_ROOT + '/log', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(errData),
    });
};
