import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import {useApi} from '@arborian/narrf';
import {url_for} from 'routes';
import BasePage from 'components/BasePage';

export default function LogoutRedirectPage() {
    const api = useApi();
    const history = useHistory();

    useEffect(() => {
        api.logout().then(
            () => history.push(url_for('home'))
        ).catch(e => {
            console.error('Error in autologout', e);
            history.push(url_for('home'));
        })
    }, [api, history]);
    return <BasePage title="Logging you out..."/>
}