import React from 'react';
import fp from 'lodash/fp';

import PCCPatientDataTable from './PCCPatientDataTable';
import {FacilityDateTime} from './FacilityDates';


const renderValue = rowData => {
    let parts = [];
    let value = fp.get('data.attributes.value', rowData);
    let systolicValue = fp.get('data.attributes.systolicValue', rowData);
    let diastolicValue = fp.get('data.attributes.diastolicValue', rowData);
    let unit = fp.get('data.attributes.unit', rowData);

    if(value) {
        parts.push(value);
    }
    if(systolicValue) {
        parts.push(systolicValue);
        parts.push('/');
        parts.push(diastolicValue);
    }
    if(unit) {
        parts.push(unit);
    }
    return <>{parts.join(' ')}</>
}

function Recorded({value, facility}) {
    const recordedBy = fp.get('data.attributes.recordedBy', value);
    const recordedDate = fp.get('data.attributes.recordedDate', value);
    return (
        <>
            by {recordedBy} at <FacilityDateTime value={recordedDate} facility={facility}/>
        </>
    );
}

export default function VitalsTable({patient, facility}) {
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='vitals'
            columns={[
                { title: 'Type', field: 'data.attributes.type' },
                { title: 'Value', field: 'data.attributes.value', render: renderValue },
                { title: 'Method', field: 'data.attributes.method' },
                {
                    title: 'Recorded', field: 'data.attributes.recordedDate', type: 'datetime',
                    render: rowData => <Recorded value={rowData} facility={facility}/>
                },
            ]}
        />
    );
}
