import React from 'react';
import fp from 'lodash/fp';
import {useSelector} from 'react-redux';
import * as R from 'react-router-dom';

import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import * as I from '@material-ui/icons';

import {ducks} from '@arborian/narrf';
import {url_for} from '../routes';

function ExtraItems() {
    const userinfo = useSelector(ducks.auth.selectUserinfo);
    if (!userinfo) return null;
    const isStaff = fp.includes('staff', userinfo.scopes);
    const isAdmin = fp.includes('__admin__', userinfo.scopes);
    const isFacAdmin = fp.includes('facility_admin', userinfo.scopes);
    const isFacStaff = fp.includes('facility_staff', userinfo.scopes);
    if (!isStaff && !isAdmin && (isFacAdmin || isFacStaff)) {
        return (
            <>
                {isFacAdmin && (
                    <ListItem button component={R.Link} to={url_for('tickets')}>
                        <ListItemIcon>
                            <I.Dashboard />
                        </ListItemIcon>
                        <ListItemText primary='Dashboard' />
                    </ListItem>
                )}
                <ListItem button component={R.Link} to={url_for('adminFacilities')}>
                    <ListItemIcon>
                        <I.Apartment />
                    </ListItemIcon>
                    <ListItemText primary='Manage Facilities' />
                </ListItem>
            </>
        );
    }
    if (!isStaff && !isAdmin) return null;
    return (
        <>
            <ListItem button component={R.Link} to={url_for('tickets')}>
                <ListItemIcon>
                    <I.Dashboard />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('practiceFusion')}>
                <ListItemIcon>
                    <I.Android />
                </ListItemIcon>
                <ListItemText primary='PracticeFusion Bot' />
            </ListItem>
            <ListItem
                button
                component={R.Link}
                to={url_for('adminFaxDirectory')}
            >
                <ListItemIcon>
                    <I.Print />
                </ListItemIcon>
                <ListItemText primary='Fax Directory' />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('orders')}>
                <ListItemIcon>
                    <I.List />
                </ListItemIcon>
                <ListItemText primary='Order Templates' />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('adminFacilities')}>
                <ListItemIcon>
                    <I.Apartment />
                </ListItemIcon>
                <ListItemText primary='Manage Facilities' />
            </ListItem>
            <ListItem button component={R.Link} to={url_for('adminSchedule')}>
                <ListItemIcon>
                    <I.DateRange />
                </ListItemIcon>
                <ListItemText primary='Manage Routing' />
            </ListItem>
            {isAdmin && (
                <>
                    <ListItem
                        button
                        component={R.Link}
                        to={url_for('adminPermissions')}
                    >
                        <ListItemIcon>
                            <I.SupervisorAccount />
                        </ListItemIcon>
                        <ListItemText primary='Manage Users' />
                    </ListItem>
                    <ListItem
                        button
                        component={R.Link}
                        to={url_for('adminTools')}
                    >
                        <ListItemIcon>
                            <I.Assessment />
                        </ListItemIcon>
                        <ListItemText primary='Admin Tools' />
                    </ListItem>
                </>
            )}
        </>
    );
}

export default function NavDrawer({open, onClose}) {
    return (
        <Drawer open={open} onClose={onClose}>
            <List>
                <ListItem button component={R.Link} to={url_for('home')}>
                    <ListItemIcon>
                        <I.Home />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                </ListItem>
                <ExtraItems />
            </List>
        </Drawer>
    );
}
