import React, {useState} from 'react';

import {Dialog, DialogContent, DialogActions} from '@material-ui/core';
import {makeStyles, TextField, Button} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    /*
    root: {
        '& form': {
            padding: theme.spacing(2),
        },
    },
    */
}));

function PracticeFusionCredentials({onCancel, onSubmit}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleSubmit = ev => {
        ev.preventDefault();
        onSubmit &&
            onSubmit({loginEmailAddress: username, password, phoneNumber});
    };

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextField
                    fullWidth
                    label='PracticeFusion Username'
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField
                    fullWidth
                    label='PracticeFusion Password'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type='password'
                />
                <TextField
                    fullWidth
                    label='PracticeFusion PhoneNumber'
                    value={phoneNumber}
                    onChange={e => setPhoneNumber(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button type='submit' color='primary' variant='contained'>
                    Save Credentials
                </Button>
            </DialogActions>
        </form>
    );
}

export default function PracticeFusionLoginDialog2({dialogRef}) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [resolve, setResolve] = useState(null);

    const handleSubmit = credentials => {
        resolve(credentials);
        setOpen(false);
    };

    const handleCancel = () => {
        resolve(null);
        setOpen(false);
    };

    dialogRef.current = async () => {
        const promise = new Promise((resolve, reject) => {
            setResolve(() => resolve);
            setOpen(true);
        });
        return await promise;
    };

    return (
        <Dialog className={classes.root} open={open} onClose={handleCancel}>
            <PracticeFusionCredentials
                onCancel={handleCancel}
                onSubmit={handleSubmit}
            />
        </Dialog>
    );
}
