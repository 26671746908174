import React from 'react';

import PCCPatientDataTable from './PCCPatientDataTable';
import MedicationDetail from './MedicationDetail';
import {FacilityDate} from './FacilityDates';


export default function MedicationsTable({patient, facility}) {
    if(!patient) return null;
    return (
        <PCCPatientDataTable
            title={null}
            patient={patient}
            rel='medications'
            columns={[
                { title: 'Medication', field: 'data.attributes.generic', 
                    render: rowData => (rowData.data.attributes.generic || rowData.data.attributes.description) 
                },
                {
                    title: 'Strength', field: 'data.attributes.strength',
                    render: rd => `${rd.data.attributes.strength} ${rd.data.attributes.strengthUOM}`
                },
                { title: 'Directions', field: 'data.attributes.directions' },
                { title: 'Status', field: 'data.attributes.status' },
                { title: 'Start Date', field: 'data.attributes.startDate', type: 'datetime',
                    render: rowData => <FacilityDate value={rowData.data.attributes.startDate} facility={facility}/>
                },
            ]}
            detailPanel={[
                {
                    tooltip: 'Schedules & Administration',
                    render: rowData => <MedicationDetail value={rowData.data} facility={facility}/>
                }
            ]}
        />
    )
}



