import React, {useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';
import {useSnackbar} from 'notistack';

import BasePage from 'components/BasePage';

import Ticket from 'components/ticket';

import {useApi, ducks} from '@arborian/narrf';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        alignItems: 'stretch',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    wider: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));
const include = [
    'form',
    'narrative',
    'comments',
    'owner',
    'provider',
    'pcc_patient',
    'facility',
    'pcc_facility',
];

export default function TicketPage({match, classes}) {
    classes = {...useStyles(), classes};
    const api = useApi();
    const ticketid = match.params.ticketid;

    const {enqueueSnackbar} = useSnackbar();

    const ticket = useSelector(
        ducks.jsonapi.selectObject(['Ticket', ticketid]),
    );
    const patchTicket = useCallback(
        async json => {
            let {type, id} = ticket;
            let attributes = fp.merge(ticket.attributes, json.data.attributes);
            await api.fetchJsonApi(ticket.links.self, {
                method: 'PATCH',
                include,
                json: {data: {type, id, attributes}},
            });
            enqueueSnackbar('Ticket Updated', {
                persist: false,
                variant: 'success',
            });
        },
        [api, ticket, enqueueSnackbar],
    );

    useEffect(() => {
        let url = api.url_for('ticket.resource', {ticketid});

        api.fetchJsonApi(url, {include});
        api.fetchAllJsonApi(api.url_for('facility.collection'));
        api.fetchAllJsonApi(api.url_for('fax_directory.collection'));
        api.fetchAllJsonApi(api.url_for('auth.users'));
        api.fetchAllJsonApi(api.url_for('auth.roles'));
    }, [api, ticketid]);

    if (!ticket) return null;
    return (
        <BasePage>
            <div className={classes.root}>
                <Ticket
                    ticket={ticket}
                    include={include}
                    onChanged={patchTicket}
                />
            </div>
        </BasePage>
    );
}
