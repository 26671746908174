import React, {useState} from 'react';
import {Formik, Form} from 'formik';

import {Button, Paper, makeStyles} from '@material-ui/core';

import {AutocompleteField} from 'components/fields/Autocomplete';
import {FreeSoloField} from 'components/fields/FreeSolo';
import {ImageAttachmentField} from 'components/fields/ImageAttachment';

import BasePage from 'components/BasePage';

const useStyles = makeStyles(theme => ({
    section: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

const options1 = [
    {value: 'value0', label: 'label0'},
    {value: 'value1', label: 'label1'},
    {value: 'value2', label: 'label2'},
];
const options2 = ['free0', 'free1', 'free2'];

export default function TestPage() {
    const originalivals = {
        regular: null,
        free: null,
        'm-regular': [],
        'm-free': [],
        image: [],
    };
    const [initialValues, setInitialValues] = useState(originalivals);
    const initialValuesToSet = {
        ...originalivals,
        // regular: options1[0],
        // "m-regular": [options1[0]],
        regular: {value: 'value0', label: 'label0'},
        'm-regular': [{...options1[0]}],
        free: 'free0',
        'm-free': ['free0', 'test'],
    };
    const classes = useStyles();
    const getOptionLabel = o => o.label;
    const getOptionSelected = (o, v) => o.value === v.value;

    const handleSubmit = (value, actions) => {
        console.log('Submit value', value);
        actions.setSubmitting(false);
    };
    console.log('Render TestPage', options1, options2);

    return (
        <BasePage>
            <Paper className={classes.section}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {formikProps => (
                        <Form>
                            <Button
                                onClick={() =>
                                    setInitialValues(initialValuesToSet)
                                }
                            >
                                Rset
                            </Button>
                            <AutocompleteField
                                name='regular'
                                label='Regular'
                                options={options1}
                                getOptionSelected={getOptionSelected}
                                getOptionLabel={getOptionLabel}
                            />

                            <ImageAttachmentField name='image' label='Image' />
                            <AutocompleteField
                                name='m-regular'
                                multiple
                                label='M-Regular'
                                options={options1}
                                getOptionSelected={getOptionSelected}
                                getOptionLabel={getOptionLabel}
                            />

                            <FreeSoloField
                                name='free'
                                label='FreeSolo'
                                options={options2}
                            />
                            <FreeSoloField
                                name='m-free'
                                multiple
                                label='M-FreeSolo'
                                freeSolo
                                options={options2}
                            />
                            <Button type='submit'>Submit</Button>
                        </Form>
                    )}
                </Formik>
            </Paper>
        </BasePage>
    );
}
